import { withStyles } from "@mui/styles";
import { createStyles, styled } from "@mui/material/styles";
import {
  AppBar,
  AppBarProps,
  Avatar,
  Backdrop,
  CircularProgress,
  CssBaseline,
  IconButton,
  // InputAdornment,
  Menu,
  MenuItem,
  //TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  CheckUserRole,
  LocalUrlEnum,
  logout,
  readCookie,
} from "../Utils/Utils";
import useNoActivity from "../Hooks/useNoActivity";
import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Vocabulary } from "../Utils/Vocabulary";
import { Logout, Person, Settings } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuContext } from "../Context/menuContext";
import theme from "../Theme/Theme";
import { useNavigate } from "react-router-dom";
import MainCategories from "./MainCategories";

type Props = {
  classes: any;
};
let logoutTimeout: any;
const drawerWidth = 240;
interface CustomAppBarProps extends AppBarProps {
  open?: boolean;
}

const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<CustomAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 30,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header(props: Props) {
  const { classes } = props;
  const hasActivity = useNoActivity();
  const menuContext = useContext(MenuContext);
  const [showUserOptions, setShowUserOptions] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  /**
   *
   */
  useEffect(() => {
    if (!hasActivity) {
      logoutTimeout = setTimeout(() => {
        logout(() => navigate(LocalUrlEnum.login));
      }, 1000 * 60 * 5);
    } else {
      if (logoutTimeout) {
        clearTimeout(logoutTimeout);
      }
    }
  }, [hasActivity]);

  /**
   *
   */
  const menuHandleClick = () => {
    menuContext.setOpen(!menuContext.open);
  };

  /**
   *
   */
  const handleCloseUserMenu = () => {
    setShowUserOptions(null);
  };

  /**
   *
   * @param event
   */
  const handleOpenUserMenu = (event: any) => {
    setShowUserOptions(event.currentTarget);
  };

  return (
    <>
      {CheckUserRole.isAdmin() ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <CustomAppBar
            theme={theme}
            position="static"
            className={classes.container}
            open={menuContext.open}
          >
            <Toolbar className={classes.toolbar}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className={classes.menuIcon}>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{
                      mr: 2,
                      ...(menuContext.open && { display: "none" }),
                    }}
                    onClick={menuHandleClick}
                  >
                    <Avatar className={classes.avatar}>
                      <MenuIcon />
                    </Avatar>
                  </IconButton>
                </div>

                <div className={classes.image}>
                  <img
                    src={"/vetmed_logo.png"}
                    alt="VETMED GHID"
                    width="100%"
                    onClick={() => navigate("/")}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={(event) => {
                  handleOpenUserMenu(event);
                }}
              >
                <Avatar className={classes.avatar}>
                  {readCookie("userName").charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>
            </Toolbar>
            <Box>
              <Menu
                anchorEl={showUserOptions}
                sx={{ mt: "50px" }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(showUserOptions)}
                onClick={() => {
                  handleCloseUserMenu();
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigate(LocalUrlEnum.settings);
                    handleCloseUserMenu();
                  }}
                >
                  <Settings
                    fontSize="large"
                    style={{
                      paddingRight: "10px",
                    }}
                  />
                  <Typography variant="h6">{Vocabulary.settings}</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setLoading(true);
                    handleCloseUserMenu();
                    logout(() => navigate(LocalUrlEnum.login));
                    setLoading(false);
                  }}
                >
                  <Logout
                    fontSize="large"
                    style={{
                      paddingRight: "10px",
                    }}
                  />
                  <Typography variant="h6">{Vocabulary.logout}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </CustomAppBar>
        </Box>
      ) : (
        <>
          <AppBar>
            <div className={classes.appBar}>
              <IconButton
                size="medium"
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={(event) => {
                  handleOpenUserMenu(event);
                }}
              >
                <Avatar className={classes.userAvatar}>
                  <Person />
                </Avatar>
              </IconButton>
            </div>
          </AppBar>
          <div className={classes.userMenuFullContainer}>
            <div className={classes.userMenu}>
              <div className={classes.image}>
                <a href="/">
                  <img
                    src={"/vetmed_logo.png"}
                    alt="VETMED GHID"
                    width="100%"
                  />
                </a>
              </div>

              <MainCategories />

              <Menu
                anchorEl={showUserOptions}
                sx={{ mt: "50px" }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(showUserOptions)}
                onClick={() => {
                  handleCloseUserMenu();
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigate(LocalUrlEnum.settings);
                    handleCloseUserMenu();
                  }}
                >
                  <Settings
                    fontSize="large"
                    style={{
                      paddingRight: "10px",
                    }}
                  />
                  <Typography variant="h6">{Vocabulary.settings}</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setLoading(true);
                    handleCloseUserMenu();
                    logout(() => {
                      setLoading(false);
                      navigate(LocalUrlEnum.login);
                    });
                  }}
                >
                  <Logout
                    fontSize="large"
                    style={{
                      paddingRight: "10px",
                    }}
                  />
                  <Typography variant="h6">{Vocabulary.logout}</Typography>
                </MenuItem>
              </Menu>
            </div>
          </div>
          {loading ? (
            <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
              <CircularProgress color="primary" />
            </Backdrop>
          ) : null}
        </>
      )}
    </>
  );
}

const styles = (theme: any) =>
  createStyles({
    container: {
      width: "100%",
      backgroundColor: "#f1f1f1 !important",
      boxShadow: "1px 3px 10px #000000",
      [theme.breakpoints.down("sm")]: {
        width: "100vw",
      },
    },
    firstSection: {
      width: "50%",
      display: "flex",
      flexDirection: "row",
      padding: 10,
      justifyContent: "space-between",
    },

    typography: {
      fontWeight: "bold",
      marginLeft: 1,
    },
    menuIcon: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    image: {
      width: "175px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 100,
        marginTop:20,
      },
    },

    avatar: {
      backgroundColor: "#000000 !important",
    },
    userAvatar: {
      backgroundColor: "#FFF !important",
      color: "#000 !important",
      width: "30px !important",
      height: "30px !important",
    },
    speedDial: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.down("md")]: {
        display: "inline",
      },
      dispaly: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 2,
      paddingTop: 6,
      marginRight: 5,
    },

    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    userMenuFullContainer: {
      backgroundColor: "#f1f1f1 !important",
      width: "100%",
      boxShadow: "0px 0px 9px #f1f1f1",
      marginTop: "46px !important",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100vw",
      },
    },
    userMenu: {
      width: "1300px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 15,
      [theme.breakpoints.down("lg")]: {
        width: "1050px",
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    appBar: {
      width: "70%",
      display: "flex",
      justifyContent: "flex-end",
      alignSelf: "center",
      zIndex: 2,
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "95%",
        padding: 10,
      },
    },
    fabAction: {
      margin: "0 8px 0 0",
    },
  });

export default withStyles(styles, { withTheme: true })(Header);
