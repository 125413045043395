import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { LocalUrlEnum, UrlEnum, get, post } from "../../Utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Backdrop,
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Box
} from "@mui/material";
import {Edit, Visibility } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";
import Modal from "../../Components/Modal";
import Category from "./Category";

type Props = {
  classes: any;
};

function CategoriesTable(props: Props) {
  const navigate = useNavigate();
  const { classes } = props;
  const cachedData = CachedDataSingleton.getInstance();
  const [state, setState] = useState<any>({
    categories: [],
    page: 1,
    perPage: 20,
    order: "desc",
    preview: false,
    loading: false,
    count: 1,
  });
  const requestHeaders = [
    { label: "Nume", name: "name", options: { sort: false } },
    { label: "Link", name: "link", options: { sort: false } },
    { label: "Ordine", name: "order", options: { sort: false } },
   
    {
      label: "Categorie părinte",
      name: "parentName",
      options: { sort: false },
    },
    { label: "Data creare", name: "createdDate", options: { sort: true } },
    {
      label: "TRUE",
      name: "status",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      label: "Opțiuni",
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.view}>
                  <ToggleButton
                    value="left"
                    aria-label="left aligned"
                    onClick={() => {
                      cachedData.setKey("preview", true);
                      cachedData.setKey("id", state.categories[rowIndex].id);
                      setState({ ...state, preview: true });
                    }}
                  >
                    <Visibility />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      navigate(
                        `/${LocalUrlEnum.category}/${state.categories[rowIndex].id}`,
                        { state: { category: state.categories[rowIndex] } }
                      );
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>               
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "multiple" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [20, 50, 100],
      confirmFilters: true,
      filter: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page - 1,
      search: true,
      serverSide: true,
      customToolbar: () => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/${LocalUrlEnum.category}`);
            }}
          >
            {Vocabulary.addCategory}
          </Button>
        );
      },
      onSearchChange: (searchText: string | null) => {
        if (searchText && searchText?.length > 3) getCategories(searchText);
        if (!searchText) getCategories(null);
      },
      onColumnSortChange: (changedColumn: string, direction: string) => {
        setState({ ...state, order: direction });
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page + 1 });
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        setState({ ...state, perPage: numberOfRows });
      },
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        handleDelete(rowsDeleted);
      },
    };
  };

  /**
   *
   */
  useEffect(() => {
    getCategories();
  }, [state.perPage, state.page, state.order]);

  /**
   *
   * @param id
   */
  const handleDelete = (selectedRows: any) => {
    const ids: number[] = [];

    selectedRows.data.map((row: any, k: any) => {
      ids.push(state.categories[row.dataIndex].id);
    });

    post(UrlEnum.deleteCategories, ids).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.deleteSucces);
      }
      getCategories();
    });
    selectedRows.data.length = 0;
  };

  /**
   *
   */
  const getCategories = (filter?: string | null) => {
    if (!filter) filter = null;
    setState({ ...state, loading: true });
    get(
      `${UrlEnum.getCategories}/${state.page}/${state.perPage}/${state.order}/${filter}`
    ).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
        setState({ ...state, loading: false });
      }
      setState({
        ...state,
        categories: response.items,
        count: response.total_count,
        loading: false,
      });
    });
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <Box className={classes.tableBox}>
      <MUIDataTable

        title={Vocabulary.categories}
        data={state.categories}
        columns={requestHeaders}
        options={getTableOptions()}
      />
      </Box>
      {state.preview ? (
        <Modal
          open={state.preview}
          onClose={() => {
            setState({ ...state, preview: false });
            cachedData.setKey("id", undefined);
          }}
          title={Vocabulary.category}
          actions={null}
          children={<Category />}
        ></Modal>
      ) : null}
      {state.loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
    </>
  );
}

const styles = (theme: any) => createStyles({
  tableBox: {
    [theme.breakpoints.down("sm")]: {
      width: "88vw",
    },
  },
});
export default withStyles(styles, { withTheme: true })(CategoriesTable);
