import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalUrlEnum, UrlEnum, get } from "../../Utils/Utils";
import {
  Backdrop,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";

type Props = {
  classes: any;
};

function UserArticle(props: Props) {
  const { classes } = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const { id } = state;
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({
    id: null,
    title: "",
    content: "",
    category: { name: "", link: "", id: "" },
    tags: [],
    attributes: [],
    categories: [],
  });

  /**
   *
   */
  useEffect(() => {
    setLoading(true);
    const storage = window.localStorage;
    const storageUser = storage.getItem("user");
    const subscription = JSON.parse(storageUser ? storageUser : "")?.account
      ?.id;
    get(`${UrlEnum.getUserArticle}/${id}/${subscription}`).then(
      (response: any) => {
        setLoading(false);
        if (response.errors) {
          console.log(response.errors);
        } else {
          setArticle(response.article);
        }
      }
    );
  }, [location]);

  /**
   *
   * @param ref
   */
  const renderArticle = (ref: any) => {
    const element = document.getElementById("article" + article.id);
    element?.remove();
    const articleContent = document.createElement("div");
    articleContent.setAttribute("id", "article" + article.id);
    articleContent.innerHTML = article.content;

    const articleImages = articleContent.getElementsByTagName("img");
    for (let i = 0; i < articleImages.length; i++) {
      articleImages[i].style.width = "100%";
      articleImages[i].style.userSelect = "none";
    }

    ref?.appendChild(articleContent);
  };

  /**
   *
   * @param link
   * @param id
   */
  const navigateToSubcategory = (link: string, id: any) => {
    navigate(`/${LocalUrlEnum.userCategories}${link}`, {
      state: { categoryId: id },
    });
  };

  /**
   *
   * @param ArrayOfItems
   * @param variant
   * @returns
   */
  const renderChipComponent = (ArrayOfItems: any, variant: any) => {
    return ArrayOfItems?.map((item: any, index: any) => {
      return (
        <Chip
          className={classes.chip}
          color="primary"
          key={index}
          variant={variant}
          label={item.name}
        />
      );
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.secondContainer}>
        <Breadcrumbs separator="›">
          <Button
            variant="text"
            className={classes.link}
            onClick={() => {
              navigate("/");
            }}
          >
            {Vocabulary.articles}
          </Button>
          {article.categories.map((category: any, key) => (
            <Button
              key={key}
              variant="text"
              className={classes.link}
              onClick={() => {
                navigateToSubcategory(category?.link, category?.id);
              }}
            >
              {category.name}
            </Button>
          ))}

          <InputLabel className={classes.link}>{article.title}</InputLabel>
        </Breadcrumbs>
        <div className={classes.chipContainer}>
          {renderChipComponent(article.tags, "outlined")}
          {renderChipComponent(article.attributes, "")}
        </div>
        <Paper className={classes.paper} elevation={24}>
          <Typography
            sx={{
              textAlign: "center",
              marginTop: 5,
              fontWeight: "bold",
              fontSize: 18,
              userSelect: "none",
            }}
          >
            {article.title}
          </Typography>
          <div
            className={classes.article}
            ref={(ref: any) => {
              renderArticle(ref);
            }}
          >
            <div className={classes.overlay} />
          </div>
        </Paper>
      </div>
      {loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    chipContainer: {
      margin: "10px 0 10px 0 !important",
    },
    chip: {
      marginRight: 5,
    },
    container: {
      width: "100%",
      display: "flex",
      minHeight: "70vh",
      justifyContent: "center",
    },
    secondContainer: {
      width: "70%",
      display: "flex",
      flexDirection: "column",
      marginTop: 50,
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    link: {
      textDecoration: "none",
      color: "#000 ! important",
    },
    overlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 999,
    },
    paper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: 50,
      padding: 20,
    },
    article: {
      width: "100%",
      height: "100%",
      position: "relative",
      textAlign: "justify",
      fontSize: 14,
      userSelect: "none",
    },
  });

export default withStyles(styles, { withTheme: true })(UserArticle);
