import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../Pages/Admin/Dashboard";
import Login from "../Pages/Login";
import RegisterWizard from "../Pages/RegisterWizard";
import theme from "../Theme/Theme";
import PageNotFound from "../Pages/PageNotFound";
import RequestsTable from "../Pages/Admin/RequestsTable";
import { CheckUserRole, LocalUrlEnum } from "../Utils/Utils";
import UsersTable from "../Pages/Admin/UsersTable";
import Request from "../Pages/Admin/Request";
import Settings from "../Pages/Admin/Settings";
import CategoriesTable from "../Pages/Admin/CategoriesTable";
import Category from "../Pages/Admin/Category";
import User from "../Pages/Admin/User";
import TagsTable from "../Pages/Admin/TagsTable";
import AttributesTable from "../Pages/Admin/AttributesTable";
import ArticlesTable from "../Pages/Admin/ArticlesTable";
import Article from "../Pages/Admin/Article";
import UserEdit from "../Pages/Admin/UserEdit";
import Articles from "../Pages/User/Articles";
import UserArticle from "../Pages/User/UserArticle";
import Subcategories from "../Pages/User/Subcategories";
import ResetPassword from "../Pages/ResetPassword";
import Admin from "../Pages/Admin/Admin";
import ExpiredPayment from "../Pages/Admin/ExpiredPayment";
import SuccessfulPayment from "../Pages/SuccessfulPayment";

const Router = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<PageNotFound />}></Route>
          <Route path="" element={<Dashboard />}>
            {CheckUserRole.isUser() ? (
              <>
                <Route path={"/articole"} element={<Articles />} />
                <Route path={"/"} element={<Articles />} />
                <Route
                  path={"/categorii/:categorie"}
                  element={<Subcategories />}
                />
              </>
            ) : null}

            {/*REQUESTS*/}
            <Route path={LocalUrlEnum.requests} element={<RequestsTable />} />
            <Route path={`${LocalUrlEnum.request}/:id`} element={<Request />} />
            {/*USERS*/}
            <Route path={LocalUrlEnum.users} element={<UsersTable />} />
            <Route path={LocalUrlEnum.user} element={<User />} />
            <Route path={`${LocalUrlEnum.user}/:id`} element={<UserEdit />} />
            <Route path={LocalUrlEnum.settings} element={<Settings />} />
             {/*ADMIN*/}
             <Route path={LocalUrlEnum.admin} element={<Admin />} />
             <Route path={`${LocalUrlEnum.admin}/:id`} element={<Admin />} />
            {/*CATEGORIES*/}
            <Route
              path={LocalUrlEnum.categories}
              element={<CategoriesTable />}
            />
            <Route path={LocalUrlEnum.category}>
              <Route index element={<Category />} />
              <Route path=":id" element={<Category />} />
            </Route>
            {/* ARTICLES */}
            {CheckUserRole.isUser() ? (
              <Route
                path={`${LocalUrlEnum.article}/:id`}
                element={<UserArticle />}
              />
            ) : (
              <Route
                path={`${LocalUrlEnum.article}/:id`}
                element={<Article />}
              />
            )}
            <Route path={LocalUrlEnum.articles} element={<ArticlesTable />} />
            <Route path={LocalUrlEnum.article} element={<Article />} />

            {/* TAGS */}
            <Route path={LocalUrlEnum.tags} element={<TagsTable />} />
            <Route path={`${LocalUrlEnum.tags}/:id`} element={<TagsTable />} />
            {/* ATTRIBUTES */}
            <Route
              path={LocalUrlEnum.attributes}
              element={<AttributesTable />}
            />
            <Route
              path={`${LocalUrlEnum.attributes}/:id`}
              element={<AttributesTable />}
            />
          </Route>
          <Route path={LocalUrlEnum.login} element={<Login />} />
          <Route path={LocalUrlEnum.expiredPayment} element={<ExpiredPayment/>} />
          <Route path={LocalUrlEnum.statusPayment} element={<SuccessfulPayment/>} />
          <Route
            path={LocalUrlEnum.resetPassword}
            element={<ResetPassword />}
          />
          <Route path={LocalUrlEnum.register} element={<RegisterWizard />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default Router;
