import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Vocabulary } from "../Utils/Vocabulary";
import FirstStep from "../Components/wizard/FirstStep";
import { WizardModel } from "../Models/Models";
import SecondStepPerson from "../Components/wizard/SecondStepPerson";
import SecondStepCompany from "../Components/wizard/SecondStepCompany";
import { ValidatorForm } from "react-material-ui-form-validator";
import ThirdStep from "../Components/wizard/ThirdStep";
import FourthStep from "../Components/wizard/FourthStep";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import {
  generateFormData,
  get,
  handleChange,
  LocalUrlEnum,
  post,
  RegisterWizardArray,
  UrlEnum,
} from "../Utils/Utils";
import { useNavigate } from "react-router-dom";

type Props = {
  classes: any;
};

/**
 *
 * @returns
 */
const getSteps = () => {
  return ["", "", "", ""];
};

function RegisterWizard(props: Props) {
  const { classes } = props;
  const [state, setState] = useState({
    model: new WizardModel(),
    accounts: [],
    companyCategories: [],
    subscriptions: [],
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const steps = getSteps();
  const navigate = useNavigate();

  /**
   *
   */
  const redirectToLogin = () => {
    navigate(`/${LocalUrlEnum.login}`);
  };
  /**
   *
   */
  useEffect(() => {
    const promises = [
      get(UrlEnum.getAccountTypes),
      get(UrlEnum.getCompanyCategories),
      get(UrlEnum.getSubscriptionTypes),
    ];
    Promise.all(promises)
      .then((values: any) => {
        setState({
          ...state,
          accounts: values[0].accounts,
          companyCategories: values[1].categories,
          subscriptions: values[2].subscriptions,
        });
      })
      .catch((ex) => console.log(ex));
  }, []);

  /**
   *
   * @param event
   */
  const handleInputChange = (event: any) => {
    const newModel: any = handleChange(event, state.model);
    setState({ ...state, model: newModel });
  };

  /**
   *
   * @param step
   * @returns
   */
  const isStepSkipped = (step: any) => {
    return skipped.has(step);
  };

  /**
   *
   * @returns
   */
  const handleNext = async () => {
    if (
      activeStep === 1 &&
      (state.model.userName !== "" || state.model.representativeName !== "")
    ) {
      const body = {
        userNames: [
          state.model.isUserAccount === "true"
            ? state.model.userName
            : state.model.representativeName,
        ],
      };
      const isUserNameValid = await post(`${UrlEnum.validateUserName}`, body);
      if (isUserNameValid?.alreadyExists) {
        toast.error(Vocabulary.unavailableUserName);
        return;
      }
    }
    if (activeStep === 2 && state.model.userNames.length !== 0) {
      const body = { userNames: state.model.userNames };
      const isUserNameValid = await post(`${UrlEnum.validateUserName}`, body);
      if (isUserNameValid?.alreadyExists) {
        let names = "";
        isUserNameValid.names?.forEach((element: string) => {
          names += `${element} `;
        });
        toast.error(`${Vocabulary.unavailableUserName} : ${names}`);
        return;
      }
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  /**
   *
   */
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   *
   * @param data
   * @returns
   */
  function formatValuesWithComma(data: { [key: string]: any }[]): string {
    const filteredValues = data
      .map((item) => item.value)
      .filter((value) => value !== null && value !== undefined && value !== "");

    return filteredValues.join(", ");
  }

  /**
   *
   */
  const handleRegister = () => {
    setLoading(true);
    //create address object
    const modelCopy = Object.assign({}, state.model);
    modelCopy.address = formatValuesWithComma([
      { key: "street", value: state.model.street },
      { key: "streetNumber", value: state.model.streetNumber },
      { key: "bloc", value: state.model.bloc },
      { key: "entrance", value: state.model.entrance },
      { key: "floor", value: state.model.floor },
      { key: "apartment", value: state.model.apartment },
      { key: "postalCode", value: state.model.postalCode },
    ]);
    const formData = generateFormData(modelCopy, RegisterWizardArray);
    post(UrlEnum.register, formData).then((response) => {
      setLoading(false);
      if (response.errors) {
        toast.error(response.errors);
      } else {
        handleNext();
        toast.success(response);
      }
    });
  };

  /**
   *
   * @param step
   * @returns
   */
  const getStepContent = (step: any) => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            accounts={state.accounts}
            companyCategories={state.companyCategories}
            handleChange={handleInputChange}
            model={state.model}
          />
        );
      case 1:
        return state.model.isUserAccount === "true" ? (
          <SecondStepPerson
            handleChange={handleInputChange}
            model={state.model}
          />
        ) : (
          <SecondStepCompany
            handleChange={handleInputChange}
            model={state.model}
          />
        );

      case 2:
        return (
          <ThirdStep handleChange={handleInputChange} model={state.model} />
        );
      case 3:
        return (
          <FourthStep
            subscriptions={state.subscriptions}
            handleChange={handleInputChange}
            model={state.model}
          />
        );
      case 4:
        return <></>;
      default:
        return <></>;
    }
  };

  /**
   *
   */
  const wizardButtons = (
    <Grid container className={classes.grid}>
      {activeStep < steps.length - 1 ? (
        <>
          <Grid className={classes.grid}>
            <Button
              disabled={activeStep === 0}
              variant="contained"
              onClick={handleBack}
              className={classes.button}
            >
              {Vocabulary.back}
            </Button>
          </Grid>
          <Grid className={classes.grid}>
            <Button
              variant="contained"
              type="submit"
              className={classes.button}
            >
              {Vocabulary.next}
            </Button>
          </Grid>
        </>
      ) : activeStep < steps.length ? (
        <>
          <Grid className={classes.grid}>
            <Button
              disabled={activeStep === 0}
              variant="contained"
              onClick={handleBack}
              className={classes.button}
            >
              {Vocabulary.back}
            </Button>
          </Grid>
          <Grid className={classes.grid}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleRegister}
            >
              {Vocabulary.registerRequest}
            </Button>
          </Grid>
        </>
      ) : null}
    </Grid>
  );

  return (
    <div className={classes.root}>
      <ToastContainer hideProgressBar={true} />
      <a href={LocalUrlEnum.login} className={classes.image}>
        <img src={"/vetmed.svg"} alt="VETMED GHID" width="70%" />
      </a>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <ValidatorForm instantValidate={false} onSubmit={handleNext}>
            <Typography variant="h5" className={classes.text}>
              {Vocabulary.registerForm}
            </Typography>

            {activeStep < steps.length ? (
              <div>{getStepContent(activeStep)}</div>
            ) : (
              <div className={classes.redirectToLoginPageButton}>
                <Button onClick={redirectToLogin} variant="contained">
                  {Vocabulary.continueLogin}
                </Button>
              </div>
            )}

            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label + index} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Typography variant="subtitle2" className={classes.text}>
              {Vocabulary.registerHelperText}
            </Typography>
            {wizardButtons}
          </ValidatorForm>
        </Paper>
        {loading ? (
          <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
            <CircularProgress color="primary" />
          </Backdrop>
        ) : null}
      </div>
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    image: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 50,
      width: "350px",
      [theme.breakpoints.down("sm")]: {
        width: "150px",
        marginBottom: 30,
      },
    },
    root: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      width: "100%",
      backgroundColor: "#000",
      background:
        "radial-gradient(circle, rgba(74,74,74,1) 0%, rgba(0,0,0,1) 93%)",
      overflowY: "auto",
      [theme.breakpoints.down("md")]: {
        overflowY: "auto",
      },
      [theme.breakpoints.down("sm")]: {
        background:
          "radial-gradient(circle, rgba(74,74,74,1) 50%, rgba(0,0,0,1) 93%)",
      },
    },
    container: {
      paddingBottom: 5,
      width: "40%",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "95%",
      },
    },
    paper: {
      paddingTop: 16,
      paddingBottom: 16,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    grid: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginTop: 10,
      [theme.breakpoints.down("md")]: {
        width: "100%",
        justifyContent: "center",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 5,
        marginRight: 15,
      },
    },
    field: {
      marginTop: 20,
      paddingTop: 16,
      paddingBottom: 16,
      buttonsField: {
        justifyContent: "center",
        display: "flex",
      },
    },
    button: {
      paddingTop: 5,
      paddingBottom: 5,
      marginTop: 10,
      marginBottom: 10,
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        flexDirection: "column",
        alignContent: "center",
      },
    },
    text: { fontWeight: "bold", fontSize: 20, textAlign: "center" },
    linkText: {
      textDecoration: "inherit",
      color: "#000",
      textAlign: "center",
    },
    stepper: {
      marginTop: 20,
      marginBottom: 20,
    },
    redirectToLoginPageButton: {
      display: "flex",
      justifyContent: "center",
      margin: 15,
    },
  });

export default withStyles(styles, { withTheme: true })(RegisterWizard);
