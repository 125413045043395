import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ConnectForm } from "../../Context/conectFormContext";

type Props = {
  classes: any;
};

function Person(props: Props) {
  return (
    <ConnectForm>
      {(methods: any) => {
        return (
          <>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="fullName"
                name="fullName"
                {...methods.register("fullName")}
                label={Vocabulary.fullName}
                fullWidth
                variant="standard"
                error={typeof methods.formState.errors.fullName === "object"}
                helperText={methods.formState.errors.fullName?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="userName"
                name="userName"
                label={Vocabulary.username}
                {...methods.register("userName")}
                error={typeof methods.formState.errors.userName === "object"}
                helperText={methods.formState.errors.userName?.message}
                fullWidth
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="email"
                name="email"
                type="email"
                label={Vocabulary.email}
                {...methods.register("email")}
                fullWidth
                error={typeof methods.formState.errors.email === "object"}
                helperText={methods.formState.errors.email?.message}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                type="number"
                id="phone"
                name="phone"
                label={Vocabulary.phone}
                {...methods.register("phone")}
                error={typeof methods.formState.errors.phone === "object"}
                helperText={methods.formState.errors.phone?.message}
                fullWidth
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>
        );
      }}
    </ConnectForm>
  );
}

const styles = (theme: any) => createStyles({});

export default withStyles(styles, { withTheme: true })(Person);
