import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Divider, Typography } from "@mui/material";
import { Email, Phone, Place } from "@mui/icons-material";
import { Vocabulary } from "../Utils/Vocabulary";

type Props = {
  classes: any;
};

function Footer(props: Props) {
  const { classes } = props;

  return (
    <footer className={classes.container}>
      <div className={classes.secondContainer}>
        <div className={classes.section}>
          <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
            {Vocabulary.contact}
          </Typography>
          <Divider style={{ margin: 5 }} />
          <div className={classes.detailsContainer}>
            <Place />
            <Typography sx={{ fontWeight: "bold", marginLeft: 1, padding: 1 }}>
              {Vocabulary.companyAddress}
            </Typography>
          </div>
          <div className={classes.detailsContainer}>
            <Email />
            <Typography sx={{ fontWeight: "bold", marginLeft: 1, padding: 1 }}>
              {Vocabulary.companyContact}
            </Typography>
          </div>
          <div className={classes.detailsContainer}>
            <Phone />
            <Typography sx={{ fontWeight: "bold", marginLeft: 1, padding: 1 }}>
              {Vocabulary.companyPhone}
            </Typography>
          </div>
        </div>
        <div className={classes.section}>
          <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
            {Vocabulary.usefulLinks}
          </Typography>
          <Divider style={{ margin: 5 }} />
          <Typography sx={{ fontWeight: "bold", padding: 1 }}>
            <a href='https://vetmed-ghid.ro/politica-de-confidentialitate'>{Vocabulary.privacyPolicy}</a>
          </Typography>
          <Typography sx={{ fontWeight: "bold", padding: 1 }}>
           <a href='https://vetmed-ghid.ro/termeni-si-conditii'>{Vocabulary.termsAndConditions}</a> 
          </Typography>
          <Typography sx={{ fontWeight: "bold", padding: 1 }}>
            <a href='https://vetmed-ghid.ro/politica-cookies'>{Vocabulary.support}</a>
          </Typography>
        </div>
      </div>
    </footer>
  );
}

const styles = (theme: any) =>
  createStyles({
    detailsContainer: {
      display: "flex",
      flexDirection: "row",
      padding: 1,
    },
    container: {
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: "#f1f1f1 !important",
      boxShadow: "0px 0px 9px 0px #f1f1f1",
      display: "flex",
      justifyContent: "center",
      marginTop: 50,
      [theme.breakpoints.down("sm")]: {
       // width:"100%"
      },
    },
    secondContainer: {
     // width: "50%",
      display: "flex",
      flexDirection: "row",
      padding: 10,
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
    
       },
    },
    section: {
      margin: 10,
      marginLeft: 20,
      marginRight: 20,
    },
  });

export default withStyles(styles, { withTheme: true })(Footer);
