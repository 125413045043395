import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Button,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";
import Person from "./Person";
import Address from "./Address";
import {
  UrlEnum,
  disableAllFields,
  downloadPdf,
  getImage,
} from "../../Utils/Utils";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";

type Props = {
  classes: any;
};

function UserRequest(props: Props) {
  const cachedData = CachedDataSingleton.getInstance();
  const { classes } = props;
  const accounts = cachedData.get("accounts");
  const subscriptions = cachedData.get("subscriptions");
  const [image, setImage] = useState<any>();
  const [zoom, setZoom] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const preview = cachedData.get("preview");
  const methods = useFormContext();
  const location = useLocation();
  const imagePath = methods.watch("imagePath");

  /**
   *
   */
  useEffect(() => {
    if (preview) disableAllFields();
    if (methods.getValues("imagePath")) {
      const url = `${UrlEnum.getImage}/${methods.getValues("imagePath")}`;
      getImage(url, (data) => {
        if (data.type.includes("pdf")) setIsPdf(true);
        const reader = new FileReader();
        reader.onload = function () {
          const dataUrl = reader.result;
          setImage(dataUrl);
        };
        reader.readAsDataURL(data);
      });
    }
  }, [location, imagePath]);

  /**
   *
   * @returns
   */
  const renderImage = () => {
    return (
      <>
        {zoom ? (
          <div
            onClick={() => setZoom(false)}
            className={`${classes.lightBox} ${classes.show} relative`}
          >
            {image ? (
              <img
                src={image}
                alt="doc"
                width="80%"
                height="80%"
                style={{ objectFit: "contain" }}
              />
            ) : null}
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {image ? (
            <img
              src={image}
              alt="doc"
              width="200px"
              height="350px"
              style={{ objectFit: "contain" }}
              onClick={() => setZoom(true)}
            />
          ) : null}
        </div>
      </>
    );
  };

  return (
    <div
      className={
        methods.getValues("FK_userAccountId") &&
        (methods.getValues("FK_userAccountId") === 1 ||
          methods.getValues("FK_userAccountId") === 4)
          ? ""
          : classes.container
      }
    >
      <ToastContainer hideProgressBar={true} />
      {methods.getValues("FK_userAccountId") !== undefined &&
      (methods.getValues("FK_userAccountId") === 2 ||
        methods.getValues("FK_userAccountId") === 3) ? (
        <Grid item xs={12} sm={12} md={12}>
          <fieldset className={classes.fieldset}>
            <Typography>
              {methods.getValues("FK_userAccountId") === 2
                ? Vocabulary.studentCard
                : Vocabulary.qualificationDiploma}
            </Typography>
            {isPdf ? (
              <Button
                variant={"contained"}
                onClick={() => {
                  const fileName = `${methods.getValues("userName")}.pdf`;
                  downloadPdf(image, fileName);
                }}
              >
                {Vocabulary.downloadPDF}
              </Button>
            ) : (
              renderImage()
            )}
          </fieldset>
        </Grid>
      ) : null}
      <Grid
        container
        spacing={2}
        style={{ height: "50%" }}
        id="previewDialogContent"
      >
        <Grid item xs={12} sm={8} md={3}>
          <InputLabel style={{ fontSize: 12 }}>
            {Vocabulary.accountType}
          </InputLabel>
          <Select
            label={Vocabulary.accountType}
            className={classes.select}
            native
            fullWidth
            variant="standard"
            {...methods.register("FK_userAccountId")}
            id={"FK_userAccountId"}
            name={"FK_userAccountId"}
          >
            {accounts &&
              accounts.map((value: any, key: any) => {
                return (
                  <option value={value.id} key={key}>
                    {value.account}
                  </option>
                );
              })}
          </Select>
        </Grid>
        <Person />
        <Address modelRoot="address." />

        <Grid item xs={12} sm={8} md={3}>
          <InputLabel style={{ fontSize: 12 }}>
            {Vocabulary.subscription}
          </InputLabel>
          <Select
            native
            required
            fullWidth
            variant="standard"
            {...methods.register("FK_subscriptionId")}
            id={"FK_subscriptionId"}
            name={"FK_subscriptionId"}
            className={classes.select}
          >
            {subscriptions &&
              subscriptions.map((value: any, key: any) => {
                return (
                  <option value={value.id} key={key}>
                    {`${value.type} ${
                      methods.getValues("FK_userAccountId") === 2
                        ? value.price
                        : value.price
                    } lei/${value.valability} ${
                      key === 0 ? Vocabulary.month : Vocabulary.months
                    }`}
                  </option>
                );
              })}
          </Select>
        </Grid>
        {methods.getValues("FK_userAccountId") === 1 ||
        methods.getValues("FK_userAccountId") === 4 ? (
          <Grid item xs={12} sm={8} md={3}>
            <TextField
              variant="standard"
              // type="number"
              id="ValidationCode"
              label={
                methods.getValues("FK_userAccountId") === 1
                  ? Vocabulary.validationNo
                  : Vocabulary.petOwnerCode
              }
              {...methods.register("ValidationCode")}
              name="ValidationCode"
              fullWidth
            />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    select: {
      paddingTop: 0,
      width: "10%",
      marginBottom: 15,
      paddingRight: 10,
      marginRight: 10,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    lightBox: {
      position: "fixed",
      zIndex: 9999999,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      visibility: "hidden",
      opacity: 0,
      transition: "opacity ease 0.4s",
    },
    show: {
      visibility: "visible",
      opacity: 1,
    },
    fieldset: {
      borderRadius: 10,
      borderColor: "#00197540",
      padding: 20,
      marginRight: 20,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    container: {
      display: "flex",
      justifyContent: "space-evenly",
      padding: 20,
      [theme.breakpoints.down("md")]: {
        flexDirection: "row",
      },
    },
  });
export default withStyles(styles, { withTheme: true })(UserRequest);
