import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import Address from "./user/Address";
import { Vocabulary } from "../Utils/Vocabulary";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CheckUserRole,
  UrlEnum,
  UserPropertiesArray,
  downloadPdf,
  generateFormData,
  getImage,
  phoneRegExp,
  post,
} from "../Utils/Utils";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createStyles } from "@mui/material/styles";
import { Visibility } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import CachedDataSingleton from "../Utils/CachedDataSingleton";

type Props = {
  classes: any;
};

function UserData(props: Props) {
  const cachedData = CachedDataSingleton.getInstance();
  const { classes } = props;
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [image, setImage] = useState<any>();
  const [zoom, setZoom] = useState(false);
  const requestValidationSchema = yup
    .object()
    .shape({
      fullName: yup.string().required(Vocabulary.requiredField),
      userName: yup.string().required(Vocabulary.requiredField),
      email: yup
        .string()
        .email(Vocabulary.emailValidation)
        .required(Vocabulary.requiredField),
      phone: yup
        .string()
        .matches(phoneRegExp, Vocabulary.phoneValidation)
        .required(Vocabulary.requiredField),
    })
    .required();
  const methods = useForm({
    resolver: yupResolver(requestValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });
  const imagePath = methods.watch("imagePath");

  /**
   *
   */
  useEffect(() => {
    if (methods.getValues("imagePath")) {
      const url = `${UrlEnum.getImage}/${methods.getValues("imagePath")}`;
      getImage(url, (data) => {
        if (data.type.includes("pdf")) setIsPdf(true);
        const reader = new FileReader();
        reader.onload = function () {
          const dataUrl = reader.result;
          setImage(dataUrl);
        };
        reader.readAsDataURL(data);
      });
    }
  }, [location, imagePath]);

  /**
   *
   */
  const handleUpdate = () => {
    const model = methods.getValues();
    const formData = generateFormData(model, UserPropertiesArray);
    post(`${UrlEnum.updateUserData}`, formData).then((response) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.updateSuccess);
        methods.reset(response.request);
        localStorage.setItem("user", JSON.stringify(response.request));
        cachedData.setKey("user", response.request);
      }
    });
  };

  /**
   *
   */
  useEffect(() => {
    const storage = window.localStorage;
    const user = JSON.parse(storage.getItem("user") || "");
    if (user) methods.reset(user);
  }, [location]);

  /**
   *
   * @returns
   */
  const renderImage = () => {
    return (
      <>
        {zoom ? (
          <div
            onClick={() => setZoom(false)}
            className={`${classes.lightBox} ${classes.show} relative`}
          >
            <img
              src={image}
              alt="doc"
              width="80%"
              height="80%"
              style={{ objectFit: "contain" }}
            />
          </div>
        ) : null}
        <img
          src={image}
          alt="doc"
          width="100%"
          height="350px"
          style={{ objectFit: "contain", alignSelf: "center" }}
          onClick={() => setZoom(true)}
        />
        <input
          {...methods.register("image")}
          style={{ marginBottom: 10, width: "100%" }}
          type="file"
          name="image"
          accept=".jpg, .png, .pdf"
        />
      </>
    );
  };

  /**
   *
   * @returns
   */
  const renderAccountValidationItem = () => {
    switch (methods.getValues("FK_userAccountId")) {
      case 1:
      case 4:
        return (
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              variant="standard"
              type="number"
              id="ValidationCode"
              label={
                methods.getValues("FK_userAccountId") === 1
                  ? Vocabulary.validationNo
                  : Vocabulary.petOwnerCode
              }
              {...methods.register("ValidationCode")}
              name="ValidationCode"
              fullWidth
            />
          </Grid>
        );
      case 2:
      case 3:
        return (
          <Grid item xs={12} sm={12} md={12}>
            <fieldset className={classes.fieldset}>
              <Typography align="left" className={classes.typography}>
                {methods.getValues("FK_userAccountId") === 2
                  ? Vocabulary.studentCard
                  : Vocabulary.qualificationDiploma}
              </Typography>
              {isPdf ? (
                <Button
                  variant={"contained"}
                  onClick={() => {
                    const fileName = `${methods.getValues("userName")}.pdf`;
                    downloadPdf(image, fileName);
                  }}
                >
                  {Vocabulary.downloadPDF}
                </Button>
              ) : (
                renderImage()
              )}
            </fieldset>
          </Grid>
        );
      default:
        <div></div>;
    }
  };

  return (
    <>
      <input type="hidden" {...methods.register("imagePath")}></input>
      <ToastContainer hideProgressBar={true} />
      <FormProvider {...methods}>
        <form
          className={classes.container}
          onSubmit={methods.handleSubmit(handleUpdate)}
        >
          <Paper elevation={12} className={classes.paper}>
            <Typography variant="h6" align="left">
              {Vocabulary.userData}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  id="email"
                  type="email"
                  disabled
                  label={Vocabulary.email}
                  {...methods.register("email")}
                  fullWidth
                  error={typeof methods.formState.errors.email === "object"}
                  helperText={methods.formState.errors.email?.message?.toString()}
                  variant="standard"
                  name="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  {...methods.register("password")}
                  id="password"
                  name="password"
                  label={Vocabulary.password}
                  fullWidth
                  variant="standard"
                  error={typeof methods.formState.errors.password === "object"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={!showPassword ? "password" : "text"}
                  helperText={methods.formState.errors.password?.message?.toString()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  {...methods.register("fullName")}
                  id="fullName"
                  name="fullName"
                  label={Vocabulary.fullName}
                  fullWidth
                  variant="standard"
                  error={typeof methods.formState.errors.fullName === "object"}
                  helperText={methods.formState.errors.fullName?.message?.toString()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  disabled
                  id="userName"
                  label={Vocabulary.username}
                  {...methods.register("userName")}
                  error={typeof methods.formState.errors.userName === "object"}
                  helperText={methods.formState.errors.userName?.message?.toString()}
                  fullWidth
                  variant="standard"
                  name="userName"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  type="number"
                  id="phone"
                  label={Vocabulary.phone}
                  {...methods.register("phone")}
                  error={typeof methods.formState.errors.phone === "object"}
                  helperText={methods.formState.errors.phone?.message?.toString()}
                  fullWidth
                  variant="standard"
                  name="phone"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Address modelRoot="address." />
              {CheckUserRole.isUser() ? renderAccountValidationItem() : null}
            </Grid>

            <div className={classes.saveButton}>
              <Button color="secondary" type="submit" variant="contained">
                {Vocabulary.save}
              </Button>
            </div>
          </Paper>
        </form>
      </FormProvider>
    </>
  );
}

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      margin: 5,
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    container: {
      // width: "49%",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    typography: {
      marginBottom: 15,
    },
    lightBox: {
      position: "fixed",
      zIndex: 99999999999,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      visibility: "hidden",
      opacity: 0,
      transition: "opacity ease 0.4s",
    },
    show: {
      visibility: "visible",
      opacity: 1,
    },
    iconButton: { color: "#0e5e6f" },
    fieldset: {
      borderRadius: 10,
      borderColor: "#00197540",
      padding: 20,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      width: "45%",
    },
    saveButton: {
      display: "flex",
      padding: 10,
      justifyContent: "flex-end",
    },
  });

export default withStyles(styles, { withTheme: true })(UserData);
