export class LoginModel {
  email: string | null = "";
  password: string | null = "";
  reCaptcha: string | null = "";
}

export class WizardModel {
  isUserAccount = "true";
  userName = "";
  email = "";
  fullName = "";
  phone = "";
  ValidationCode = "";
  image = "";
  FK_userAccountId = "1";
  FK_subscriptionId = "";
  country = "";
  county = "";
  city = "";
  street = "";
  bloc = "";
  entrance = "";
  apartment = "";
  streetNumber = "";
  floor = "";
  postalCode = "";
  address = "";
  name = "";
  FK_categoryId = "1";
  CUI = "";
  registerNo = "";
  representativeName = "";
  userNames: Array<string> = [];
  emails: Array<string> = [];
}

export class Address {
  country = "";
  county = "";
  city = "";
  address = "";
}

export class Company {
  name = "";
  FK_categoryId = "";
  CUI = "";
  registerNo = "";
  representativeName = "";
  email = "";
  phone = "";
  address = new Address();
}

export class RequestModel {
  userName = "";
  email = "";
  password = "";
  fullName = "";
  phone = "";
  ValidationCode = "";
  imagePath = "";
  FK_addressId = "";
  FK_companyId = "";
  FK_accountStatusId = "";
  FK_roleId = "";
  FK_subscriptionId = "";
  address = new Address();
  company = new Company();
}

export type indexedType = {
  [index: string]: any;
};

export type UserRole = {
  id: number;
  role: string;
};

export type Category = {
  name: string | null;
  id: number | null;
};
