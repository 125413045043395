import { Button, Select, Typography } from "@mui/material";
import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import { Vocabulary } from "../../Utils/Vocabulary";

import ShortUniqueId from "short-unique-id";
import { LocalUrlEnum, UrlEnum, get, post } from "../../Utils/Utils";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  classes: any;
};

function ExpiredPayment(props: Props) {
  const { classes } = props;
  const uid = new ShortUniqueId({ length: 30 });
  const location = useLocation();
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [selectedSubscriptions, setSelectedSubscription] = useState<any>(null);
  const [userData, setUser] = useState(null);

  /**
   *
   */
  useEffect(() => {
    if (
      location.state != undefined &&
      location?.state?.user?.FK_subscriptionId
    ) {
      setUser(location.state.user);
      get(UrlEnum.getSubscriptionTypes).then((res: any) => {
        const obj = res.subscriptions.find(
          (o: any) => o.id === location.state.user.FK_subscriptionId
        );
        const subscriptionsForUser = res.subscriptions.filter(
          (subs: any) => subs.type == obj.type
        );
        setSubscriptionOptions(subscriptionsForUser);
      });
    }
  }, []);

  /**
   *
   */
  const handleSubscriptionChange = (event: any) => {
    setSelectedSubscription(event.target.value);
  };

  /**
   *
   */
  const redirectToPayment = async () => {
    if (!selectedSubscriptions) {
      toast.error(Vocabulary.selectSubscriptionPeriod);
      return;
    }
    const newUser: any = Object.assign({}, userData);
    const newSubscription = JSON.parse(selectedSubscriptions).value;
    newUser.FK_subscriptionId = newSubscription.id;
    const updateUserSubscription = await post(
      UrlEnum.updateSubscriptionPlan,
      newUser
    );

    if (!updateUserSubscription.errors) {
      // const url = "https://ecclients.btrl.ro:5443/payment/rest/register.do";
      const url = "https://ecclients.btrl.ro/payment/rest/register.do";
      const user = JSON.parse(localStorage.user);
      const orderDate = new Date();
      const order = {
        orderCreationDate: `${orderDate.getFullYear()}-${orderDate.getMonth()}-${orderDate.getDate()}`,
        customerDetails: {
          email: user.email,
          phone: `40${
            user.phone
              ? user.phone.substring(1).replace(/\s/g, "")
              : user.company.phone.substring(1).replace(/\s/g, "")
          }`,
          deliveryInfo: {
            deliveryType: "abonament",
            country: "642",
            city: user.address ? user.address.city : user.company.address.city,
            postAddress: user.address
              ? user.address.city
              : user.company.address.city,
          },
          billingInfo: {
            country: "642",
            city: user.address ? user.address.city : user.company.address.city,
            postAddress: user.address
              ? user.address.city
              : user.company.address.city,
          },
        },
      };
      const params = new URLSearchParams({
        userName: "Vetmedghid_API",
        password: "caRqYFD8nPCY6NV_Ap!",
        orderNumber: `${uid()}`,
        amount: (Number(newSubscription.price) * 100).toString(),
        currency: "946",
        description: "abonament VETMED",
        returnUrl: `${process.env.REACT_APP_URL}/${LocalUrlEnum.statusPayment}`,
        orderBundle: JSON.stringify(order),
      });
      // const params = new URLSearchParams({
      //   userName: "test_iPay3_api",
      //   password: "test_iPay3_ap!e4r",
      //   orderNumber: `${uid()}`,
      //   amount: (100).toString(),
      //   currency: "946",
      //   description: "abonament VETMED",
      //   returnUrl: `${process.env.REACT_APP_URL}/${LocalUrlEnum.statusPayment}`,
      //   orderBundle: JSON.stringify(order),
      // });
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: params,
      })
        .then(async function (response) {
          return response.json();
        })
        .then(function (result) {
          localStorage.setItem("orderId", result.orderId);
          window.location.href = result.formUrl;
        })
        .catch((err) => {
          localStorage.setItem("error", err.errorMessage);
          console.log(err);
        });
    } else {
      toast.error(updateUserSubscription.errors);
    }
  };
  return (
    <div className={classes.root}>
      <ToastContainer hideProgressBar={true} />
      <div className={classes.image}>
        <img src={"/vetmed.svg"} alt="VETMED GHID" width="70%" />
      </div>
      <Typography variant="h5" className={classes.message}>
        {Vocabulary.expiredPayment}
      </Typography>
      <div className={classes.selectContainer}>
        <Select
          native
          required
          value={selectedSubscriptions ? selectedSubscriptions : ""}
          sx={{
            color: "white",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(228, 219, 233, 0.25)",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
          defaultValue={null}
          id={"FK_subscriptionId"}
          name={"FK_subscriptionId"}
          onChange={(event) => {
            handleSubscriptionChange(event);
          }}
        >
          <option value="" selected disabled hidden>
            {Vocabulary.selectSubscriptions}
          </option>
          {subscriptionOptions.map((value: any, key: any) => {
            return (
              <option
                style={{ background: "rgba(0, 0, 0, 0.7)" }}
                value={JSON.stringify({ value })}
                key={key}
              >
                {`${value.type} ${value.price} lei/${value.valability} ${
                  key === 0 ? Vocabulary.month : Vocabulary.months
                }`}
              </option>
            );
          })}
        </Select>
      </div>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => redirectToPayment()}
      >
        {Vocabulary.renewalPayment}
      </Button>
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      paddingTop: "22vh",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#000",
      background:
        "radial-gradient(circle, rgba(74,74,74,1) 0%, rgba(0,0,0,1) 93%)",
    },

    button: {
      height: "50px",
      width: "200px",
      marginTop: "70px !important",
      paddingRight: "3em",
    },
    form: {
      width: "60%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    image: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 50,
      width: "350px",
      [theme.breakpoints.down("sm")]: {
        width: "200px",
      },
    },
    selectContainer: {
      marginTop: 30,
      color: "#fff",
    },
    text: {
      color: "#FFF",
      textDecoration: "inherit",
    },

    message: {
      color: "#FFF",
      textAlign: "justify",
      [theme.breakpoints.down("sm")]: {
        padding: "2em",
        textAlign: "center",
      },
    },
  });

export default withStyles(styles, { withTheme: true })(ExpiredPayment);
