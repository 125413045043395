import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import { Vocabulary } from "../../Utils/Vocabulary";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Grid, InputLabel, Paper } from "@mui/material";
import CompanyRequest from "../../Components/user/CompanyRequest";
import UserRequest from "../../Components/user/UserRequest";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { get, UrlEnum, post } from "../../Utils/Utils";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";

type Props = {
  classes: any;
};

function UserEdit(props: Props) {
  const { classes } = props;
  const idCache = CachedDataSingleton.getInstance().get("id");
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });
  const location = useLocation();

  /**
   *
   */
  useEffect(() => {
    if (location.state != undefined) {
      const id = location.state.id;
      getUser(id);
    }
  }, [location]);

  /**
   *
   */
  useEffect(() => {
    if (idCache) {
      getUser(idCache);
      // disableAllFields();
    }
  }, [idCache]);

  /**
   *
   */
  const getUser = (id: any) => {
    get(`${UrlEnum.getUser}/${id}`).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        methods.reset(response.request);
      }
    });
  };

  /**
   *
   * @param model
   */
  const handleUpdate = () => {
    const model = methods.getValues();
    const id = location.state.id;

    post(`${UrlEnum.updateRequestData}/${id}`, model).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.updateSuccess);
        methods.reset(response.request);
      }
    });
  };
  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <input type="hidden" {...methods.register("imagePath")}></input>
      <InputLabel>{Vocabulary.userData.toUpperCase()}</InputLabel>
      <Paper className={classes.paper}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleUpdate)}>
            {methods ? (
              methods.getValues("FK_companyId") ? (
                <CompanyRequest />
              ) : (
                <UserRequest />
              )
            ) : null}
            <Grid
              container
              justifyContent="flex-end"
              className={classes.buttonContainer}
            >
              {idCache ? null : (
                <Button color="secondary" type="submit" variant="contained">
                  {Vocabulary.save}
                </Button>
              )}
            </Grid>
          </form>
        </FormProvider>
      </Paper>
    </>
  );
}
const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      margin: 5,
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },

    addressContainer: {
      marginTop: "10px !important",
      paddingTop: 10,
    },

    saveButton: {
      display: "flex",
      padding: 10,
      justifyContent: "flex-end",
    },
    select: {
      marginTop: "2",
    },
  });
export default withStyles(styles, { withTheme: true })(UserEdit);
