import { UserRole, indexedType } from "../Models/Models";

export const nrOfItemsPerPage = 20;
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const UrlEnum: indexedType = {
  login: "/api/login",
  register: "/api/register",
  logout: "/api/logout",
  getAccountTypes: "/api/accounts",
  getCompanyCategories: "/api/company/categories",
  getSubscriptionTypes: "/api/subscriptions",
  validateUserName: "/api/check/user",
  getAllRequests: "/api/requests",
  getUser: "/api/user",
  getStatuses: "/api/statuses",
  getImage: "/api/image",
  publicImage: "/api/publicImage",
  getPublicImage: "/api/publicImage",
  updateUserData: "/api/user",
  updateRequestData: "/api/request",
  updateRequestStatus: "/api/requestStatus",
  getUsers: "/api/users",
  getCategory: "/api/category",
  getUserRoles: "/api/roles",
  getCategories: "/api/categories",
  Company: "/api/company",
  attributes: "/api/attributes",
  getAttribute: "/api/attribute",
  deleteAttributes: "/api/deleteAttributes",
  tags: "/api/tags",
  getTag: "/api/tag",
  article: "/api/article",
  articles: "/api/articles",
  deleteArticles: "/api/deleteArticles",
  mainCategories: "/api/mainCategories",
  mainAttributes: "/api/mainAttributes",
  getUserArticles: "/api/userArticles",
  getUserArticle: "/api/userArticle",
  addUser: "/api/addUser",
  forgotPassword: "/api/forgot-password",
  getAdmins: "/api/admins",
  getAdmin: "/api/admin",
  statistics: "/api/statistics",
  deleteCategories: "/api/deleteCategories",
  userTags: "/api/userTags",
  getSubscription: "/api/subscription",
  registerPayment: "/api/registerPayment",
  updateSubscriptionPlan: "/api/updateSubscriptionPlan",
};

export const AccountTypes = {
  MedicVeterinar: "1",
  StudentVeterinar: "2",
  TehnicianSauAsistentVeterinar: "3",
  FermierSauProprietarDeAnimale: "4",
};

export const SubscriptionTypes = {
  BASIC: "BASIC",
  PREMIUM: "PREMIUM",
  PROFESSIONAL: "PROFESSIONAL",
  STUDENT: "STUDENT",
};

export const LocalUrlEnum: indexedType = {
  login: "login",
  register: "register",
  logout: "logout",
  requests: "requests",
  request: "request",
  settings: "settings",
  users: "users",
  user: "user",
  categories: "categories",
  category: "category",
  tags: "tags",
  attributes: "atributes",
  articles: "articles",
  article: "article",
  userCategories: "categorii",
  resetPassword: "reset-password",
  admin: "admin",
  expiredPayment: "expiredPayment",
  statusPayment: "statusPayment",
};

export const UserPropertiesArray: Array<string> = [
  "id",
  "userName",
  "email",
  "fullName",
  "phone",
  "ValidationCode",
  "image",
  "imagePath",
  "FK_userAccountId",
  "FK_subscriptionId",
  "address",
  "name",
  "FK_categoryId",
  "CUI",
  "registerNo",
  "representativeName",
  "password",
];

export const CompanyUserPropertiesArray: Array<string> = [
  "id",
  "email",
  "phone",
  "name",
  "FK_categoryId",
  "CUI",
  "registerNo",
  "representativeName",
  "userNames[]",
  "emails[]",
];

export const CategoryPropertiesArray: Array<string> = [
  "id",
  "name",
  "link",
  "order",
  "mainMenu",
  "image",
  "FK_parentId",
];

export const TagsPropertiesArray: Array<string> = [
  "name",
  "description",
  "link",
];

export const RegisterWizardArray: Array<string> = [
  "isUserAccount",
  "userName",
  "email",
  "fullName",
  "phone",
  "ValidationCode",
  "image",
  "FK_userAccountId",
  "FK_subscriptionId",
  "country",
  "county",
  "city",
  "address",
  "name",
  "FK_categoryId",
  "CUI",
  "registerNo",
  "representativeName",
  "userNames[]",
  "emails[]",
];

export const HttpStatusCode = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  RequestTimeout: 408,
  InternalServerError: 500,
  NotImplemented: 501,
  UnprocessableEntity: 422,
};

/**
 *
 * @param name
 * @returns
 */
export function readCookie(name: any) {
  const n = `${name}=`;
  const index = document.cookie.indexOf(n);
  if (index < 0) {
    return "";
  }
  let cookieValue = document.cookie.substring(index + n.length);
  const end = cookieValue.indexOf(";");
  if (end >= 0) {
    cookieValue = cookieValue.substring(0, end);
  }
  return cookieValue;
}

/**
 *
 * @param response
 * @param history
 * @returns
 */
async function handleHttpResponse(response: any, history: any) {
  const res = response.clone();
  switch (res.status) {
    case HttpStatusCode.OK:
      try {
        const resp = await res.json();
        return resp;
      } catch (e) {
        const r = await response.text();
        return r;
      }
    case HttpStatusCode.BadRequest:
    case HttpStatusCode.InternalServerError:
    case HttpStatusCode.Forbidden:
      try {
        const resp = await res.json();
        return { errors: resp[Object.keys(resp)[0]] };
      } catch (e) {
        try {
          const errText = await response.text();
          if (errText.substring(0, 1) === "<") {
            return { errors: "Error" };
          }
          if (errText && errText.length > 0) {
            return { errors: errText };
          }
        } catch (ex) {
          console.log(ex);
        }
      }
      return { errors: response.statusText };
    case HttpStatusCode.Unauthorized:
    case HttpStatusCode.UnprocessableEntity:
      if (document.location.pathname !== LocalUrlEnum.login) {
        const expires = new Date(Date.now() - 3600).toUTCString();
        document.cookie = `access_token=;expires=${expires};path=/`;
        document.location.href = `/${LocalUrlEnum.login}`;
        return { errors: "Unauthorized" };
      }
      try {
        const resp = await res.json();
        return { errors: resp[Object.keys(resp)[0]] };
      } catch (e) {
        try {
          const errText = await response.text();
          if (errText.substring(0, 1) === "<") {
            return { errors: "Error" };
          }
          if (errText && errText.length > 0) {
            return { errors: errText };
          }
        } catch (ex) {
          console.log(ex);
        }
      }
      break;
    case HttpStatusCode.NotFound:
      return { errors: "Not Found" };
    default:
      break;
  }
  return res;
}

/**
 *
 * @param url
 * @param data
 * @param props
 * @param history
 * @returns
 */
export async function post(
  url: string,
  data: any = null,
  props: any = null,
  history = null
) {
  const access_token = readCookie("access_token");
  let properties: RequestInit = {
    method: "POST",
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(data),
  };

  if (data instanceof FormData) {
    properties = {
      method: "POST",
      cache: "no-cache",
      body: data,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow",
    };
  }
  if (props) {
    properties = Object.assign(properties, props);
  }
  const response = await fetch(url, properties);

  return handleHttpResponse(response, history);
}

/**
 *
 * @param url
 * @param requestHeaders
 * @param history
 * @returns
 */
export async function get(
  url: any,
  requestHeaders = undefined,
  history = null
) {
  const access_token = readCookie("access_token");
  const headers = requestHeaders
    ? requestHeaders
    : {
        "Content-Type": "application/json",
        Accept: "*/json",
        Authorization: `Bearer ${access_token}`,
      };
  const response = await fetch(url, {
    method: "GET",
    cache: "no-cache",
    headers: headers,
  });

  return handleHttpResponse(response, history);
}

/**
 * delete data from http request
 * @param url
 * @param history
 * @returns
 */
export async function httpDelete(url: any, history = null) {
  const access_token = readCookie("access_token");
  const headers = {
    "Content-Type": "application/json",
    Accept: "*/json",
    Authorization: `Bearer ${access_token}`,
  };
  const response = await fetch(url, {
    method: "DELETE",
    cache: "no-cache",
    headers: headers,
  });

  return handleHttpResponse(response, history);
}

/**
 *
 * @param url
 * @param callback
 * @param requestHeaders
 * @param history
 */
export function getImage(url: any, callback: (data: any) => void) {
  const access_token = readCookie("access_token");
  const headers = {
    "Content-Type": "application/json",
    Accept: "*/json",
    Authorization: `Bearer ${access_token}`,
  };
  fetch(url, {
    headers: headers,
  })
    .then((response) => response.blob())
    .then((data) => {
      callback(data);
    });
}

/**
 *
 */
export function disableAllFields() {
  const previewDialogContent = document.getElementById("previewDialogContent");
  if (!previewDialogContent) return;

  const fullInputHTML = previewDialogContent.getElementsByTagName("input");
  if (fullInputHTML.length <= 0) return;

  const fullDatePickerHTLM =
    previewDialogContent.getElementsByClassName("disableWrapper");

  const fullSelectHTML = previewDialogContent.getElementsByTagName("select");

  const fullLabelHTML = previewDialogContent.getElementsByTagName("label");

  const fieldSets = previewDialogContent.getElementsByTagName("fieldset");

  const fullEditor = previewDialogContent.getElementsByClassName(
    "notranslate public-DraftEditor-content"
  );

  const fullTextAreaHTML =
    previewDialogContent.getElementsByTagName("textarea");

  const fullButton = previewDialogContent.getElementsByTagName("button");

  let index = 0;

  if (fullDatePickerHTLM.length > 0)
    for (index = 0; index < fullDatePickerHTLM.length; index++) {
      fullDatePickerHTLM[index].addEventListener(
        "click",
        (e) => {
          e.stopImmediatePropagation();
          e.stopPropagation();
        },
        true
      );
      fullDatePickerHTLM[index].addEventListener(
        "mousedown",
        (e) => {
          e.stopImmediatePropagation();
          e.stopPropagation();
        },
        true
      );
    }

  for (index = 0; index < fullTextAreaHTML.length; index++)
    fullTextAreaHTML[index].setAttribute("disabled", "disabled");

  for (index = fullButton.length - 1; index >= 0; index--) {
    fullButton[index].remove();
  }

  for (index = 0; index < fieldSets.length; index++)
    if (!fieldSets[index].getAttribute("aria-hidden"))
      fieldSets[index].style.border = "none";

  for (index = 0; index < fullInputHTML.length; index++) {
    if (fullInputHTML[index].id === "CautareNume")
      fullInputHTML[index].removeAttribute("disabled");
    else {
      fullInputHTML[index].setAttribute("disabled", "disabled");
      fullInputHTML[index].removeAttribute("accept");
    }
  }

  for (index = 0; index < fullSelectHTML.length; index++) {
    fullSelectHTML[index].setAttribute("disabled", "disabled");
  }

  for (index = 0; index < fullLabelHTML.length; index++)
    fullLabelHTML[index].setAttribute("disabled", "disabled");

  for (index = 0; index < fullEditor.length; index++)
    fullEditor[index].setAttribute("contentEditable", "false");
}

/**
 *
 * @param event
 * @param model
 * @param callback
 */
export function handleChange(event: any, model: any) {
  const newModel: any = Object.assign({}, model);
  const { name } = event.target;
  if (name === "image") {
    const { 0: file } = event.target.files;
    newModel[name] = file;
  } else {
    if (name.indexOf("[") > 0) {
      const nameParts: any = name.substring(0, name.indexOf("["));
      const index = parseInt(
        name.substring(name.indexOf("[") + 1, name.indexOf("]"))
      );
      newModel[nameParts][index] = event.target.value;
    } else {
      newModel[name] = event.target.value;
    }
  }
  return newModel;
}

/**
 *
 * @param source
 */
export function downloadPdf(source: any, _fileName: string) {
  const downloadLink = document.createElement("a");
  downloadLink.href = source;
  downloadLink.download = _fileName;
  downloadLink.click();
}

const userRoles = {
  admin: { id: 1, role: "admin" },
  user: { id: 2, role: "user" },
};

export class CheckUserRole {
  /**
   * if is admin
   */
  static isAdmin(): boolean {
    return this.hasRole(userRoles.admin);
  }
  /**
   * if is user
   */
  static isUser(): boolean {
    return this.hasRole(userRoles?.user);
  }

  /**
   * checks to see if current user has a set role
   * @param neededRole
   */
  static hasRole(role: UserRole) {
    const storage = window.localStorage;
    const userFormStorage = storage.getItem("user");
    const userData = userFormStorage ? JSON.parse(userFormStorage || "") : null;
    const userRole = userData?.FK_roleId;
    if (userRole && userRole === role.id) return true;
    return false;
  }
}

/**
 *
 * @param model
 * @param propertiesArray
 * @returns
 */
export function generateFormData(model: any, propertiesArray: Array<string>) {
  const formData = new FormData();
  propertiesArray.forEach((property) => {
    switch (property) {
      case "image":
        if (model[property])
          formData.append(
            property,
            model[property][0] ? model[property][0] : model[property]
          );
        break;
      case "address":
        if (
          typeof model[property] === "object" &&
          !Array.isArray(model[property]) &&
          model[property] !== null
        ) {
          formData.append(property, JSON.stringify(model[property]));
        } else {
          formData.append(property, model[property]);
        }
        break;
      case "userNames[]":
        if (model["userNames"])
          for (let i = 0; i < model["userNames"].length; i++) {
            formData.append("userNames[]", model["userNames"][i]);
          }
        break;
      case "emails[]":
        if (model["emails"])
          for (let i = 0; i < model["emails"].length; i++) {
            formData.append("emails[]", model["emails"][i]);
          }
        break;
      default:
        if (typeof model[property] === "boolean") {
          formData.append(property, model[property]);
        } else
          formData.append(property, model[property] ? model[property] : null);
    }
  });
  return formData;
}

/**
 *
 * @param {*} image
 * @param {*} maxWidth
 * @returns Blob
 */
export function resizeImage(file: any) {
  const promise = new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (readerEvent) {
        const image = new Image();
        image.src = readerEvent.target?.result as string;
        image.onload = function (imageEvent) {
          const imageFile = resize(image, file.name);
          resolve(imageFile);
        };
        image.onerror = function (err) {
          reject(err);
        };
      };
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
  return promise;
}

/**
 *
 * @param {*} image
 * @param {*} maxWidth
 * @param {*} fileName
 * @returns
 */
function resize(image: any, fileName: any) {
  const canvas = document.createElement("canvas");
  let { width } = image,
    { height } = image;
  const maxWidth = width > 1900 ? 1900 : width;
  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }
  } else {
    if (height > maxWidth) {
      width *= maxWidth / height;
      height = maxWidth;
    }
  }
  canvas.width = width;
  canvas.height = height;
  canvas?.getContext("2d")?.drawImage(image, 0, 0, width, height);
  const dataUrl = canvas.toDataURL("image/png");
  const resizedImage = dataURLToBlob(dataUrl);
  return blobToFile(resizedImage, fileName);
}

/**
 *
 * @param {*} dataURL
 * @param {*} fileName
 * @returns
 */
function dataURLToBlob(dataURL: any) {
  const BASE64_MARKER = ";base64,";
  let parts = dataURL.split(BASE64_MARKER);
  let contentType = parts[0].split(":")[1];
  let raw = window.atob(parts[1]);
  const rawLength = raw.length;

  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(",");
    contentType = parts[0].split(":")[1];
    raw = parts[1];
    return new Blob([raw], { type: contentType });
  }
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  const blob = new Blob([uInt8Array], { type: contentType });
  return blob;
}

/**
 *
 * @param {*} ab
 * @param {*} fileName
 * @returns
 */
function blobToFile(ab: any, fileName: any) {
  const f = new File([ab], fileName, {
    type: "image/jpeg",
    lastModified: new Date().getTime(),
  });
  return f;
}

/**
 *
 */
export const logout = (callback: any) => {
  post(UrlEnum.logout).then((response) => {
    if (response.errors) return null;
    const expires = new Date(Date.now() - 3600).toUTCString();
    document.cookie = `access_token=;expires=${expires};path=/`;
    document.cookie = `userName=;expires=${expires};path=/`;
    document.cookie = `fullName=;expires=${expires};path=/`;
    localStorage.removeItem("user");
    callback();
  });
};

export const ResolutionBreakPoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export function isMobile() {
  return document.body.offsetWidth < ResolutionBreakPoints.md;
}
