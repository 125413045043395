export const Vocabulary = {
  login: "Login",
  userName: "Utilizator",
  password: "Parola",
  resetPassword: "Resetare Parolă",
  requireRegister: "Solicită Înregistrare",
  legalPerson: "Persoană juridică",
  naturalPerson: "Persoană fizică",
  selectAccountType: "Selectează tipul contului",
  selectCompanyCategory: "Selectează tipul companiei",
  medic: "Medic veterinar",
  student: "Student veterinar",
  assistant: "Tehnician Veterinar/Asistent veterinar",
  owner: "Fermieri/Proprietari de animale de companie",
  accountType: "Cont",
  selectRole: "Selectează rolul utilizatorului:",
  fullName: "Nume și prenume ",
  username: "Nume utilizator",
  email: "Email",
  phone: "Telefon",
  requiredField: "Acest câmp este obligatoriu",
  companyName: "Denumirea companiei",
  cuiValidation:
    "Trebuie să conțină un număr de identificare, între 1 și 9 cifre",
  CUI: "CUI",
  registerNo: "Nr. înregistrare",
  representativeName: "Nume și prenume administrator",
  emailValidation: "Introduceți  o adresa valida.",
  phoneValidation: "Introduceți un număr de telefon valid.",
  next: "Următorul pas",
  back: "Pasul anterior",
  country: "Țara",
  county: "Județ",
  city: "Oraș",
  address: "Adresa",
  street: "Strada",
  bloc: "Bloc",
  entrance: "Scara",
  apartment: "Apartament",
  streetNumber: "Număr",
  floor: "Etaj",
  postalCode: "Cod poștal",
  validationNo: "Număr atestat valid medic veterinar",
  studentCard: "Carnet de student vizat pe anul în curs:",
  qualificationDiploma: "Diplomă de calificare:",
  petOwnerCode: "Cod exploatație / Serie carnet de sănătate animal",
  loadFile: "Incărcare fișier (.jpg/.pdf/.png)",
  currency: "lei",
  month: "lună",
  tag: "Etichetă",
  months: " luni",
  subscription: "Abonamentul",
  subscriptionText:
    "Veți beneficia de o perioadă de free trial de 7 zile. După finalizarea perioadei veți achita contravaloarea abonamentului.",
  registerRequest: "Solicită Cont Nou",
  registerForm: "Formular de înregistrare",
  unavailableUserName: "Acest nume de utilizator nu este disponibil",
  uploadFile: "Încărcați fișierul",
  selectSubscriptions: "Selectează abonamentul",
  selectSubscriptionTime: "Selectează perioada abonamentului",
  settings: "Setări",
  logout: "Deconectare",
  demands: "Solicitări",
  users: "Utilizatori",
  activeUsers: "Utilizatori activi pe platformă",
  categories: "Categorii",
  articles: "Articole",
  addNewArticle: "Adaugă articol",
  attributes: "Atribute",
  tags: "Etichete",
  dashboard: "Dashboard",
  requestsList: "Solicitări cont",
  view: "Vizualizare",
  edit: "Editează",
  noResultsFound: "Nu a fost găsit nici un rezultat...",
  request: "Solicitare",
  close: "Închide",
  save: "Salvare",
  registerUserData: "Informații formular de înregistrare",
  updateAccountStatus: "Status cont",
  continueLogin: "Continuă pe pagina de conectare",
  mentions: "Mențiuni",
  approved: "Aprobat",
  requestAdditions: "Solicitare completări",
  processing: "În procesare",
  rejected: "Respins",
  freeTrial: "Perioadă gratuită 7 zile",
  changeStatus: "Modifică status",
  downloadPDF: "Descarcă PDF",
  companyData: "Date despre companie",
  userData: "Detalii utilizator",
  startDate: "Dată început",
  endDate: "Dată finalizare",
  filters: "Filtre",
  resetFilter: "Șterge filtre",
  subscriptionDetails: "Detalii abonament",
  updateSuccess: "Datele au fost actualizate cu succes",
  addNewUser: "Adaugă utilizator",
  addCategory: "Adaugă categorie",
  addAttribute: "Adaugă atribut",
  pageNotFound: "Pagina nu a fost găsită!",
  categoryName: "Nume categorie",
  category: "Categorie",
  link: "Link",
  mainMenuCategory: "Categorie în pagina principală",
  order: "Ordine",
  parentCategory: "Categorie părinte",
  selectImage: "Trageți și plasați o imagine reprezentativă aici sau apăsați",
  categoryForm: "Formular de adăugare categorie",
  companyCategory: "Categorie companie",
  requestData: "Detalii cont",
  attributeName: "Denumire atribut",
  terms: "Termeni",
  Attribute: "Atribut",
  delete: "Șterge",
  deleteSucces: "Datele au fost șterse cu succes",
  attributeFilter: "Atribut în pagina client",
  addTag: "Adaugă etichetă",
  tagName: "Nume etichetă",
  description: "Descriere",
  addDataSucces: "Datele au fost adăugate cu succes",
  article: "Articol",
  articleTitle: "Titlu articol",
  selectCategory: "Selectează categoria",
  contentDoctorStudent: "Conținut pentru Medici veterinari/Studenți veterinari",
  contentAssistentTehnician: "Conținut pentru Asistent/Tehnician veterinar",
  contentFarmer: "Conținut pentru Fermieri/Proprietari de animale",
  selectTags: "Selectează cuvinte cheie",
  selectAttributes: "Selectează atribute",
  readMore: "Citește mai mult...",
  companyAddress: "Corod, Strada Lalelelor, Nr.9, Galați",
  companyContact: "contact@vetmed-ghid.ro",
  companyPhone: "+40 766 229 625",
  contact: "Contact",
  usefulLinks: "Link-uri utile",
  privacyPolicy: "Politica de confidențialitate",
  termsAndConditions: "  Termeni și condiții",
  support: "Solicita suport",
  addImage: "Adaugă imagine",
  searchForArticle: "Caută un articol",
  send: "Trimite",
  resetPasswordEnterEmail:
    "Introduceți adresa dumneavoastră de email și vă vom trimite un link pentru resetare.",
  dontHaveAccount: "Nu ai cont? Înregistrează-te acum.",
  admins: "Administratori",
  addNewAdmin: "Adaugă administrator",
  seeArticles: "Vezi articole",
  viewedArticles: "Articole vizualizate",
  expiredPayment:
    "Abonamentul dumneavoastră a expirat. Vă rugăm să îl reînnoiți.",
  renewalPayment: "Reînnoire abonamet",
  successfulPayment: "Abonamentul dumneavoastră a fost reînnoit cu succes!",
  declinedPayment:
    "Tranzacție eșuată din diferite motive (Card blocat, fonduri insuficiente, limită tranzacționare depășită, CVV greșit, card expirat, banca emitentă a deținătorului de card declină tranzacția etc)",
  unpaid: "Neplătit",
  subscriptionType: "Tipul abonamentului",
  subscriptionDeadline: "Valabil până la",
  actionCode104:
    "Card restricționat (blocat temporar sau permanent din cauza lipsei plății sau a morții titularului de card).",
  actionCode124:
    "Tranzacția nu poate fi autorizată din cauza acordului guvernului, băncii centrale sau instituției financiare, legi sau reglementări",
  actionCode320: "Card inactiv. Vă rugăm activați cardul",
  actionCode801: "Emitent indisponibil.",
  actionCode803:
    "Card blocat. Contactați banca emitentă sau reîncercați tranzacția cu alt card.",
  actionCode804:
    "Tranzacția nu este permisă. Contactați banca emitentă sau reîncercați tranzacția cu alt card.",
  actionCode805: "Tranzacție respinsă.",
  actionCode861: "Dată expirare card greșită.",
  actionCode871: "CVV gresit.",
  actionCode905: "Card invalid. Acesta nu există în baza de date.",
  actionCode906: "Card expirat",
  actionCode913:
    "Tranzacție invalidă. Contactați banca emitentă sau reîncercați tranzacția cu alt card.",
  actionCode914: "Cont invalid. Vă rugăm contactați banca emitentă.",
  actionCode915: "Fonduri insuficiente.",
  actionCode917: "Limită tranzacționare depășită.",
  actionCode952: "Suspect de fraudă.",
  actionCode998:
    "Tranzacția în rate nu este permisă cu acest card. Te rugăm să folosești un card de credit emise de Banca Transilvania.",
  actionCode341016:
    "3DS2 authentication is declined by Authentication Response (ARes) – issuer",
  actionCode341017: "3DS2 authentication status in ARes is unknown - issuer",
  actionCode341018: "3DS2 CReq cancelled - client",
  actionCode341019: "3DS2 CReq failed - client/issuer",
  actionCode341020: "3DS2 unknown status in RReq - issuer",
  registerHelperText:
    "Dacă întâmpini dificultăți în procesul de înregistrare te rugăm să ne apelezi la numărul de telefon 0766229625.",
  subscriptionExpiration: "Expirare abonament",
  freeTrialExpiration: "Expirare perioadă gratuită de 7 zile",
  selectSubscriptionPeriod: "Selectează perioada de abonare",
};
