import { ThemeProvider, withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Fragment, useEffect, useState } from "react";
import { LocalUrlEnum, UrlEnum, get } from "../Utils/Utils";
import { Avatar, Button, Typography, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";

type Props = {
  classes: any;
};

const theme = createTheme({});

function MainCategories(props: Props) {
  const [categories, setCategories] = useState<{ subcategories: any }[]>([]);
  const { classes } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedCategoryIndex, setCategorySelectedIndex] = useState<
    number | null
  >(null);
  const navigate = useNavigate();
  /**
   *
   */
  useEffect(() => {
    get(UrlEnum.mainCategories).then((response) => {
      if (!response.errors) {
        setCategories(response.items);
      }
    });
  }, []);

  /**
   *
   * @param event
   * @param index
   */
  const handleOpen = (event: any, index: number) => {
    setCategorySelectedIndex(index);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  /**
   *
   * @param e
   * @returns
   */
  const handleClose = (e: any) => {
    if (e.currentTarget.localName !== "ul") {
      const menu = document.getElementById("simple-menu")?.children[2];
      const menuBoundary = {
        left: (menu as HTMLElement)?.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right:
          (menu as HTMLElement)?.offsetLeft +
          (menu as HTMLElement)?.offsetHeight,
        bottom:
          (menu as HTMLElement)?.offsetTop +
          (menu as HTMLElement)?.offsetHeight,
      };
      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }

    setOpen(false);
    setAnchorEl(null);
  };



  /**
   *
   * @param link
   * @param id
   */
  const navigateToSubcategory = (link: string, id: number) => {
    navigate(LocalUrlEnum.userCategories + link, {
      state: { categoryId: id },
    });
  };

  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        handleClose(e);
      },
    },
  };
  return (
    <div className={classes.container}>
      {categories?.map((category: any, index: any) => {
        return (
          <Fragment key={category.id}>
            <ThemeProvider theme={theme}>
              <Button
                style={{ marginRight: 10, zIndex: 1301 }}
                id={category.id}
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseOver={(event: any) => {
                  handleOpen(event, index);
                }}
                onMouseLeave={handleClose}
                onClick={() => {
                  navigateToSubcategory(category.link, category.id);
                }}
                className={classes.button}
                startIcon={
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    src={`${UrlEnum.getPublicImage}/${category.imagePath}`}
                  />
                }
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#000",
                    fontSize: 16,
                  }}
                >
                  {category?.name}
                </Typography>
              </Button>

              {selectedCategoryIndex !== null &&
              categories[selectedCategoryIndex].subcategories.length > 0 ? (
                <Menu
                  className={classes.menu}
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={open}
                  sx={{
                    pointerEvents: "none",
                  }}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  MenuListProps={{
                    onMouseLeave: handleClose,
                    style: { pointerEvents: "auto" },
                  }}
                >
                  {categories[selectedCategoryIndex]?.subcategories?.map(
                    (subcategory: any, id: any) => {
                      return (
                        <MenuItem
                          key={id}
                          id={id}
                          onClick={() => {
                            navigateToSubcategory(
                              subcategory.link,
                              subcategory.id
                            );
                          }}
                        >
                          {subcategory.name}
                        </MenuItem>
                      );
                    }
                  )}
                </Menu>
              ) : null}
            </ThemeProvider>
          </Fragment>
        );
      })}
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      zIndex: 1,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },

    },
    button: {
      zIndex: "1301 !important",
      marginRight: "5px !important",

    },
    menu: {
      "& .MuiPaper-root": {
        backgroundColor: "#f1f1f1",
      },
    },
  });

export default withStyles(styles, { withTheme: true })(MainCategories);
