/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Chip,
  Grid,
  Pagination,
  Paper,
  Typography,
} from "@mui/material";
import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { Vocabulary } from "../Utils/Vocabulary";
import { useNavigate } from "react-router-dom";
import { LocalUrlEnum } from "../Utils/Utils";
import { useState } from "react";
import { Pets } from "@mui/icons-material";
type Props = {
  classes: any;
  articles: any;
  nrPages: number;
};
function ArticlesGrid(props: Props) {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { classes } = props;

  /**
   *
   * @param id
   */
  const navigateToArticle = (id: any) => {
    navigate(`/${LocalUrlEnum.article}/${id}`, {
      state: { id: id },
    });
  };

  /**
   *
   * @param event
   * @param value
   */
  const handlePageChange = (event: any, value: any) => {
    window.scrollTo(0, 0);
    // handleParams("pagina", value);
    setPage(value);
  };

  /**
   *
   * @param content
   * @param index
   */
  const renderArticle = (content: any, index: any) => {
    const element = document.getElementById("article" + index);
    element?.remove();
    const e = document.createElement("div");
    let articleContent: any;
    e.innerHTML = content;
    for (let i = 0; i < 3; i++) {
      const child = e.childNodes[i];

      if (
        child instanceof HTMLElement &&
        (child as HTMLElement)?.innerText.length > 50
      ) {
        articleContent = document.createElement("div");
        articleContent.setAttribute("id", "article" + index);
        articleContent.innerText = (child as HTMLElement)?.innerText.substring(
          0,
          500
        );
        break;
      } else if (child?.nodeName === "IMG") {
        articleContent = document.createElement("img");
        articleContent.setAttribute("id", "article" + index);
        articleContent.src = (child as HTMLElement)?.outerHTML.substring(
          (child as HTMLElement)?.outerHTML.indexOf('"') + 1,
          (child as HTMLElement)?.outerHTML.lastIndexOf('" alt')
        );
      } else {
        articleContent = document.createElement("div");
      }
      articleContent.style.cssText = "width:100%";
    }
    return (
      <div
        className={classes.articleHtml}
        ref={(ref: any) => ref?.appendChild(articleContent)}
      >
        <div className={classes.overlay} />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {props.articles.length > 0 ? (
        <Grid container spacing={12}>
          {props.articles?.map((article: any, index: number) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                md={6}
                sm={12}
                className={classes.articleGridContainer}
              >
                <Paper elevation={12} className={classes.paper}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      margin: 1,
                      userSelect: "none",
                    }}
                  >
                    {article.title}
                  </Typography>
                  <div style={{ margin: 10 }}>
                    {article?.tags.map((tag: any, indexTag: any) => {
                      return (
                        <Chip
                          key={indexTag}
                          sx={{ maxWidth: "100px", marginRight: 2 }}
                          color="secondary"
                          label={tag.name}
                        />
                      );
                    })}
                  </div>
                  {renderArticle(article.content, index)}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigateToArticle(article.id);
                      }}
                      className={classes.button}
                    >
                      {Vocabulary.readMore}
                    </Button>
                  </div>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <div className={classes.noResultsFound}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "16",
              marginTop: 10,
            }}
          >
            {Vocabulary.noResultsFound}
          </Typography>
          <Pets color="primary" fontSize={"large"} />
        </div>
      )}
      {props.articles.length > 0 ? (
        <Pagination
          style={{ marginTop: 50 }}
          count={props.nrPages}
          page={page}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
        />
      ) : null}
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    articleGridContainer: {
      margin: 10,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "83px !important",
      },
    },
    container: {
      width: "70%",
      display: "flex",
      minHeight: "400px",
      padding: 10,
      flexDirection: "column",
      alignItems: "center",
      marginTop: 50,
      height: "100%",
      justifyContent: "center",
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "95%",
        padding: 10,
      },
    },
    paper: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      padding: 25,
      marginTop: "0 !important",
      boxShadow: "1px 2px 9px #808080",
    },
    button: {
      borderRadius: "25px !important",
      backgroundColor: "#3A8891 !important",
      margin: "20px !important",
    },
    overlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 999,
      background:
        "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%)",
    },
    articleHtml: {
      width: "100%",
      height: "100%",
      position: "relative",
      textAlign: "justify",
      fontSize: 14,
      userSelect: "none",
    },
    noResultsFound: {
      width: "100%",
      justifyContent: "center",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      marginTop: 100,
    },
  });
export default withStyles(styles, { withTheme: true })(ArticlesGrid);
