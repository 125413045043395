import { ToastContainer, toast } from "react-toastify";
import { FormProvider, useForm, Controller } from "react-hook-form";
import {
  Paper,
  InputLabel,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { useState, useEffect } from "react";
import {
  get,
  UrlEnum,
  post,
  disableAllFields,
  LocalUrlEnum,
} from "../../Utils/Utils";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "@mui/material/Select";
import Modal from "../../Components/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";
import WYSIWYGEditor from "../../Components/WYSIWYGEditor";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  classes: any;
};

function Article(props: Props) {
  const cachedData = CachedDataSingleton.getInstance();
  const { classes } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const idCache = cachedData.get("id");
  const preview = cachedData.get("preview");
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(0);
  const [loading, setLoading] = useState(false);
  let values: any[] = [];

  const [state, setState] = useState({
    categories: [],
    tags: [],
    attributes: [],
    attributeOrTagAdded: false,
    preview: false,
    parentCategories: [{}],
  });

  const requestValidationSchemaArticle = yup
    .object()
    .shape({
      title: yup.string().required(Vocabulary.requiredField),
    })
    .required();

  const requestValidationSchemaAttribute = yup
    .object()
    .shape({
      name: yup.string().required(Vocabulary.requiredField),
      link: yup.string().required(Vocabulary.requiredField),
    })
    .required();
  const requestValidationSchemaTag = yup
    .object()
    .shape({
      name: yup.string().required(Vocabulary.requiredField),
      description: yup.string().required(Vocabulary.requiredField),
      link: yup.string().required(Vocabulary.requiredField),
    })
    .required();

  const methodsTag = useForm({
    resolver: yupResolver(requestValidationSchemaTag),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });
  const methodsArticle = useForm({
    resolver: yupResolver(requestValidationSchemaArticle),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

  const methodsAttribute = useForm({
    resolver: yupResolver(requestValidationSchemaAttribute),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

  /**
   *
   */
  useEffect(() => {
    const promises = [
      get(UrlEnum.getCategories),
      get(UrlEnum.tags),
      get(UrlEnum.attributes),
    ];
    Promise.all(promises)
      .then((values: any) => {
        setState({
          ...state,
          categories: values[0].items,
          tags: values[1].tags,
          attributes: values[2].attributes,
          parentCategories: getCategories(
            values[0].parentCategories,
            values[0].items
          ),
        });
      })
      .catch((ex) => console.log(ex));
  }, [state.attributeOrTagAdded]);

  /**
   *
   */
  useEffect(() => {
    if (preview) disableAllFields();
  }, [preview]);

  /**
   *
   */
  useEffect(() => {
    if (idCache) {
      getArticle(idCache);
    }
  }, [idCache]);

  /**
   *
   * @param id
   */
  const getArticle = (id: any) => {
    const tagsIds: number[] = [];
    const attributeIds: number[] = [];
    const categoriesIds: number[] = [];
    get(`${UrlEnum.article}/${id}`).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      }
      methodsArticle.reset(response.article);
      //get the tags ids
      response.article.tags.map((value: any, key: any) => {
        tagsIds.push(value.id);
      });
      //get the attributes ids
      response.article.attributes.map((value: any, key: any) => {
        attributeIds.push(value.id);
      });
      //get the categories ids
      response.article.categories.map((value: any, key: any) => {
        categoriesIds.push(value.id);
      });
      methodsArticle.setValue("FK_attributeId", attributeIds);
      methodsArticle.setValue("FK_tagId", tagsIds);
      methodsArticle.setValue("FK_categoryId", categoriesIds);
      setLoading(false);
    });
  };

  /**
   *
   */
  useEffect(() => {
    if (location.state != undefined) {
      setLoading(true);
      setEdit(true);
      const { id } = location.state;
      getArticle(id);
    }
  }, [location]);

  /**
   *
   */
  const handleSubmit = () => {
    setLoading(true);
    const body = methodsArticle.getValues();
    post(UrlEnum.articles, body).then((response) => {
      setLoading(false);
      if (response.errors) {
        toast.error(response.errors);
      } else {
        navigate(`/${LocalUrlEnum.articles}`);
        toast.success(Vocabulary.addDataSucces);
      }
    });
  };

  /**
   *
   */
  const handleSubmitAttribute = () => {
    setLoading(true);
    const body = methodsAttribute.getValues();
    post(UrlEnum.attributes, body).then((response) => {
      setLoading(false);
      if (response.errors) {
        setOpen(0);
        toast.error(response.errors);
      } else {
        setOpen(0);
        methodsAttribute.reset({});
        const attributeOrTagAddedLocal = state.attributeOrTagAdded;
        setState({ ...state, attributeOrTagAdded: !attributeOrTagAddedLocal });
      }
    });
  };

  /**
   *
   */
  const handleSubmitTag = () => {
    setLoading(true);
    const body = methodsTag.getValues();
    post(UrlEnum.tags, body).then((response: any) => {
      setLoading(false);
      if (response.errors) {
        setOpen(0);
        toast.error(response.errors);
      } else {
        setOpen(0);
        methodsTag.reset({});
        const attributeOrTagAddedLocal = state.attributeOrTagAdded;
        setState({ ...state, attributeOrTagAdded: !attributeOrTagAddedLocal });
      }
    });
  };
  /**
   *
   */
  const handleUpdate = () => {
    setLoading(true);
    const body = methodsArticle.getValues();
    post(UrlEnum.article, body).then((response) => {
      setLoading(false);
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.updateSuccess);
      }
    });
  };

  /**
   *
   * @param param0
   * @param padding
   * @param categories
   */
  const getSubcategories = (
    { id, name }: any,
    padding: any,
    categories: any
  ) => {
    const subcategories = categories.filter(
      (category: any) => category.FK_parentId === id
    );

    values = [...values, { id: id, name: name, padding: padding }];
    if (subcategories.length > 0) {
      subcategories.map(({ id, name }: any, key: any) =>
        getSubcategories({ id, name }, padding + 2, categories)
      );
    }
  };

  /**
   *
   * @param parentCategories
   * @param categories
   * @returns
   */
  const getCategories = (parentCategories: any, categories: any) => {
    parentCategories.map(({ id, name }: any, key: any) => {
      getSubcategories({ id, name }, 1.5, categories);
    });
    return values;
  };

  /**
   *
   * @returns
   */
  const renderAttributeModal = () => {
    return (
      <FormProvider {...methodsAttribute}>
        <form
          id="form"
          onSubmit={methodsAttribute.handleSubmit(handleSubmitAttribute)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="name"
                label={Vocabulary.attributeName}
                {...methodsAttribute.register("name")}
                fullWidth
                error={
                  typeof methodsAttribute.formState.errors.name === "object"
                }
                helperText={methodsAttribute.formState.errors.name?.message?.toString()}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="attributeLink"
                label={Vocabulary.link}
                {...methodsAttribute.register("link")}
                fullWidth
                error={
                  typeof methodsAttribute.formState.errors.link === "object"
                }
                helperText={methodsAttribute.formState.errors.link?.message?.toString()}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControlLabel
                control={
                  <Controller
                    name="filter"
                    control={methodsAttribute.control}
                    defaultValue={false}
                    render={({ field: { value, ref, ...field } }) => (
                      <Checkbox
                        {...field}
                        inputRef={ref}
                        checked={!!value}
                        color="primary"
                        size={"medium"}
                        disableRipple
                      />
                    )}
                  />
                }
                label={Vocabulary.attributeFilter}
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  /**
   *
   * @returns
   */
  const renderTagModal = () => {
    return (
      <FormProvider {...methodsTag}>
        <form id="form" onSubmit={methodsTag.handleSubmit(handleSubmitTag)}>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="name"
                label={Vocabulary.tagName}
                {...methodsTag.register("name")}
                fullWidth
                error={typeof methodsTag.formState.errors.name === "object"}
                helperText={methodsTag.formState.errors.name?.message?.toString()}
                variant="standard"
                name="name"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="name"
                label={Vocabulary.description}
                {...methodsTag.register("description")}
                fullWidth
                error={
                  typeof methodsTag.formState.errors.description === "object"
                }
                helperText={methodsTag.formState.errors.description?.message?.toString()}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="link"
                label={Vocabulary.link}
                {...methodsTag.register("link")}
                fullWidth
                error={typeof methodsTag.formState.errors.link === "object"}
                helperText={methodsTag.formState.errors.link?.message?.toString()}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <div>
      <ToastContainer hideProgressBar={true} />
      <InputLabel>{Vocabulary.article.toUpperCase()}</InputLabel>
      <Paper className={classes.paper}>
        <FormProvider {...methodsArticle}>
          <form
            onSubmit={methodsArticle.handleSubmit(
              edit ? handleUpdate : handleSubmit
            )}
          >
            <Grid container spacing={2}>
              <Grid className={classes.text} item xs={12} sm={12} md={4}>
                <Grid className={classes.text}>
                  <TextField
                    variant="outlined"
                    id="title"
                    label={Vocabulary.articleTitle}
                    {...methodsArticle.register("title")}
                    fullWidth
                    error={
                      typeof methodsArticle.formState.errors.title === "object"
                    }
                    helperText={methodsArticle.formState.errors.title?.message?.toString()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputLabel>{Vocabulary.selectCategory}</InputLabel>
                <Controller
                  name="FK_categoryId"
                  control={methodsArticle.control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <FormControl fullWidth required>
                      <Select {...field} multiple defaultValue={[]}>
                        {state.parentCategories.map((value: any, key: any) => (
                          <MenuItem
                            key={key}
                            value={value.id}
                            sx={{ paddingLeft: `${value.padding}em` }}
                          >
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputLabel>{Vocabulary.selectTags}</InputLabel>
                <Controller
                  name="FK_tagId"
                  control={methodsArticle.control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        className={classes.select}
                        {...field}
                        multiple
                        defaultValue={[]}
                      >
                        {state.tags.map((value: any, key: any) => (
                          <MenuItem key={key} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <InputLabel>{Vocabulary.selectAttributes}</InputLabel>
                <Controller
                  name="FK_attributeId"
                  control={methodsArticle.control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select {...field} multiple defaultValue={[]} fullWidth>
                        {state.attributes.map((value: any, key: any) => (
                          <MenuItem key={key} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Grid container className={classes.addButtonContainer}>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ marginTop: "1em" }}
                    onClick={() => setOpen(1)}
                    className={classes.Button}
                  >
                    {Vocabulary.addAttribute}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Grid container className={classes.addButtonContainer}>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ marginTop: "1em" }}
                    onClick={() => setOpen(2)}
                    className={classes.Button}
                  >
                    {Vocabulary.addTag}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <fieldset className={classes.fieldset}>
              <legend>{Vocabulary.contentDoctorStudent}</legend>
              <Controller
                name="contentDoctor"
                control={methodsArticle.control}
                render={({ field: { onChange, value } }) => {
                  return <WYSIWYGEditor value={value} onChange={onChange} />;
                }}
              />
            </fieldset>
            <fieldset className={classes.fieldset}>
              <legend>{Vocabulary.contentAssistentTehnician}</legend>
              <Controller
                name="contentAssistent"
                control={methodsArticle.control}
                render={({ field: { onChange, value } }) => {
                  return <WYSIWYGEditor value={value} onChange={onChange} />;
                }}
              />
            </fieldset>
            <fieldset className={classes.fieldset}>
              <legend>{Vocabulary.contentFarmer}</legend>
              <Controller
                name="contentFarmer"
                control={methodsArticle.control}
                render={({ field: { onChange, value } }) => {
                  return <WYSIWYGEditor value={value} onChange={onChange} />;
                }}
              />
            </fieldset>

            {preview ? null : (
              <Grid
                container
                justifyContent="flex-end"
                className={classes.buttonContainer}
              >
                <Button color="secondary" type="submit" variant="contained">
                  {Vocabulary.save}
                </Button>
              </Grid>
            )}
          </form>
        </FormProvider>
        <Modal
          open={open > 0}
          onClose={() => {
            setOpen(0);
          }}
          title={open === 1 ? Vocabulary.Attribute : Vocabulary.tag}
          actions={
            open > 0 ? (
              <Button
                form="form"
                type="submit"
                variant="contained"
                color="secondary"
              >
                {Vocabulary.save}
              </Button>
            ) : null
          }
          children={open === 1 ? renderAttributeModal() : renderTagModal()}
        />
        {loading ? (
          <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
            <CircularProgress color="primary" />
          </Backdrop>
        ) : null}
      </Paper>
    </div>
  );
}
const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      margin: 5,
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    fieldset: {
      borderRadius: 10,
      borderColor: "#00197540",
      marginBottom: 20,
      marginTop: 20,
      padding: 20,
    },
    buttonContainer: {
      marginTop: 15,
      paddingLeft: 15,
    },
    addButtonContainer: {
      marginTop: 15,
    },
    text: {
      marginTop: 20,
    },
    option: {
      marginLeft: 35,
    },
    list: {
      listStyleType: "none",
      padding: 0,
    },
    secondList: {
      paddingLeft: 20,
    },
    listItem: {
      marginLeft: 0,
      listStyleType: "none",
      padding: 0,
    },
  });
export default withStyles(styles, { withTheme: true })(Article);
