import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CachedDataSingleton from "../Utils/CachedDataSingleton";

type Props = {
  classes: any;
  open: boolean;
  onClose: () => void;
  children: any;
  title: any;
  actions?: any;
};

const cachedData = CachedDataSingleton.getInstance();

function Modal(props: Props) {
  const { open, children, onClose, classes, title, actions } = props;

  return (
    <Dialog open={open} maxWidth={"lg"} className={classes.dialog}>
      <DialogActions className={classes.dialogHeader}>
        <DialogTitle>{title}</DialogTitle>
        <Fab
          className={classes.fab}
          size="small"
          color="primary"
          onClick={() => {
            onClose();
            cachedData.setKey("preview", false);
          }}
        >
          <Close />
        </Fab>
      </DialogActions>
      <DialogContent id="previewDialogContent">{children}</DialogContent>
      <DialogActions className={classes.dialogActions}>{actions}</DialogActions>
    </Dialog>
  );
}

const styles = (theme: any) =>
  createStyles({
    dialog:{
      overflow:'scroll',
    },
    dialogActions: {
      backgroundColor: "#0e5e6f",
    },
    dialogHeader: {
      justifyContent: "space-between !important",
    },
    fab: {
      marginRight: "15px !important",
    },
  });
export default withStyles(styles, { withTheme: true })(Modal);
