import { useEffect, useState } from "react";

import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { resizeImage } from "../Utils/Utils";

const WYSIWYGEditor = (props: any) => {
  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty()
  );
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);

  /**
   *
   */
  useEffect(() => {
    if (!props.value || htmlTo(editorState) == props.value) return;
    setEditorState(
      EditorState.push(editorState, ContentState.createFromBlockArray(
        htmlToDraft(props.value || "").contentBlocks
      ), "insert-fragment")
    );
  }, [props.value]);

  /**
   *
   * @param data
   */
  const htmlTo = (data: any) => {
    return draftToHtml(convertToRaw(data.getCurrentContent()));
  };

  /**
   *
   * @param editorState
   * @returns
   */
  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    if (props.value !== htmlTo(editorState)) {
      props.onChange(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );
    }
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  /**
   *
   * @param file
   * @returns
   */
  const _uploadImageCallBack = async (file: any) => {
    const newUploadedImages = uploadedImages;
    const resizedFile = (await resizeImage(file)) as any;
    const base64 = await toBase64(resizedFile);
    const imageObject = {
      file: resizedFile,
      localSrc: URL.createObjectURL(resizedFile),
    };
    uploadedImages.push(imageObject);
    setUploadedImages(newUploadedImages);
    return new Promise((resolve, reject) => {
      resolve({
        data: {
          link: base64,
        },
      });
    });
  };

  return (
    <div className="editor">
      <Editor
        editorState={editorState}
        editorClassName="editor-class"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: {
            uploadCallback: _uploadImageCallBack,
            alt: { present: true, mandatory: true },
          },
          inputAccept:
            "application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel",
        }}
      />
    </div>
  );
};

export default WYSIWYGEditor;
