import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import {
  LocalUrlEnum,
  UrlEnum,
  get,
  nrOfItemsPerPage,
} from "../../Utils/Utils";
import { ToastContainer } from "react-toastify";
import {
  Chip,
  Grid,
  Backdrop,
  CircularProgress,
  Avatar,
  TextField,
  Autocomplete,
  IconButton,
  Tooltip,
  InputAdornment,
  Button,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArticlesGrid from "../../Components/ArticlesGrid";

type Props = {
  classes: any;
};

function Articles(props: Props) {
  const [url, setUrl] = useState("");
  const [nrPages, setNrPages] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedAttribute, setSelectedAttribute] = useState<string | null>(
    null
  );
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [selectedCategoriesName, setSelectedCategoriesName] = useState<any>([]);
  const [state, setState] = useState<any>({
    articles: [],
    categories: [],
    attributes: [],
    loading: false,
    parentCategories: [],
    tags: [],
  });
  const { classes } = props;
  const navigate = useNavigate();
  let renderCategories: any[] = [];

  /**
   *
   * @param param
   * @param value
   */
  const handleParams = (param: string, value: any) => {
    const updatedParams = new URLSearchParams(searchParams);
    if (updatedParams.has(param)) {
      if (param == "categorie") {
        updatedParams.delete(param);
        value?.forEach((category: any) => {
          updatedParams.append(param, category.id);
        });
      } else updatedParams.set(param, value);
    } else {
      updatedParams.append(param, param == "categorie" ? value[0].id : value);
    }

    setSearchParams(updatedParams);
  };

  /**
   *
   * @param nrOfElements
   */
  const calculateNumberOfPages = (nrOfElements: any) => {
    const nEl = nrOfElements / nrOfItemsPerPage;
    const nrOfPages = nrOfElements % nrOfItemsPerPage;
    if (nrOfPages === 0) {
      setNrPages(nEl);
    } else {
      setNrPages(Math.trunc(nEl) + 1);
    }
  };

  /**
   *
   */
  useEffect(() => {
    const storage = window.localStorage;
    const updatedParams = new URLSearchParams(searchParams);
    const storageUser = storage.getItem("user");
    const userParsed = JSON.parse(storageUser ? storageUser : "");
    const subscription = userParsed ? userParsed.account?.id : null;
    const url = `${UrlEnum.getUserArticles}/${
      subscription ? subscription : null
    }/${updatedParams.get("pagina") ? updatedParams.get("pagina") : 1}/${
      updatedParams.getAll("categorie").length == 0
        ? "null"
        : updatedParams.getAll("categorie")
    }/${updatedParams.get("atribut")}/${updatedParams.get(
      "tag"
    )}/${updatedParams.get("filtru")}`;
    setUrl(url);
  }, [searchParams]);

  /**
   *
   */
  useEffect(() => {
    if (url !== "") {
      setState({ ...state, loading: true });
      const promises = [
        get(url),
        get(UrlEnum.mainCategories),
        get(UrlEnum.mainAttributes),
        get(UrlEnum.userTags),
      ];
      Promise.all(promises).then((values: any) => {
        setState({
          ...state,
          articles: values[0].articles,
          categories: values[1].items,
          attributes: values[2].attributes,
          loading: false,
          parentCategories: getCategories(
            values[1].parentCategories,
            values[1].subcategories
          ),
          tags: values[3].tags,
        });
        calculateNumberOfPages(values[0].total_count);
      });
    }
  }, [url]);

  /**
   *
   * @param value
   */
  const handleCategoryChange = (value: any) => {
    window.scrollTo(0, 0);
    handleParams("categorie", value);
    setSelectedCategoriesName(value);
  };

  /**
   *
   * @param value
   */
  const handleAttributeChange = (value: any) => {
    window.scrollTo(0, 0);
    handleParams("atribut", value);
    selectedAttribute == value
      ? setSelectedAttribute(null)
      : setSelectedAttribute(value);
  };

  /**
   *
   * @param value
   */
  const handleTagChange = (value: any) => {
    window.scrollTo(0, 0);
    handleParams("tag", value);
    selectedTag == value ? setSelectedTag(null) : setSelectedTag(value);
  };

  /**
   *
   * @param param0
   * @param padding
   * @param categories
   */
  const getSubcategories = (
    { id, name }: any,
    padding: any,
    categories: any
  ) => {
    const subcategories = categories.filter(
      (category: any) => category.FK_parentId === id
    );

    renderCategories = [
      ...renderCategories,
      { id: id, name: name, padding: padding },
    ];
    if (subcategories.length > 0) {
      subcategories.map(({ id, name }: any, key: any) =>
        getSubcategories({ id, name }, padding + 1.2, categories)
      );
    }
  };

  /**
   *
   * @param parentCategories
   * @param categories
   * @returns
   */
  const getCategories = (parentCategories: any, categories: any) => {
    parentCategories.map(({ id, name }: any, key: any) => {
      getSubcategories({ id, name }, 0.7, categories);
    });
    return renderCategories;
  };

  /**
   *
   */
  const handleResetFilters = () => {
    setSelectedAttribute(null);
    setSelectedTag("");
    setSelectedCategoriesName([]);
    navigate("/");
  };

  /**
   *
   * @returns
   */
  const renderFilterBar = () => {
    return (
      <>
        <div className={classes.searchBar}>
          <TextField
            fullWidth
            type="search"
            variant="standard"
            onInput={(e) => {
              const filtru = (e.target as HTMLInputElement).value;
              handleParams("filtru", filtru);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Search />
                  </IconButton>{" "}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.filters}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} sm={6}>
              <Autocomplete
                freeSolo
                multiple
                disableClearable
                id="category"
                options={state.parentCategories}
                value={selectedCategoriesName}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <div style={{ paddingLeft: `${option.padding}em` }}>
                      {option.name}
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    label={Vocabulary.selectCategory}
                    margin="normal"
                    {...params}
                    variant="outlined"
                  />
                )}
                getOptionLabel={(option: any) =>
                  option.name ? option.name : option
                }
                onChange={(event: any, value: any) => {
                  handleCategoryChange(value);
                }}
              />
              <Grid item xs={12} md={4} sm={6} style={{ marginTop: "20px" }}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleResetFilters}
                >
                  {Vocabulary.resetFilter}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} sm={6} style={{ marginTop: 20 }}>
              <Grid container spacing={2}>
                {state.attributes.map((attribute: any) => {
                  return (
                    <Grid item xs={3} key={attribute.id}>
                      <Chip
                        color="primary"
                        label={attribute.name}
                        variant="outlined"
                        className={
                          selectedAttribute === attribute.id ||
                          new URLSearchParams(searchParams).get("atribut") ==
                            attribute.id
                            ? classes.chipSelected
                            : classes.chip
                        }
                        clickable={true}
                        onClick={() => handleAttributeChange(attribute.id)}
                      />
                    </Grid>
                  );
                })}
                {state.tags.map((tag: any) => {
                  return (
                    <Grid item xs={3} key={tag.id}>
                      <Chip
                        color="primary"
                        label={tag.name}
                        variant="outlined"
                        className={
                          selectedTag === tag.id ||
                          new URLSearchParams(searchParams).get("tag") == tag.id
                            ? classes.chipSelected
                            : classes.chip
                        }
                        clickable={true}
                        onClick={() => handleTagChange(tag.id)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} sm={6} style={{ marginTop: 20 }}>
              <Grid container spacing={2}>
                {state.categories.map((category: any) => {
                  return (
                    <Grid item xs={2} key={category.id}>
                      <Tooltip title={category.name}>
                        <IconButton
                          onClick={() => {
                            navigate(
                              LocalUrlEnum.userCategories + category.link,
                              {
                                state: { categoryId: category.id },
                              }
                            );
                            window.scrollTo(0, 0);
                          }}
                        >
                          <Avatar
                            sx={{ width: 40, height: 40 }}
                            src={`${UrlEnum.getPublicImage}/${category.imagePath}`}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <div className={classes.image} />
      <div className={classes.parentContainer}>
        {renderFilterBar()}
        <ArticlesGrid articles={state.articles} nrPages={nrPages} />
        {state.loading ? (
          <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
            <CircularProgress color="primary" />
          </Backdrop>
        ) : null}
      </div>
    </>
  );
}

const styles = (theme: any) =>
  createStyles({
    filters: {
      backgroundColor: "#f1f1f1",
      width: "70%",
      display: "flex",
      padding: 10,
      flexDirection: "column",
      alignItems: "center",
      borderRadius: 5,
      height: "100%",
      justifyContent: "center",
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "95%",
        marginRight: "auto",
      },
    },
    parentContainer: {
      marginTop: 50,
      padding: "10px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },

    image: {
      width: "100%",
      margin: 0,
      height: "200px",
      backgroundRepeat: "no-repeat",
      background:
        "linear-gradient(221deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6026785714285714) 61%),url('http://vetmed-ghid.webmates.ro/assets/css/../img/jumbotron.jpg')",
      backgroundSize: "100%",
      backgroundPosition: "center center",
      [theme.breakpoints.down("md")]: {
        objectFit: "contain",
        background:
          "linear-gradient(221deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6026785714285714) 61%),url('http://vetmed-ghid.webmates.ro/assets/css/../img/jumbotron.jpg')",
      },
    },
    select: {
      margin: 10,
    },

    chip: {
      margin: "5px !important",
    },
    chipSelected: {
      margin: "5px !important",
      backgroundColor: "#0e5e6f !important",
      color: "#fff !important",
    },
    searchBar: {
      justifyContent: "center",
      marginBottom: 20,
      width: "30vw",
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "95%",
        marginRight: "auto",
      },
    },
  });
export default withStyles(styles, { withTheme: true })(Articles);
