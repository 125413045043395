import { Divider, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import { Vocabulary } from "../../Utils/Vocabulary";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckUserRole, UrlEnum, get, phoneRegExp } from "../../Utils/Utils";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createStyles } from "@mui/material/styles";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserData from "../../Components/UserData";
import Grid from "@mui/material/Grid";
import CompanyDetails from "../../Components/CompanyDetails";
import Box from "@mui/material/Box";
import moment from "moment";

type Props = {
  classes: any;
};

function Settings(props: Props) {
  const { classes } = props;
  const location = useLocation();
  const [idCompany, setIdCompany] = useState(null);
  const [subscription, setSubscription] = useState({
    type: "",
    expireAt: "",
    subscriptionType: "",
  });
  const requestValidationSchema = yup
    .object()
    .shape({
      fullName: yup.string().required(Vocabulary.requiredField),
      userName: yup.string().required(Vocabulary.requiredField),
      email: yup
        .string()
        .email(Vocabulary.emailValidation)
        .required(Vocabulary.requiredField),
      phone: yup
        .string()
        .matches(phoneRegExp, Vocabulary.phoneValidation)
        .required(Vocabulary.requiredField),
    })
    .required();
  const methods = useForm({
    resolver: yupResolver(requestValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

  const configureSubscription = async (user: any) => {
    const payment = await get(
      `${UrlEnum.getSubscription}/${user.FK_subscriptionId}`
    ).then((response: any) => {
      if (response.errors) {
        console.log(response.errors);
      } else {
        return response.subscription;
      }
    });
    let expDay;
    let subscriptionType;
    let date;
    if (!user.lastPaymentDate) {
      //free trial
      date = new Date(parseInt(user.statusModified) * 1000);
      expDay = moment(date);
      expDay.add(7, "days");
      subscriptionType = "Expirare perioadă gratuită";
    } else {
      //subscription
      date = new Date(user.lastPaymentDate);
      expDay = moment(date);
      expDay.add(payment.valability, "months");
      subscriptionType = "Expirare abonament";
    }
    setSubscription({
      type: payment.type,
      expireAt: `${expDay?.format("YYYY/MM/DD")}`,
      subscriptionType: subscriptionType,
    });
  };
  /**
   *
   */
  useEffect(() => {
    const storage = window.localStorage;
    const user = JSON.parse(storage.getItem("user") || "");
    if (user) {
      methods.reset(user);
      setIdCompany(user?.FK_companyId);
      configureSubscription(user);
    }
  }, [location]);

  return (
    <div className={classes.parentContainer}>
      <input type="hidden" {...methods.register("imagePath")}></input>
      <ToastContainer hideProgressBar={true} />
      <div className={classes.secondContainer}>
        <Box sx={{ flexGrow: 1 }} className={classes.container}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 6, md: 12 }}
          >
            <Grid item xs={6}>
              <UserData />
            </Grid>
            <Grid item xs={6}>
              <FormProvider {...methods}>
                <form
                  className={classes.form}
                  onSubmit={methods.handleSubmit(() => {
                    console.log(methods.getValues());
                  })}
                >
                  {CheckUserRole.isUser() ? (
                    <Paper elevation={12} className={classes.paper}>
                      <Typography variant="h6" align="left">
                        {Vocabulary.subscriptionDetails}
                      </Typography>
                      <Divider className={classes.divider} />
                      <Typography>
                        {Vocabulary.subscriptionType}:{" "}
                        <span>{subscription.type}</span>
                      </Typography>
                      <Typography>
                        {subscription.subscriptionType}: {subscription.expireAt}
                      </Typography>
                    </Paper>
                  ) : null}
                </form>
              </FormProvider>
            </Grid>
            <Grid item xs={6}>
              {CheckUserRole.isUser() && idCompany ? (
                <CompanyDetails idCompany={idCompany} />
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      margin: 5,
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    parentContainer: {
      marginTop: CheckUserRole.isUser() ? 50 : 0,
      width: "100%",
      minHeight: "60vh",
      display: "flex",
      justifyContent: CheckUserRole.isUser() ? "center" : null,
      [theme.breakpoints.down("lg")]: { flexDirection: "column" },
    },
    secondContainer: {
      width: CheckUserRole.isUser() ? "1300px" : "100%",
      [theme.breakpoints.down("lg")]: { flexDirection: "column" },
    },

    form: {
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    divider: {
      marginTop: "10px !important",
      marginBottom: "10px !important",
    },
  });

export default withStyles(styles, { withTheme: true })(Settings);
