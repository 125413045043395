import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Box,
} from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { LocalUrlEnum, UrlEnum, get, httpDelete } from "../../Utils/Utils";
import clsx from "clsx";
import Modal from "../../Components/Modal";
import CompanyRequest from "../../Components/user/CompanyRequest";
import UserRequest from "../../Components/user/UserRequest";
import { useNavigate } from "react-router-dom";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormProvider, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Config from "../../Utils/Config";
import Filter from "../../Components/Filter";

type Props = {
  classes: any;
};

function RequestsTable(props: Props) {
  const cachedData = CachedDataSingleton.getInstance();
  const { classes } = props;
  const statuses = cachedData.get("statuses");
  const navigate = useNavigate();
  const [state, setState] = useState<any>({
    requests: [],
    page: 1,
    perPage: 20,
    order: "desc",
    preview: false,
    loading: false,
    count: 1,
    startDate: null,
    endDate: null,
    role: null,
    status: null,
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

  const requestHeaders = [
    { label: "Nume utilizator", name: "userName", options: { sort: false } },
    { label: "Nume", name: "fullName", options: { sort: false } },
    { label: "Email", name: "email", options: { sort: false } },
    { label: "Companie", name: "company", options: { sort: false } },
    { label: "Role", name: "accountType", options: { sort: false } },
    { label: "Status", name: "accountStatus", options: { sort: false } },
    {
      label: "Data înregistrare ",
      name: "registerDate",
      options: { sort: true },
    },
    {
      label: "TRUE",
      name: "status",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      label: "Opțiuni",
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,

        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.view}>
                  <ToggleButton
                    onClick={() => {
                      handlePreview(state.requests[rowIndex].id);
                    }}
                    value="left"
                    aria-label="left aligned"
                  >
                    <Visibility />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      navigate(
                        `/${LocalUrlEnum.request}/${state.requests[rowIndex].id}`,
                        { state: { id: state.requests[rowIndex].id } }
                      );
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={() => {
                      handleDelete(state.requests[rowIndex].id);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,

      rowsPerPageOptions: [20, 50, 100],
      confirmFilters: true,
      filter: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page - 1,
      search: true,
      serverSide: true,

      onSearchChange: (searchText: string | null) => {
        if (searchText && searchText?.length > 3) getRequests(searchText);
        if (!searchText) getRequests(null);
      },
      onColumnSortChange: (changedColumn: string, direction: string) => {
        setState({ ...state, order: direction });
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page + 1 });
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        setState({ ...state, perPage: numberOfRows });
      },
      setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
        return {
          className: clsx({
            [classes.rejected]: row[5] === Vocabulary.rejected,
            [classes.processing]: row[5] === Vocabulary.processing,
            [classes.requestAdditions]: row[5] === Vocabulary.requestAdditions,
            [classes.approved]: row[5] === Vocabulary.approved,
            [classes.freeTrial]: row[5] === Vocabulary.freeTrial,
            [classes.unpaidSubscription]: row[5] === Vocabulary.unpaid,
          }),
        };
      },
    };
  };

  /**
   *
   */
  useEffect(() => {
    getRequests();
  }, [
    state.perPage,
    state.page,
    state.status,
    state.startDate,
    state.endDate,
    state.order,
  ]);

  /**
   *
   */
  const getRequests = (filter?: string | null) => {
    if (!filter) filter = null;
    setState({ ...state, loading: true });
    get(
      `${UrlEnum.getAllRequests}/${state.page}/${state.perPage}/${state.order}/${state.startDate}/${state.endDate}/${state.status}/${filter}`
    ).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
        setState({ ...state, loading: false });
      }
      setState({
        ...state,
        requests: response[0].items,
        count: response[0].total_count,
        loading: false,
      });
    });
  };

  /**
   *
   * @param id
   */
  const handlePreview = (id: any) => {
    get(`${UrlEnum.getUser}/${id}`).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        cachedData.setKey("preview", true);
        setState({
          ...state,
          preview: true,
        });
        methods.reset(response.request);
      }
    });
  };

  /**
   *
   * @param id
   */
  const handleDelete = (id: any) => {
    httpDelete(`${UrlEnum.getUser}/${id}`).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.deleteSucces);
        getRequests();
      }
    });
  };

  /**
   *
   * @param e
   */
  const handleStatusChange = (e: any) => {
    const indexStatus = statuses.findIndex(
      (s: any) => s.status === e.target.name
    );
    console.log(indexStatus);
    setState({ ...state, status: indexStatus + 1 });
  };

  /**
   *
   */
  const handleDeleteFilters = () => {
    setState({ ...state, startDate: null, endDate: null, status: null });
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <Filter
        children={
          <>
            <ButtonGroup variant="contained" className={classes.groupButton}>
              <Button
                name={Vocabulary.freeTrial}
                onClick={handleStatusChange}
                className={classes.freeTrial}
              >
                {Vocabulary.freeTrial}
              </Button>
              <Button
                name={Vocabulary.approved}
                onClick={handleStatusChange}
                className={classes.approved}
              >
                {Vocabulary.approved}
              </Button>
              <Button
                name={Vocabulary.processing}
                onClick={handleStatusChange}
                className={classes.processing}
              >
                {Vocabulary.processing}
              </Button>
              <Button
                name={Vocabulary.requestAdditions}
                onClick={handleStatusChange}
                className={classes.requestAdditions}
              >
                {Vocabulary.requestAdditions}
              </Button>
              <Button
                name={Vocabulary.rejected}
                onClick={handleStatusChange}
                className={classes.rejected}
              >
                {Vocabulary.rejected}
              </Button>
              <Button
                name={Vocabulary.unpaid}
                onClick={handleStatusChange}
                className={classes.unpaidSubscription}
              >
                {Vocabulary.unpaid}
              </Button>
            </ButtonGroup>
            <div className={classes.datePickerContainer}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  className={classes.datePicker}
                  inputFormat={Config.momentEUDateFormat}
                  label={Vocabulary.startDate}
                  value={state.startDate}
                  onChange={(value) => {
                    setState({
                      ...state,
                      startDate: value.format(Config.datePickerFormat),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  className={classes.datePicker}
                  inputFormat={Config.momentEUDateFormat}
                  label={Vocabulary.endDate}
                  value={state.endDate}
                  onChange={(value) => {
                    setState({
                      ...state,
                      endDate: value.format(Config.datePickerFormat),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </>
        }
        handleDeleteFilters={handleDeleteFilters}
      />
      <Box className={classes.tableBox}>
        <MUIDataTable
          title={Vocabulary.requestsList}
          data={state.requests}
          columns={requestHeaders}
          options={getTableOptions()}
        />
      </Box>
      {state.preview ? (
        <Modal
          title={Vocabulary.request}
          onClose={() => setState({ ...state, preview: false })}
          open={state.preview}
          children={
            <>
              <FormProvider {...methods}>
                {methods.getValues("FK_companyId") ? (
                  <CompanyRequest />
                ) : (
                  <UserRequest />
                )}
              </FormProvider>
            </>
          }
        />
      ) : null}
      {state.loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
    </>
  );
}

const styles = (theme: any) =>
  createStyles({
    freeTrial: {
      "& td": { backgroundColor: "#aec6cf" },
      backgroundColor: "#aec6cf !important",
    },
    processing: {
      "& td": { backgroundColor: "#e2dca4" },
      backgroundColor: "#e2dca4 !important",
    },
    requestAdditions: {
      "& td": { backgroundColor: "#e6c1c1" },
      backgroundColor: "#e6c1c1 !important",
    },
    approved: {
      "& td": { backgroundColor: "#b0ceb1" },
      backgroundColor: "#b0ceb1 !important",
    },
    rejected: {
      "& td": { backgroundColor: "#e16b6b" },
      backgroundColor: "#e16b6b !important",
    },
    unpaidSubscription: {
      "& td": { backgroundColor: "#e16b6s" },
      backgroundColor: "#db3d3d !important",
    },
    button: {
      backgroundColor: "#FFF !important",
    },
    groupButton: {
      alignSelf: "flex-start",
      margin: 5,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        width: "97%",
      },
    },
    datePickerContainer: {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    datePicker: {
      margin: "5px !important",
      [theme.breakpoints.down("sm")]: {
        width: "97%",
      },
    },
    tableBox: {
      [theme.breakpoints.down("sm")]: {
        width: "88vw",
        padding: 0,
        zIndex: 1,
      },
    },
  });

export default withStyles(styles, { withTheme: true })(RequestsTable);
