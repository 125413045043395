import {
  Paper,
  InputLabel,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Vocabulary } from "../../Utils/Vocabulary";
import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UrlEnum, post, LocalUrlEnum } from "../../Utils/Utils";
import { Visibility } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate  } from "react-router-dom";

type Props = {
  classes: any;
};

function Admin(props: Props) {
  const { classes } = props;

  const requestValidationSchema = yup.object().shape({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const methods = useForm({
    resolver: yupResolver(requestValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

/**
 * 
 */
  const handleSubmit = () => {
    post(UrlEnum.getAdmins, methods.getValues()).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.addDataSucces);
        navigate(`/${LocalUrlEnum.admin}/${response[0].admin.id}`);
      }
    });
  };

  /**
 * 
 */
  const handleUpdate = () => {
    post(UrlEnum.getAdmin, methods.getValues()).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.updateSuccess);
      }
    });
  };
/**
 * 
 * @param id 
 */
  const handleGetAdmin = (id: any) => {
    post(`${UrlEnum.getAdmin}/${id}`).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        methods.reset(response.admin);
      }
    });
  };

  /**
   * 
   */
  useEffect(() => {
    if (location.state) handleGetAdmin(location.state.id);
  }, [location]);

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <InputLabel>{Vocabulary.addNewAdmin.toUpperCase()}</InputLabel>
      <Paper className={classes.paper}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(location.state ? handleUpdate : handleSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  id="fullName"
                  label={Vocabulary.fullName}
                  {...methods.register("fullName")}
                  name="fullName"
                  error={typeof methods.formState.errors.fullName === "object"}
                  helperText={methods.formState.errors.fullName?.message?.toString()}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  id="userName"
                  label={Vocabulary.userName}
                  {...methods.register("userName")}
                  name="userName"
                  error={typeof methods.formState.errors.userName === "object"}
                  helperText={methods.formState.errors.userName?.message?.toString()}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  id="email"
                  label={Vocabulary.email}
                  {...methods.register("email")}
                  name="email"
                  error={typeof methods.formState.errors.email === "object"}
                  helperText={methods.formState.errors.email?.message?.toString()}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  id="password"
                  label={Vocabulary.password}
                  {...methods.register("password")}
                  name="password"
                  error={typeof methods.formState.errors.password === "object"}
                  helperText={methods.formState.errors.password?.message?.toString()}
                  fullWidth
                  type={!showPassword ? "password" : "text"}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              className={classes.buttonContainer}
              sx={{ marginTop: 5 }}
            >
              <Button
                color="secondary"
                type="submit"
                variant="contained"
                className={classes.saveButton}
              >
                {Vocabulary.save}
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </Paper>
    </>
  );
}
const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      margin: 5,
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
      saveButton: {
        display: "flex",
        padding: 10,
        justifyContent: "flex-end",
      },
      buttonContainer: {
        margin: 5,
      },
    },
  });
export default withStyles(styles, { withTheme: true })(Admin);
