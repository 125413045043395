import {
  Paper,
  InputLabel,
  Button,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useState, useEffect, Fragment } from "react";
import { WizardModel } from "../../Models/Models";
import {
  get,
  UrlEnum,
  post,
  LocalUrlEnum,
  phoneRegExp,
  generateFormData,
  RegisterWizardArray,
  CompanyUserPropertiesArray,
} from "../../Utils/Utils";
import Person from "../../Components/user/Person";
import Company from "../../Components/user/Company";
import Address from "../../Components/user/Address";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  classes: any;
};

function User(props: Props) {
  const { classes } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    model: new WizardModel(),
    accounts: [],
    companyCategories: [],
    subscriptions: [],
  });

  const requestValidationSchema = yup.object().shape({
    fullName: yup.string().when("isUserAccount", (isUser, schema) => {
      return isUser == "true"
        ? schema.required(Vocabulary.requiredField)
        : schema;
    }),
    userName: yup.string().when("isUserAccount", (isUser, schema) => {
      return isUser == "true"
        ? schema.required(Vocabulary.requiredField)
        : schema;
    }),
    email: yup.string().when("isUserAccount", (isUser, schema) => {
      return isUser == "true"
        ? schema
            .required(Vocabulary.requiredField)
            .email(Vocabulary.emailValidation)
        : schema;
    }),
    phone: yup.string().when("isUserAccount", (isUser, schema) => {
      return isUser == "true"
        ? schema
            .matches(phoneRegExp, Vocabulary.phoneValidation)
            .required(Vocabulary.requiredField)
        : schema;
    }),
    company: yup.object().when("isUserAccount", {
      is: "false",
      then: yup.object().shape({
        name: yup.string().required(Vocabulary.requiredField),
        CUI: yup.string().required(Vocabulary.requiredField),
        registerNo: yup.string().required(Vocabulary.requiredField),
        representativeName: yup.string().required(Vocabulary.requiredField),
        email: yup
          .string()
          .required(Vocabulary.requiredField)
          .email(Vocabulary.emailValidation),
      }),
    }),
    address: yup.object().when("isUserAccount", {
      is: "true",
      then: yup.object().shape({
        address: yup.string().required(Vocabulary.requiredField),
        county: yup.string().required(Vocabulary.requiredField),
        country: yup.string().required(Vocabulary.requiredField),
        city: yup.string().required(Vocabulary.requiredField),
      }),
    }),
  });
  /**
   *
   */
  const methods = useForm({
    defaultValues: {
      image: "",
      imagePath: "",
      isUserAccount: "true",
      FK_userAccountId: 1,
      ValidationCode: 0,
      FK_subscriptionId: 1,
      email: "",
      address: {
        address: "",
        city: "",
        country: "",
        county: "",
      },
      company: {
        name: "",
        CUI: "",
        FK_categoryId: "",
        registerNo: "",
        representativeName: "",
        address: {
          address: "",
          city: "",
          country: "",
          county: "",
        },
      },
      fullName: "",
      phone: "",
      userName: "",
    },
    resolver: yupResolver(requestValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

  const { control, getValues } = useForm({
    mode: "onChange",
  });

  const {} = useFieldArray({
    control,
    name: "users",
  });
  const watchIfIsNormalPerson = methods.watch("isUserAccount");
  const watchAccountType = methods.watch("FK_userAccountId");
  /**
   *
   */
  useEffect(() => {
    const promises = [
      get(UrlEnum.getAccountTypes),
      get(UrlEnum.getCompanyCategories),
      get(UrlEnum.getSubscriptionTypes),
    ];
    Promise.all(promises)
      .then((values: any) => {
        setState({
          ...state,
          accounts: values[0].accounts,
          companyCategories: values[1].categories,
          subscriptions: values[2].subscriptions,
        });
      })
      .catch((ex) => console.log(ex));
  }, []);

  /**
   *
   */
  const handleSubmit = () => {
    setLoading(true);
    let formData = new FormData();
    if (methods.getValues("isUserAccount") == "true") {
      formData = generateFormData(methods.getValues(), RegisterWizardArray);
      formData.append("address", methods.getValues("address").address);
      formData.append("city", methods.getValues("address").city);
      formData.append("country", methods.getValues("address").country);
      formData.append("county", methods.getValues("address").county);
    } else {
      formData = generateFormData(
        methods.getValues("company"),
        CompanyUserPropertiesArray
      );
      const company = methods.getValues("company");
      const subscription = `${methods.getValues("FK_subscriptionId")}`;
      const account = `${methods.getValues("FK_userAccountId")}`;
      formData.append("address", company.address.address);
      formData.append("city", company.address.city);
      formData.append("country", company.address.country);
      formData.append("county", company.address.county);
      formData.append("FK_categoryId", account);
      formData.append("FK_subscriptionId", subscription);
    }
    for (let i = 0; i < getValues().users.length; i++) {
      formData.append("userNames[]", getValues().users[i].username);
      formData.append("emails[]", getValues().users[i].email);
    }

    post(UrlEnum.addUser, formData).then((response: any) => {
      setLoading(false);
      if (response.errors) {
        toast.error(response.errors);
      } else {
        navigate(`/${LocalUrlEnum.users}`);
        toast.success(response);
      }
    });
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <input type="hidden" {...methods.register("imagePath")}></input>
      <InputLabel>{Vocabulary.addNewUser.toUpperCase()}</InputLabel>
      <Paper className={classes.paper}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <fieldset className={classes.fieldset}>
              <legend>{Vocabulary.requestData}</legend>
              <InputLabel>{Vocabulary.selectAccountType}</InputLabel>
              <Controller
                defaultValue={"true"}
                name="isUserAccount"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <RadioGroup {...field} row>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={Vocabulary.naturalPerson}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={Vocabulary.legalPerson}
                      />
                    </RadioGroup>
                  );
                }}
              />
              <InputLabel>{Vocabulary.selectRole}</InputLabel>
              <Select
                native
                required
                fullWidth
                {...methods.register("FK_userAccountId")}
                id={"FK_userAccountId"}
                name={"FK_userAccountId"}
              >
                {watchIfIsNormalPerson === "true"
                  ? state.accounts.map((value: any, key: any) => {
                      return (
                        <option value={value.id} key={key}>
                          {value.account}
                        </option>
                      );
                    })
                  : state.companyCategories.map((value: any, key: any) => {
                      return (
                        <option value={value.id} key={key}>
                          {value.category}
                        </option>
                      );
                    })}
              </Select>
            </fieldset>
            <fieldset className={classes.fieldset}>
              <legend>
                {watchIfIsNormalPerson === "true"
                  ? Vocabulary.userData
                  : Vocabulary.companyData}
              </legend>
              <Grid container spacing={2}>
                {watchIfIsNormalPerson === "true" ? (
                  <>
                    <Person />
                    <Address modelRoot="address." />
                  </>
                ) : (
                  <>
                    <Company addUserInterface={true} />
                    <Address modelRoot="company.address." />

                    <Grid
                      className={classes.usersContainer}
                      container
                      spacing={2}
                    >
                      {[...Array(3)].map((_v, i) => {
                        return (
                          <Fragment key={i}>
                            <Grid item xs={6}>
                              <Controller
                                name={`users[${i}].username`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => {
                                  return (
                                    <TextField
                                      {...field}
                                      id={`users${i}]`}
                                      label={`${Vocabulary.userName} ${i + 1} `}
                                      fullWidth
                                      required
                                    />
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Controller
                                name={`users[${i}].email`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => {
                                  return (
                                    <TextField
                                      {...field}
                                      id={`emails[${i}]`}
                                      label={`${Vocabulary.email} ${i + 1} `}
                                      fullWidth
                                      required
                                    />
                                  );
                                }}
                              />
                            </Grid>
                          </Fragment>
                        );
                      })}
                    </Grid>
                  </>
                )}

                {watchIfIsNormalPerson === "true" &&
                  (watchAccountType == 1 || watchAccountType == 4 ? (
                    <Grid item xs={12} sm={12} md={3}>
                      <TextField
                        variant="standard"
                        type="number"
                        id="ValidationCode"
                        label={
                          watchAccountType == 1
                            ? Vocabulary.validationNo
                            : Vocabulary.petOwnerCode
                        }
                        {...methods.register("ValidationCode")}
                        name="ValidationCode"
                        fullWidth
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={3}>
                      <Typography>
                        {watchAccountType == 2
                          ? Vocabulary.studentCard
                          : Vocabulary.qualificationDiploma}
                      </Typography>

                      <input
                        required
                        {...methods.register("image")}
                        style={{ marginBottom: 10, width: "100%" }}
                        type="file"
                        accept=".jpg, .png, .pdf"
                        id="image"
                        name="image"
                      />
                    </Grid>
                  ))}
                <Grid item xs={12} sm={12} md={3}>
                  <InputLabel>{Vocabulary.selectSubscriptions}</InputLabel>
                  <Select
                    native
                    required
                    fullWidth
                    {...methods.register("FK_subscriptionId")}
                    id={"FK_subscriptionId"}
                    name={"FK_subscriptionId"}
                  >
                    {state.subscriptions.map((value: any, key: any) => {
                      return (
                        <option value={value.id} key={key}>
                          {`${value.type} ${
                            watchAccountType == 2 ? 70 : value.price
                          } lei/${value.valability} ${
                            key === 0 ? Vocabulary.month : Vocabulary.months
                          }`}
                        </option>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            </fieldset>
            <Grid
              container
              justifyContent="flex-end"
              className={classes.buttonContainer}
            >
              <Button color="secondary" type="submit" variant="contained">
                {Vocabulary.save}
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </Paper>
      {loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
    </>
  );
}
const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      margin: 5,
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    fieldset: {
      borderRadius: 10,
      borderColor: "#00197540",
      marginBottom: 20,
      padding: 20,
    },
    container: {
      width: "49%",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    usersContainer: {
      padding: 10,
      marginTop: "10px !important",
    },

    typography: {
      marginBottom: 15,
    },

    select: {
      marginTop: "2",
    },
  });

export default withStyles(styles, { withTheme: true })(User);
