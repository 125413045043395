import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Vocabulary } from "../Utils/Vocabulary";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { LocalUrlEnum, post,  UrlEnum } from "../Utils/Utils";
import { LoginModel } from "../Models/Models";
import { Visibility } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import CachedDataSingleton from "../Utils/CachedDataSingleton";

type Props = {
  classes: any;
};

function Login(props: Props) {
  const { classes } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const captchaRef = useRef<any>(null);
  const [user, setUser] = useState<LoginModel>(new LoginModel());
  const [showPassword, setShowPassword] = useState(false);
  const cachedData = CachedDataSingleton.getInstance();

  /**
   *
   * @param event
   */
  const onChangeTextField = (event: any) => {
    const newUser: any = Object.assign({}, user);
    newUser[event.target.name] = event.target.value;
    setUser(newUser);
  };

  /**
   *
   */
  const handleSubmit = () => {
    if (captchaRef.current) {
      setLoading(true);
      const token = captchaRef.current.getValue();
      const newUser: any = Object.assign({}, user);
      newUser["reCaptcha"] = token;
      post(UrlEnum.login, newUser, { credentials: "include" }).then(
        (response) => {
          setLoading(false);
          if (response.errors) {
            toast.error(response.errors);
            captchaRef.current.reset();
          }
          if (response.access_token && response.user) {
            const expires = new Date(Date.now() + 180 * 10000000).toUTCString();

            localStorage.setItem("user", JSON.stringify(response.user));
            if (
              response.user.FK_accountStatusId == 6 &&
              response.user.FK_roleId != 1
            )
              navigate(`/${LocalUrlEnum.expiredPayment}`, {
                state: { user: response.user },
              });
            else {
              document.cookie = `access_token=${response.access_token};expires=${expires};path=/`;
              document.cookie = `userName=${response.user.userName};expires=${expires};path=/`;
              document.cookie = `fullName=${response.user.fullName};expires=${expires};path=/`;
              cachedData.setKey("user", response.user);

              if (response.firstLogg == true) {
                //navigate to settings first time the user logs
                navigate(`/${LocalUrlEnum.settings}`);
              } else {
                window.location.href = "/";
              }
            }
          }
        }
      );
    }
  };

  return (
    <div className={classes.root}>
      <ToastContainer hideProgressBar={true} />
      <div className={classes.image}>
        <img src={"/vetmed.svg"} alt="VETMED GHID" width="70%" />
      </div>
      <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
        <TextValidator
          variant="filled"
          required={true}
          id="email"
          name="email"
          label={Vocabulary.email}
          validators={["required"]}
          value={user.email}
          onChange={(e) => onChangeTextField(e)}
          className={classes.textField}
          inputProps={{ style: { color: "white" } }}
          InputLabelProps={{ style: { color: "white" } }}
        />
        <TextValidator
          variant="filled"
          required={true}
          id="password"
          name="password"
          type={!showPassword ? "password" : "text"}
          label={Vocabulary.password}
          validators={["required"]}
          value={user.password}
          onChange={(e) => onChangeTextField(e)}
          className={classes.textField}
          inputProps={{ style: { color: "white" } }}
          InputLabelProps={{ style: { color: "white" } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ color: "white" }}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <Visibility />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.ReCAPTCHA}>
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={
              process.env.REACT_APP_SITE_KEY
                ? process.env.REACT_APP_SITE_KEY
                : ""
            }
          />
        </div>
        <Button variant="contained" type="submit" className={classes.button}>
          {Vocabulary.login}
        </Button>
      </ValidatorForm>
      <div className={classes.bottomText}>
        <Link to={`/${LocalUrlEnum.register}`} className={classes.text}>
          <Typography>{Vocabulary.requireRegister}</Typography>
        </Link>
        <Link to={`/${LocalUrlEnum.resetPassword}`} className={classes.text}>
          <Typography>{Vocabulary.resetPassword}</Typography>
        </Link>
      </div>
      {loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#000",
      background:
        "radial-gradient(circle, rgba(74,74,74,1) 0%, rgba(0,0,0,1) 93%)",
    },
    textField: {
      margin: "5px !important",
      height: "50px",
      width: "300px",
      color: "#FFF !important",
      [theme.breakpoints.down("lg")]: {},
    },
    button: {
      height: "50px",
      width: "300px",
      marginTop: "30px !important",
    },
    form: {
      width: "60%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    image: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 50,
      width: "350px",
      [theme.breakpoints.down("sm")]: {
        width: "200px",
      },
    },
    text: {
      color: "#FFF",
      textDecoration: "inherit",
    },
    ReCAPTCHA: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      marginTop: 30,
    },
    bottomText: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "300px",
      marginTop: 10,
    },
  });

export default withStyles(styles, { withTheme: true })(Login);
