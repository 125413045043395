import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDial from "@mui/material/SpeedDial";
import { useNavigate } from "react-router-dom";
import { LocalUrlEnum } from "../Utils/Utils";
import {
  Article,
  Class,
  Home,
  Person,
  PersonAddAlt,
  Style,
  Summarize,
  ChevronLeft,
} from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import MenuIcon from "@mui/icons-material/Menu";
import { Vocabulary } from "../Utils/Vocabulary";
import { useState } from "react";

type Props = {
  classes: any;
};

function SpeedDialLayout(props: Props) {
  const { classes } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  /**
   *
   * @param path
   */
  const handleNavigationChange = (path: string) => {
    navigate(path);
  };

  const actions = [
    {
      icon: (
        <Home
          color="secondary"
          fontSize="large"
          onClick={(event) => handleNavigationChange("/")}
        />
      ),
      name: `${Vocabulary.dashboard}`,
    },
    {
      icon: (
        <PersonAddAlt
          color="secondary"
          fontSize="large"
          onClick={(event) => handleNavigationChange(LocalUrlEnum.requests)}
        />
      ),
      name: `${Vocabulary.demands}`,
    },
    {
      icon: (
        <Person
          color="secondary"
          fontSize="large"
          onClick={(event) => handleNavigationChange(LocalUrlEnum.users)}
        />
      ),
      name: `${Vocabulary.users}`,
    },
    {
      icon: (
        <Class
          color="secondary"
          fontSize="large"
          onClick={(event) => handleNavigationChange(LocalUrlEnum.categories)}
        />
      ),
      name: `${Vocabulary.categories}`,
    },
    {
      icon: (
        <Article
          color="secondary"
          fontSize="large"
          onClick={(event) => handleNavigationChange(LocalUrlEnum.articles)}
        />
      ),
      name: `${Vocabulary.articles}`,
    },
    {
      icon: (
        <Summarize
          color="secondary"
          fontSize="large"
          onClick={(event) => handleNavigationChange(LocalUrlEnum.attributes)}
        />
      ),
      name: `${Vocabulary.attributes}`,
    },
    {
      icon: (
        <Style
          color="secondary"
          fontSize="large"
          onClick={(event) => handleNavigationChange(LocalUrlEnum.tags)}
        />
      ),
      name: `${Vocabulary.tags}`,
    },
  ];

  return (
    <>
      <div className={classes.main}>
        <Box>
          <Backdrop open={open} />
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            icon={
              <SpeedDialIcon
                icon={<MenuIcon sx={{ marginTop: 0.3 }} />}
                openIcon={
                  <ChevronLeft
                    color="secondary"
                    fontSize="large"
                    sx={{ left: 7, top: 6 }}
                  />
                }
              />
            }
            sx={{ position: "absolute" }}
            direction={"down"}
            FabProps={{
              size: "small",
              sx: { bgcolor: "#000000 !important" },
            }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
          >
            {actions.map((action, key) => (
              <SpeedDialAction
                icon={action.icon}
                key={key}
                tooltipTitle={action.name}
                onClick={() => setOpen(false)}
                sx={{ background: "#000000 !important" }}
              />
            ))}
          </SpeedDial>
        </Box>
      </div>
    </>
  );
}

const styles = (theme: any) =>
  createStyles({
    main: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "inline",
        position: "absolute",
        left: 5,
        top: 13,
        zIndex: 9999,
      },
    },
  });

export default withStyles(styles, { withTheme: true })(SpeedDialLayout);
