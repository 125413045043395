import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { UrlEnum, get, post, phoneRegExp } from "../../Utils/Utils";
import CompanyRequest from "../../Components/user/CompanyRequest";
import UserRequest from "../../Components/user/UserRequest";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import Modal from "../../Components/Modal";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  classes: any;
};

function Request(props: Props) {
  const cachedData = CachedDataSingleton.getInstance();
  const statuses = cachedData.get("statuses");
  const { classes } = props;
  const { state } = useLocation();
  const { id } = state;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const requestValidationSchema = yup
    .object()
    .shape({
      userName: yup.string().required(Vocabulary.requiredField),
      email: yup
        .string()
        .email(Vocabulary.emailValidation)
        .required(Vocabulary.requiredField),
      fullName: yup.string().when("FK_userAccountId", (isUser, schema) => {
        return isUser ? schema.required(Vocabulary.requiredField) : schema;
      }),
      phone: yup.string().when("FK_userAccountId", (isUser, schema) => {
        return isUser ? schema.required(Vocabulary.requiredField) : schema;
      }),
      company: yup.object().when("FK_userAccountId", {
        is: undefined,
        then: yup.object().shape({
          name: yup.string().required(Vocabulary.requiredField),
          CUI: yup.string().required(Vocabulary.requiredField),
          registerNo: yup.string().required(Vocabulary.requiredField),
          representativeName: yup.string().required(Vocabulary.requiredField),
          email: yup
            .string()
            .required(Vocabulary.requiredField)
            .email(Vocabulary.emailValidation),
          phone: yup
            .string()
            .matches(phoneRegExp, Vocabulary.phoneValidation)
            .required(Vocabulary.requiredField),
        }),
      }),
    })
    .required();
  const methods = useForm({
    resolver: yupResolver(requestValidationSchema),
    mode: "all",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

  /**
   *
   */
  useEffect(() => {
    setLoading(true);
    get(`${UrlEnum.getUser}/${id}`).then((response: any) => {
      setLoading(false);
      if (response.errors) {
        toast.error(response.errors);
      } else {
        methods.reset(response.request);
      }
    });
  }, [location]);

  /**
   *
   * @param model
   */
  const handleUpdate = () => {
    setLoading(true);
    const model = methods.getValues();
    post(`${UrlEnum.updateRequestData}/${id}`, model).then((response) => {
      setLoading(false);
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.updateSuccess);
        methods.reset(response.request);
      }
    });
  };

  /**
   *
   */
  const handleUpdateRequestStatus = () => {
    setLoading(true);
    const body = {
      id: methods.getValues("id"),
      FK_accountStatusId: methods.getValues("FK_accountStatusId"),
      mentions: methods.getValues("mentions"),
    };
    post(UrlEnum.updateRequestStatus, body).then((response: any) => {
      setLoading(false);
      if (response.errors) {
        setOpen(false);
        toast.error(response.errors);
      } else {
        setOpen(false);
        toast.success(Vocabulary.updateSuccess);
        methods.reset(response.request);
      }
    });
  };

  /**
   *
   */

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <input type="hidden" {...methods.register("imagePath")}></input>
      <InputLabel>{Vocabulary.request.toUpperCase()}</InputLabel>
      <Paper className={classes.paper}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(() => handleUpdate())}>
            {methods ? (
              methods.getValues("FK_companyId") ? (
                <CompanyRequest />
              ) : (
                <UserRequest />
              )
            ) : null}
            <Grid
              container
              justifyContent="flex-end"
              className={classes.buttonContainer}
            >
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                className={classes.changeStatusButton}
                variant="contained"
              >
                {Vocabulary.changeStatus}
              </Button>
              <Button color="secondary" type="submit" variant="contained">
                {Vocabulary.save}
              </Button>
            </Grid>

            {open ? (
              <Modal
                title={Vocabulary.request}
                onClose={() => {
                  setOpen(false);
                }}
                open={open}
                actions={
                  <Button
                    onClick={handleUpdateRequestStatus}
                    className={classes.saveButton}
                    variant="contained"
                    color="secondary"
                  >
                    {Vocabulary.save}
                  </Button>
                }
                children={
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <InputLabel style={{ fontSize: 12 }}>
                        {Vocabulary.accountType}
                      </InputLabel>
                      <Select
                        label={Vocabulary.accountType}
                        native
                        required
                        fullWidth
                        variant="standard"
                        {...methods.register("FK_accountStatusId")}
                        id={"FK_accountStatusId"}
                        name={"FK_accountStatusId"}
                      >
                        {statuses &&
                          statuses.map((value: any, key: any) => {
                            return (
                              <option value={value.id} key={key}>
                                {value.status}
                              </option>
                            );
                          })}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="mentions"
                        label={Vocabulary.mentions}
                        {...methods.register("mentions")}
                        name="mentions"
                        fullWidth
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                }
              />
            ) : null}
          </form>
        </FormProvider>
      </Paper>
      {loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
    </>
  );
}
const styles = (theme: any) =>
  createStyles({
    paper: {
      margin: "0 auto",
      marginBottom: 70,
      marginTop: 10,
      padding: 20,
      [theme.breakpoints.down("md")]: {
        marginBottom: 0,
      },
    },

    changeStatusButton: {
      marginRight: "10px !important",
    },
    buttonContainer: {
      marginTop: 10,
      paddingRight: 25,
    },
    saveButton: {
      backgroundColor: "#fff !important",
      color: "#000 !important",
    },
  });
export default withStyles(styles, { withTheme: true })(Request);
