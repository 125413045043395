import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Grid, InputLabel, Select, TextField } from "@mui/material";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ConnectForm } from "../../Context/conectFormContext";

type Props = {
  classes: any;
  addUserInterface: any;
};

function Company(props: Props) {
  const cachedData = CachedDataSingleton.getInstance();
  const { classes } = props;
  const companyCategories = cachedData.get("companyCategories");

  return (
    <ConnectForm>
      {(methods: any) => {
        return (
          <>
            {props.addUserInterface ? null : (
              <>
                <Grid item xs={12} sm={12} md={3}>
                  <InputLabel style={{ fontSize: 12 }}>
                    {Vocabulary.accountType}
                  </InputLabel>
                  <Select
                    label={Vocabulary.accountType}
                    className={classes.select}
                    native
                    required
                    fullWidth
                    variant="standard"
                    id={"company.FK_categoryId"}
                    {...methods.register("company.FK_categoryId")}
                    name={"company.FK_categoryId"}
                  >
                    {companyCategories.map((value: any, key: any) => {
                      return (
                        <option value={value.id} key={key}>
                          {value.category}
                        </option>
                      );
                    })}
                  </Select>
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12} md={3}>
              <TextField
                variant="standard"
                id={"company.name"}
                {...methods.register("company.name")}
                name={"company.name"}
                label={Vocabulary.companyName}
                error={typeof methods.formState.errors.company?.name === "object"}
                helperText={methods.formState.errors.company?.name?.message?.toString()}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                variant="standard"
                id="company.CUI"
                {...methods.register("company.CUI")}
                name="company.CUI"
                error={typeof methods.formState.errors.company?.CUI === "object"}
                helperText={methods.formState.errors.company?.CUI?.message?.toString()}
                label={Vocabulary.CUI}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                variant="standard"
                id="company.registerNo"
                {...methods.register("company.registerNo")}
                name="company.registerNo"
                error={typeof methods.formState.errors.company?.registerNo=== "object"}
                helperText={methods.formState.errors.company?.registerNo?.message?.toString()}
                label={Vocabulary.registerNo}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                variant="standard"
                id="company.representativeName"
                {...methods.register("company.representativeName")}
                name="company.representativeName"
                label={Vocabulary.representativeName}
                error={typeof methods.formState.errors.company?.representativeName === "object"}
                helperText={methods.formState.errors.company?.representativeName?.message?.toString()}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                variant="standard"
                id="company.email"
                {...methods.register("company.email")}
                name="company.email"
                label={Vocabulary.email}
                error={typeof methods.formState.errors.company?.email === "object"}
                helperText={methods.formState.errors.company?.email?.message?.toString()}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                variant="standard"
                id="company.phone"
                {...methods.register("company.phone")}
                name="company.phone"
                label={Vocabulary.phone}
                error={typeof methods.formState.errors.company?.phone=== "object"}
                helperText={methods.formState.errors.company?.phone?.message?.toString()}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>
        );
      }}
    </ConnectForm>
  );
}

const styles = (theme: any) =>
  createStyles({
    select: {
      paddingTop: 0,
      width: "10%",
      marginBottom: 15,
      paddingRight: 10,
      marginRight: 10,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  });

export default withStyles(styles, { withTheme: true })(Company);
