import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useState, useEffect } from "react";
import {
  ToggleButton,
  Button,
  Tooltip,
  ToggleButtonGroup,
  Grid,
  TextField,
  Backdrop,
  CircularProgress,
  Box
} from "@mui/material";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import { useForm, FormProvider } from "react-hook-form";
import Modal from "../../Components/Modal";
import {
  UrlEnum,
  LocalUrlEnum,
  get,
  post,
  httpDelete,
  disableAllFields,
} from "../../Utils/Utils";
import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  classes: any;
};

function TagsTable(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = props;
  const [state, setState] = useState<any>({
    tags: [],
    page: 1,
    perPage: 20,
    order: "desc",
    preview: false,
    edit: false,
    add: false,
    count: 1,
  });
  
  const requestValidationSchema = yup
    .object()
    .shape({
      name: yup.string().required(Vocabulary.requiredField),
      description: yup.string().required(Vocabulary.requiredField),
      link: yup.string().required(Vocabulary.requiredField),
    })
    .required();
  const methods = useForm({
    resolver: yupResolver(requestValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });
  /**
   *
   */
  const requestHeaders = [
    { label: "Nume", name: "name", options: { sort: false } },
    { label: "Descriere", name: "description", options: { sort: false } },
    { label: "Link", name: "link", options: { sort: false } },
    { label: "Data creare", name: "createdDate", options: { sort: true } },
    {
      label: "TRUE",
      name: "status",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      label: "Opțiuni",
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,

        empty: true,

        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.view}>
                  <ToggleButton
                    value="left"
                    aria-label="left aligned"
                    onClick={() => {
                      handlePreview(state.tags[rowIndex].id);
                    }}
                  >
                    <Visibility />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    value="center"
                    aria-label="centered"
                    onClick={() => {
                      setState({ ...state, edit: true });
                      navigate(
                        `/${LocalUrlEnum.tags}/${state.tags[rowIndex].id}`,
                        {
                          state: { id: state.tags[rowIndex].id },
                        }
                      );
                    }}
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    value="center"
                    aria-label="centered"
                    onClick={() => {
                      handleDelete(state.tags[rowIndex].id);
                    }}
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];
  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [20, 50, 100],
      confirmFilters: true,
      filter: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page - 1,
      search: true,
      serverSide: true,
      customToolbar: () => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              setState({ ...state, add: true });
              methods.reset({});
            }}
          >
            {Vocabulary.addTag}
          </Button>
        );
      },
      onSearchChange: (searchText: string | null) => {
        if (searchText && searchText?.length > 3) getTags(searchText);
        if (!searchText) getTags(null);
      },
      onColumnSortChange: (changedColumn: string, direction: string) => {
        setState({ ...state, order: direction });
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page + 1 });
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        setState({ ...state, perPage: numberOfRows });
      },
    };
  };

  /**
   *
   */
  const handleCloseModal = () => {
    if (state.add) {
      setState({ ...state, add: false });
    } else {
      setState({ ...state, preview: false, edit: false });
      navigate(`/${LocalUrlEnum.tags}`);
    }
  };

  /**
   *
   */
  const getTags = (filter?: string | null) => {
    if (!filter) filter = null;
    if (state.preview) disableAllFields();
    setState({ ...state, loading: true });

    get(
      `${UrlEnum.tags}/${state.page}/${state.perPage}/${state.order}/${filter}`
    ).then((response) => {
      if (response.errors) {
        toast.error(response.errors);
        setState({ ...state, loading: false });
      } else {
        setState({
          ...state,
          tags: response.tags,
          edit: location.state == undefined ? false : true,
          add: false,
          count: response.total_count,
          loading: false,
        });
      }
    });
  };

  /**
   *
   */
  useEffect(() => {
    getTags();
  }, [state.perPage, state.page, state.order, state.preview]);

  /**
   *
   * @param id
   */
  const handleGetTag = (id: any) => {
    get(`${UrlEnum.getTag}/${id}`).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        methods.reset(response.tag);
      }
    });
  };

  /**
   *
   */
  const handleSubmitData = () => {
    const body = {
      name: methods.getValues("name"),
      description: methods.getValues("description"),
      link: methods.getValues("link"),
    };

    post(UrlEnum.tags, body).then((response: any) => {
      if (response.errors) {
        setState({ ...state, add: false });
        toast.error(response.errors);
      } else {
        setState({ ...state, add: false });
        methods.reset(response.tag);
        toast.success(Vocabulary.addDataSucces);
        getTags();
      }
    });
  };
  /**
   *
   * @param id
   */
  const handlePreview = (id: any) => {
    setState({
      ...state,
      preview: true,
    });
    handleGetTag(id);
  };

  /**
   *
   */
  useEffect(() => {
    if (state.preview) disableAllFields();
  }, [state]);

  /**
   *
   *
   */
  const handleUpdate = () => {
    post(UrlEnum.getTag, methods.getValues()).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        getTags();
      }
    });
  };

  /**
   *
   * @param id
   */
  const handleDelete = (id: any) => {
    httpDelete(`${UrlEnum.getTag}/${id}`).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.deleteSucces);
        getTags();
      }
    });
  };
  /**
   *
   */
  const handleSaveButton = () => {
    if (state.add) {
      handleSubmitData();
    } else {
      navigate(`/${LocalUrlEnum.tags}`);
      window.location.reload();
      setState({ ...state, edit: false });
      handleUpdate();
    }
  };

  /**
   *
   */
  useEffect(() => {
    if (location.state != undefined) {
      handleGetTag(location.state.id);
    }
  }, [location]);
  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <Box className={classes.tableBox}>
      <MUIDataTable
        title={Vocabulary.tags}
        data={state.tags}
        columns={requestHeaders}
        options={getTableOptions()}
      />
      </Box>
      {state.loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
      <Modal
        open={state.add || state.preview || state.edit}
        onClose={() => {
          handleCloseModal();
        }}
        title={Vocabulary.tags}
        actions={
         state.add || state. edit ? <Button
            type="submit"
            variant="contained"
            color="secondary"
            form="form"
          >
            {Vocabulary.save}
          </Button> : null
         }
        
        children={
          <FormProvider {...methods}>
            <form id="form" onSubmit={methods.handleSubmit(handleSaveButton)}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    id="name"
                    label={Vocabulary.tagName}
                    {...methods.register("name")}
                    fullWidth
                    error={typeof methods.formState.errors.name === "object"}
                    helperText={methods.formState.errors.name?.message?.toString()}
                    variant="standard"
                    name="name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    id="name"
                    label={Vocabulary.description}
                    {...methods.register("description")}
                    fullWidth
                    error={
                      typeof methods.formState.errors.description === "object"
                    }
                    helperText={methods.formState.errors.description?.message?.toString()}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    id="link"
                    label={Vocabulary.link}
                    {...methods.register("link")}
                    fullWidth
                    error={typeof methods.formState.errors.link === "object"}
                    helperText={methods.formState.errors.link?.message?.toString()}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        }
      ></Modal>
    </>
  );
}

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      margin: 5,
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    saveButton: {
      display: "flex",
      justifyContent: "flex-end",
    },
    tableBox: {
      [theme.breakpoints.down("sm")]: {
        width: "88vw",
      },
    },

  });
export default withStyles(styles, { withTheme: true })(TagsTable);
