import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { UrlEnum, get, httpDelete, LocalUrlEnum } from "../../Utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Backdrop,
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Box,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import UserEdit from "./UserEdit";
import Modal from "../../Components/Modal";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";
import Filter from "../../Components/Filter";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Config from "../../Utils/Config";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type Props = {
  classes: any;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, padding: 0 }}>{children}</Box>}
    </div>
  );
}

function UsersTable(props: Props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [articlesState, setArticlesState] = useState({
    open: false,
    articles: [],
  });
  const cachedData = CachedDataSingleton.getInstance();
  const [tab, setTab] = useState(0);
  const { classes } = props;
  const storage = window.localStorage;
  const companyCategories = JSON.parse(
    storage.getItem("companyCategories") || ""
  );
  const accounts = JSON.parse(storage.getItem("accounts") || "");
  const [state, setState] = useState<any>({
    users: [],
    page: 1,
    perPage: 20,
    order: "desc",
    preview: false,
    usersCount: 1,
    adminsCount: 1,
    startDate: null,
    endDate: null,
    role: null,
    loading: false,
    admins: [],
    userAccount: "",
    companyCategory: "",
  });

  const requestHeaders = [
    { label: "Nume utilizator", name: "userName", options: { sort: false } },
    { label: "Nume", name: "fullName", options: { sort: false } },
    { label: "Email", name: "email", options: { sort: false } },
    { label: "Rol", name: "accountType", options: { sort: false } },
    { label: "Companie", name: "company", options: { sort: false } },
    {
      label: "Status abonament",
      name: "paymentStatus",
      options: { sort: false },
    },
    {
      label: "Data expirare abonament ",
      name: "paymentExpirationDate",
      options: { sort: true },
    },
    {
      label: "Timp petrecut pe platformă",
      name: "time",
      options: {
        sort: false,
      },
    },
    {
      label: "Articole accesate",
      name: "",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setArticlesState({
                  ...articlesState,
                  articles: state.users[rowIndex].articles,
                  open: true,
                });
              }}
            >
              {Vocabulary.seeArticles}
            </Button>
          );
        },
      },
    },
    {
      label: "TRUE",
      name: "status",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      label: "Opțiuni",
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.view}>
                  <ToggleButton
                    value="left"
                    aria-label="left aligned"
                    onClick={() => {
                      setOpen(true);
                      cachedData.setKey("id", state.users[rowIndex].id);
                      cachedData.setKey("preview", true);
                    }}
                  >
                    <Visibility />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      navigate(
                        `/${LocalUrlEnum.user}/${state.users[rowIndex].id}`,
                        { state: { id: state.users[rowIndex].id } }
                      );
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={() => {
                      handleDelete(state.users[rowIndex].id);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  const requestHeadersAdmins = [
    { label: "Nume utilizator", name: "userName", options: { sort: false } },
    { label: "Nume", name: "fullName", options: { sort: false } },
    { label: "Email", name: "email", options: { sort: false } },
    {
      label: "Opțiuni",
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    value="center"
                    aria-label="centered"
                    onClick={() => {
                      navigate(
                        `/${LocalUrlEnum.admin}/${state.admins[rowIndex].id}`,
                        { state: { id: state.admins[rowIndex].id } }
                      );
                    }}
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    value="center"
                    aria-label="centered"
                    onClick={() => {
                      handleDelete(state.admins[rowIndex].id);
                    }}
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [20, 50, 100],
      confirmFilters: true,
      filter: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      count: state.usersCount,
      rowsPerPage: state.perPage,
      page: state.page - 1,
      search: true,
      serverSide: true,
      customToolbar: () => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/${LocalUrlEnum.user}`);
            }}
          >
            {Vocabulary.addNewUser}
          </Button>
        );
      },
      onSearchChange: (searchText: string | null) => {
        if (searchText && searchText?.length > 3) {
          getUsers(searchText);
        }
        if (!searchText) getUsers(null);
      },
      onColumnSortChange: (changedColumn: string, direction: string) => {
        setState({ ...state, order: direction });
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page + 1 });
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        setState({ ...state, perPage: numberOfRows });
      },
    };
  };

  /**
   *
   * @returns
   */
  const getTableOptionsAdmins = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [20, 50, 100],
      confirmFilters: true,
      filter: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      count: state.adminsCount,
      rowsPerPage: state.perPage,
      page: state.page - 1,
      search: true,
      serverSide: true,
      customToolbar: () => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/${LocalUrlEnum.admin}`);
            }}
          >
            {Vocabulary.addNewAdmin}
          </Button>
        );
      },
      onSearchChange: (searchText: string | null) => {
        if (searchText && searchText?.length > 3) {
          getUsers(searchText, 0);
        }
        if (!searchText) getUsers(null);
      },
      onColumnSortChange: (changedColumn: string, direction: string) => {
        setState({ ...state, order: direction });
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page + 1 });
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        setState({ ...state, perPage: numberOfRows });
      },
    };
  };

  /**
   *
   * @param id
   */
  const handleDelete = (id: any) => {
    httpDelete(`${UrlEnum.getUser}/${id}`).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.deleteSucces);
        getUsers();
      }
    });
  };

  /**
   *
   */
  useEffect(() => {
    getUsers();
  }, [
    state.perPage,
    state.page,
    state.status,
    state.startDate,
    state.endDate,
    state.order,
    state.userAccount,
    state.companyCategory,
  ]);

  /**
   *
   * @param filter
   * @param isAdmin
   */
  const getUsers = (filter?: string | null, isAdmin?: number | null) => {
    if (!filter) filter = null;

    let filterAdmin = null;
    let filterUser = null;
    let userAccountFilter = null;
    let companyCategoryFilter = null;

    isAdmin == 0 ? (filterAdmin = filter) : (filterUser = filter);

    if (state.userAccount != "") userAccountFilter = state.userAccount;
    if (state.companyCategory != "")
      companyCategoryFilter = state.companyCategory;

    setState({ ...state, loading: true });
    get(
      `${UrlEnum.getUsers}/${state.page}/${state.perPage}/${state.order}/${state.startDate}/${state.endDate}/${filterAdmin}/${filterUser}/${userAccountFilter}/${companyCategoryFilter}`
    ).then((response) => {
      if (response.errors) {
        toast.error(response.errors);
        setState({ ...state, loading: false });
      } else {
        setState({
          ...state,
          users: response.users,
          usersCount: response.nrOfUsers,
          adminsCount: response.nrOfAdmins,
          admins: response.admins,
          loading: false,
        });
      }
    });
  };

  /**
   *
   * @param index
   * @returns
   */
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  /**
   *
   */
  const handleDeleteFilters = () => {
    setState({
      ...state,
      startDate: null,
      endDate: null,
      userAccount: "",
      companyCategory: "",
    });
  };

  /**
   *
   * @param event
   * @param newValue
   */
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <Box className={classes.tabContainer}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            color: "#fff",
          }}
        >
          <Tab
            style={{ color: "#fff" }}
            label={Vocabulary.users}
            {...a11yProps(0)}
          />
          <Tab
            style={{ color: "#fff" }}
            label={Vocabulary.admins}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <Filter
          children={
            <Box className={classes.filterBox}>
              <Grid container className={classes.filterContainer}>
                <Grid container spacing={2} className={classes.dateContainer}>
                  <Grid item xs={12} sm={6} md={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        className={classes.datePicker}
                        inputFormat={Config.momentEUDateFormat}
                        label={Vocabulary.startDate}
                        value={state.startDate}
                        onChange={(value) => {
                          setState({
                            ...state,
                            startDate: value.format(Config.datePickerFormat),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField sx={{ width: "100%" }} {...params} />
                        )}
                      ></DatePicker>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        className={classes.datePicker}
                        inputFormat={Config.momentEUDateFormat}
                        label={Vocabulary.endDate}
                        value={state.endDate}
                        onChange={(value) => {
                          setState({
                            ...state,
                            endDate: value.format(Config.datePickerFormat),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField sx={{ width: "100%" }} {...params} />
                        )}
                      ></DatePicker>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel>{Vocabulary.selectRole}</InputLabel>
                    <FormControl className={classes.select} fullWidth>
                      <Select
                        value={state.userAccount}
                        onChange={(e) =>
                          setState({
                            ...state,
                            companyCategory: "",
                            userAccount: e.target.value,
                          })
                        }
                      >
                        {accounts.map((value: any, key: any) => (
                          <MenuItem key={key} value={value.id}>
                            {value.account}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputLabel>{Vocabulary.selectCompanyCategory}</InputLabel>
                    <FormControl className={classes.select} fullWidth>
                      <Select
                        value={state.companyCategory}
                        onChange={(e) =>
                          setState({
                            ...state,
                            userAccount: "",
                            companyCategory: e.target.value,
                          })
                        }
                      >
                        {companyCategories.map((value: any, key: any) => (
                          <MenuItem key={key} value={value.id}>
                            {value.category}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          }
          handleDeleteFilters={handleDeleteFilters}
        />
        <Box className={classes.tableBox}>
          <MUIDataTable
            title={Vocabulary.users}
            data={state.users}
            columns={requestHeaders}
            options={getTableOptions()}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Box className={classes.tableBox}>
          <MUIDataTable
            title={Vocabulary.admins}
            data={state.admins}
            columns={requestHeadersAdmins}
            options={getTableOptionsAdmins()}
          />
        </Box>
      </TabPanel>
      {state.loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
      {open ? (
        <Modal
          title={null}
          onClose={() => {
            setOpen(false);
            cachedData.setKey("id", null);
          }}
          open={open}
          actions={null}
          children={<UserEdit />}
        />
      ) : null}
      {articlesState.open ? (
        <Modal
          title={`${Vocabulary.viewedArticles}: ${articlesState.articles.length}`}
          onClose={() => {
            setArticlesState({ ...articlesState, open: false });
          }}
          open={articlesState.open}
          actions={null}
          children={
            <div>
              <List className={classes.list}>
                {articlesState.articles.map((el: any, index: any) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={`${index + 1}. ${el.article.title}`}
                        key={index}
                      ></ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          }
        />
      ) : null}
    </>
  );
}

const styles = (theme: any) =>
  createStyles({
    tabContainer: {
      background:
        "linear-gradient(191deg, rgba(45,105,112,1) 21%, rgba(98,182,183,1) 62%, rgba(45,105,112,1) 82%)!important",
      borderBottom: 1,
      borderColor: "divider",
    },
    datePicker: {
      //margin: "5px !important",
    },
    dateContainer: {
      marginBottom: 20,
    },
    filterContainer: {
      padding: 10,
    },
    tableBox: {
      [theme.breakpoints.down("sm")]: {
        width: "87vw",
      },
    },
    filterBox: {
      [theme.breakpoints.down("sm")]: {},
    },
    list: {
      columns: "4 auto",
      columnGap: "4vw",
      [theme.breakpoints.down("sm")]: {
        columns: "2 auto",
      },
    },
  });
export default withStyles(styles, { withTheme: true })(UsersTable);
