import { ToastContainer, toast } from "react-toastify";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";
import {
  UrlEnum,
  get,
  post,
  LocalUrlEnum,
  disableAllFields,
} from "../../Utils/Utils";
import { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  ToggleButton,
  Tooltip,
  ToggleButtonGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
  Box
} from "@mui/material";
import Modal from "../../Components/Modal";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Edit, Visibility } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createStyles} from "@mui/material/styles";
import { withStyles } from "@mui/styles";


type Props = {
  classes: any;
};


function AttributesTable(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = props;
  const [state, setState] = useState<any>({
    attributes: [],
    page: 1,
    perPage: 20,
    order: "desc",
    preview: false,
    loading: false,
    edit: false,
    count: 1,
  });
  const [open, setOpen] = useState(false);

  /**
   *
   */
  const getAttributes = (filter?: string | null) => {
    if (!filter) filter = null;
    
    setState({ ...state, loading: true });
    
    get(
      `${UrlEnum.attributes}/${state.page}/${state.perPage}/${state.order}/${filter}`
    ).then((response) => {
      if (response.errors) {
        toast.error(response.errors);
        setState({ ...state, loading: false });
      } else {
        setState({
          ...state,
          attributes: response.attributes,
          count: response.total_count,
          edit: location.state == undefined ? false : true,
          loading: false,
        });
      }
    });
    if (state.preview) disableAllFields();
  };

  /**
   *
   */
  useEffect(() => {
    getAttributes();
  }, [state.perPage, state.page, state.order, state.preview]);

  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "multiple" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [20, 50, 100],
      confirmFilters: true,
      filter: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page - 1,
      search: true,
      serverSide: true,
      customToolbar: () => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              methods.reset({});
              setOpen(true);
            }}
          >
            {Vocabulary.addAttribute}
          </Button>
        );
      },

      onSearchChange: (searchText: string | null) => {
        if (searchText && searchText?.length > 3) getAttributes(searchText);
        if (!searchText) getAttributes(null);
      },

      onColumnSortChange: (changedColumn: string, direction: string) => {
        setState({ ...state, order: direction });
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page + 1 });
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        setState({ ...state, perPage: numberOfRows });
      },
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        handleDelete(rowsDeleted);
      },
    };
  };

  function handleEditClick(rowIndex: any) {
    setState({ ...state, edit: true });
    navigate(`/${LocalUrlEnum.attributes}/${state.attributes[rowIndex].id}`, {
      state: { id: state.attributes[rowIndex].id },
    });
  }

  /**
   *
   */
  const requestHeaders = [
    { label: "Nume", name: "name", options: { sort: false } },
    { label: "Link", name: "link", options: { sort: false } },
    { label: "Termeni", name: "terms", options: { sort: false } },
    { label: "Data creare", name: "createdDate", options: { sort: true } },
    {
      label: "TRUE",
      name: "status",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      label: "Opțiuni",
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,

        empty: true,

        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.view}>
                  <ToggleButton
                    onClick={() => {
                      disableAllFields();
                      handlePreview(state.attributes[rowIndex].id);
                    }}
                    value="left"
                    aria-label="left aligned"
                  >
                    <Visibility />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    value="center"
                    aria-label="centered"
                    onClick={() => handleEditClick(rowIndex)}
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];
  const requestValidationSchema = yup
  .object()
  .shape({
    name: yup.string().required(Vocabulary.requiredField),
    link: yup.string().required(Vocabulary.requiredField),
  })
  .required();
  const methods = useForm({
    resolver: yupResolver(requestValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

  /**
   *
   * @param id
   */
  const handleGetAttribute = (id: any) => {
    get(`${UrlEnum.getAttribute}/${id}`).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        methods.reset(response.attribute);
      }
    });
  };

  /**
   *
   */
  const handleSubmit = () => {
    const body = {
      name: methods.getValues("name"),
      link: methods.getValues("link"),
      filter: methods.getValues("filter"),
    };
    post(UrlEnum.attributes, body).then((response: any) => {
      if (response.errors) {
        setOpen(false);
        toast.error(response.errors);
      } else {
        setOpen(false);
        methods.reset(response.attribute);
        getAttributes();
      }
    });
  };

  /**
   *
   * @param id
   */
  const handlePreview = (id: any) => {
    setState({
      ...state,
      preview: true,
    });
    handleGetAttribute(id);
  };
  
  /**
   *
   * @param id
   */
  const handleDelete = (selectedRows: any) => {
    const ids: number[] = [];

    selectedRows.data.map((row: any, k: any) => {
      ids.push(state.attributes[row.dataIndex].id);
    });

    post(UrlEnum.deleteAttributes, ids).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.deleteSucces);
      }
      getAttributes();
    });
    selectedRows.data.length = 0;
  };

  /**
   *
   *
   */
  const handleUpdate = () => {
    post(UrlEnum.getAttribute, methods.getValues()).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        getAttributes();
      }
    });
  };

  function handleSaveButton() {
    if (open) {
      handleSubmit();
    } else {
      navigate(`/${LocalUrlEnum.attributes}`);
      window.location.reload();
      setState({ ...state, edit: false });

      handleUpdate();
    }
  }
  /**
   *
   */
  useEffect(() => {
    if (location.state != undefined) {
      handleGetAttribute(location.state.id);
    }
  }, [location]);

  /**
   *
   */
  useEffect(() => {
    if (state.preview) disableAllFields();
  }, [state]);

  const handleCloseModal = () => {
    if (open) {
      setOpen(false);
    } else {
      setState({ ...state, preview: false, edit: false });
      navigate(`/${LocalUrlEnum.attributes}`);
    }
  };
  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <Box className={classes.tableBox}>
      <MUIDataTable
        title={Vocabulary.attributes}
        data={state.attributes}
        columns={requestHeaders}
        options={getTableOptions()}
        
      />
      </Box>
      {state.loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
      <Modal
        open={open || state.preview || state.edit}
        onClose={() => {
          handleCloseModal();
        }}
        title={Vocabulary.Attribute}
        actions={
          open || state.edit ? (
            <Button
            type="submit"
            variant="contained"
            color="secondary"
            form="form"
            >
              {Vocabulary.save}
            </Button>
          ) : null
        }
        children={
          <FormProvider {...methods}>
          <form id="form" onSubmit={methods.handleSubmit(handleSaveButton)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="name"
                label={Vocabulary.attributeName}
                {...methods.register("name")}
                fullWidth
                error={typeof methods.formState.errors.name === "object"}
                helperText={methods.formState.errors.name?.message?.toString()}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                id="attributeLink"
                label={Vocabulary.link}
                {...methods.register("link")}
                fullWidth
                error={typeof methods.formState.errors.link === "object"}
                helperText={methods.formState.errors.link?.message?.toString()}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {!open ? (
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  id="terms"
                  label={Vocabulary.terms}
                  {...methods.register("terms")}
                  fullWidth
                  error={typeof methods.formState.errors.terms === "object"}
                  helperText={methods.formState.errors.terms?.message?.toString()}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={6}>
              <FormControlLabel
                control={
                  <Controller
                    name="filter"
                    control={methods.control}
                    defaultValue={false}
                    render={({ field: { value, ref, ...field } }) => (
                      <Checkbox
                        {...field}
                        inputRef={ref}
                        checked={!!value}
                        color="primary"
                        size={"medium"}
                        disableRipple
                      />

                    )}
                  />
                }
                label={Vocabulary.attributeFilter}
                labelPlacement="end"
              />
            </Grid>
          </Grid>
          </form>
          </FormProvider>
        }
      ></Modal>
    </>
  );
}

const styles = (theme: any) =>
  createStyles({
    tableBox:{
      [theme.breakpoints.down("sm")]: {
        width: "88vw",
      },
    }
  });
export default withStyles(styles, { withTheme: true })(AttributesTable);
