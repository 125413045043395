import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Paper, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useEffect } from "react";
import { AccountTypes, SubscriptionTypes } from "../../Utils/Utils";

type Props = {
  classes: any;
  handleChange: (event: any) => void;
  model: any;
  subscriptions: any;
};

function FourthStep(props: Props) {
  const { classes } = props;

  /**
   *
   */
  useEffect(() => {
    handleChangeSubscription();
  }, []);

  /**
   *
   * @returns
   */
  const handleChangeSubscription = () => {
    let subsId;
    if (props.model.isUserAccount === "false") {
      subsId = props.subscriptions.filter(
        (subs: any) =>
          subs.type === SubscriptionTypes.PROFESSIONAL && subs.valability === 1
      );
      props.handleChange({
        target: {
          value: subsId[0].id,
          name: "FK_subscriptionId",
        },
      });
      return;
    }
    switch (props.model.FK_userAccountId) {
      case AccountTypes.MedicVeterinar:
        subsId = props.subscriptions.filter(
          (subs: any) =>
            subs.type === SubscriptionTypes.PREMIUM && subs.valability === 1
        );
        props.handleChange({
          target: {
            value: subsId[0].id,
            name: "FK_subscriptionId",
          },
        });
        return;
      case AccountTypes.StudentVeterinar:
        subsId = props.subscriptions.filter(
          (subs: any) =>
            subs.type === SubscriptionTypes.STUDENT && subs.valability === 1
        );
        props.handleChange({
          target: {
            value: subsId[0].id,
            name: "FK_subscriptionId",
          },
        });
        return;
      case AccountTypes.TehnicianSauAsistentVeterinar:
      case AccountTypes.FermierSauProprietarDeAnimale:
        subsId = props.subscriptions.filter(
          (subs: any) =>
            subs.type === SubscriptionTypes.BASIC && subs.valability === 1
        );
        props.handleChange({
          target: {
            value: subsId[0].id,
            name: "FK_subscriptionId",
          },
        });
        return;
      default:
        return null;
    }
  };

  /**
   *
   * @param subscription
   * @returns
   */
  const showSubscription = (
    subscription: any,
    style: any,
    isCompany?: boolean
  ) => {
    return (
      <Paper className={classes.paper}>
        <div className={style}>
          {Vocabulary.subscription}
          <Typography variant="h5" align="center">
            {subscription?.type}
          </Typography>
        </div>
        <Typography
          className={classes.text}
          variant="h6"
          align="center"
        >{`${subscription.price} ${Vocabulary.currency}/${Vocabulary.month}`}</Typography>
        <Typography className={classes.text} align="center" variant="subtitle1">
          {Vocabulary.subscriptionText}
        </Typography>
      </Paper>
    );
  };
  return (
    <div className={classes.container}>
      {(() => {
        if (props.model.isUserAccount === "false") {
          return showSubscription(
            props.subscriptions.filter(
              (subs: any) =>
                subs.type === SubscriptionTypes.PROFESSIONAL &&
                subs.valability === 1
            )[0],
            classes.subscriptionNameProfessional,
            true
          );
        }
        switch (props.model.FK_userAccountId) {
          case AccountTypes.MedicVeterinar:
            return showSubscription(
              props.subscriptions.filter(
                (subs: any) =>
                  subs.type === SubscriptionTypes.PREMIUM &&
                  subs.valability === 1
              )[0],
              classes.subscriptionNamePremium
            );
          case AccountTypes.StudentVeterinar:
            return showSubscription(
              props.subscriptions.filter(
                (subs: any) =>
                  subs.type === SubscriptionTypes.STUDENT &&
                  subs.valability === 1
              )[0],
              classes.subscriptionNamePremium
            );
          case AccountTypes.TehnicianSauAsistentVeterinar:
          case AccountTypes.FermierSauProprietarDeAnimale:
            return showSubscription(
              props.subscriptions.filter(
                (subs: any) =>
                  subs.type === SubscriptionTypes.BASIC && subs.valability === 1
              )[0],
              classes.subscriptionNameBasic
            );
          default:
            return null;
        }
      })()}
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    container: {
      padding: 20,
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {},
    },
    paper: {
      margin: 20,
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    subscriptionNameProfessional: {
      backgroundColor: "#0E5E6F",
      color: "#FFF",
      padding: 20,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    subscriptionNameBasic: {
      backgroundColor: "#59C1BD",
      padding: 20,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    subscriptionNamePremium: {
      backgroundColor: "#0E5E6F",
      color: "#FFF",
      padding: 20,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    text: {
      padding: 15,
    },
    select: {
      margin: 15,
    },
  });

export default withStyles(styles, { withTheme: true })(FourthStep);
