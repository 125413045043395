import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import {
  CheckUserRole,
  LocalUrlEnum,
  UrlEnum,
  get,
  readCookie,
} from "../../Utils/Utils";
import DrawerLayout from "../../Components/DrawerLayout";
import { Box } from "@mui/system";
import { CssBaseline } from "@mui/material";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";
import { Icon, Segment, Statistic } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import SpeedDialLayout from "../../Components/SpeedDialLayout";
import Footer from "../../Components/Footer";

type Props = {
  classes: any;
  children?: any;
};

function Dashboard(props: Props) {
  const { classes } = props;
  const navigate = useNavigate();
  const cachedData = CachedDataSingleton.getInstance();
  const location = useLocation();
  const pathName = window.location.pathname;
  const [statistics, setStatistics] = useState({
    nrUsers: 0,
    nrActiveUsers: 0,
  });

  /**
   *
   */
  useEffect(() => {
    const storage = window.localStorage;
    if (!readCookie("access_token")) {
      navigate(LocalUrlEnum.login);
    }
    if (CheckUserRole.isAdmin())
      get(UrlEnum.statistics).then((response: any) => {
        if (!response.errors)
          setStatistics({
            nrUsers: response.numberOfUsers,
            nrActiveUsers: response.numberOfActiveUsers,
          });
      });

    if (
      storage.getItem("accounts") &&
      storage.getItem("accounts") !== "" &&
      storage.getItem("roles") &&
      storage.getItem("roles") !== "" &&
      storage.getItem("companyCategories") &&
      storage.getItem("companyCategories") !== "" &&
      storage.getItem("subscriptions") &&
      storage.getItem("subscriptions") !== "" &&
      storage.getItem("statuses") &&
      storage.getItem("statuses") !== "" &&
      storage.getItem("user") &&
      storage.getItem("user") !== ""
    ) {
      const accounts = JSON.parse(storage.getItem("accounts") || "");
      const companyCategories = JSON.parse(
        storage.getItem("companyCategories") || ""
      );
      const subscriptions = JSON.parse(storage.getItem("subscriptions") || "");
      const statuses = JSON.parse(storage.getItem("statuses") || "");
      const user = JSON.parse(storage.getItem("user") || "");
      const roles = JSON.parse(storage.getItem("roles") || "");
      cachedData.set({
        accounts: accounts,
        companyCategories: companyCategories,
        subscriptions: subscriptions,
        statuses: statuses,
        user: user,
        roles: roles,
      });
    } else {
      const promises = [
        get(UrlEnum.getAccountTypes),
        get(UrlEnum.getCompanyCategories),
        get(UrlEnum.getSubscriptionTypes),
        get(UrlEnum.getStatuses),
        get(UrlEnum.getUserRoles),
      ];

      Promise.all(promises).then((values: any) => {
        cachedData.set({
          accounts: values[0].accounts,
          companyCategories: values[1].categories,
          subscriptions: values[2].subscriptions,
          statuses: values[3].subscriptions,
          roles: values[4].roles,
          user: JSON.parse(storage.getItem("user") || ""),
        });
        storage.setItem("accounts", JSON.stringify(values[0].accounts));
        storage.setItem(
          "companyCategories",
          JSON.stringify(values[1].categories)
        );
        storage.setItem(
          "subscriptions",
          JSON.stringify(values[2].subscriptions)
        );
        storage.setItem("statuses", JSON.stringify(values[3].statuses));
        storage.setItem("roles", JSON.stringify(values[4].roles));
      });
    }
  }, [location]);

  return (
    <div className={classes.container}>
      <Header />
      {CheckUserRole.isAdmin() ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <DrawerLayout />
          <SpeedDialLayout />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {pathName === "/" ? (
              <Segment inverted className={classes.segment}>
                <Statistic.Group
                  widths="two"
                  inverted
                  size="tiny"
                  className={classes.statisticGroup}
                >
                  <div className={classes.statistic}>
                    <Statistic color="teal">
                      <Statistic.Value>
                        <Icon name="user" />
                        {statistics.nrUsers}
                      </Statistic.Value>
                      <Statistic.Label>{Vocabulary.users}</Statistic.Label>
                    </Statistic>
                    <Statistic color="teal">
                      <Statistic.Value>
                        <Icon name="users" />
                        {statistics.nrActiveUsers}
                      </Statistic.Value>
                      <Statistic.Label>
                        {Vocabulary.activeUsers}
                      </Statistic.Label>
                    </Statistic>
                  </div>
                </Statistic.Group>
              </Segment>
            ) : (
              <Outlet />
            )}
          </Box>
        </Box>
      ) : (
        <>
          <Outlet /> <Footer />
        </>
      )}
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    container: {
      backgroundColor: "#ffff",
      width:'100%',
      [theme.breakpoints.down("lg")]: {},
    },
    segment: {
      marginTop: "25px !important",
      background:
        "linear-gradient(191deg, rgba(45,105,112,1) 21%, rgba(98,182,183,1) 62%, rgba(45,105,112,1) 82%)!important",
    },
    statisticGroup: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    statistic: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
  });

export default withStyles(styles, { withTheme: true })(Dashboard);
