import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Grid, TextField } from "@mui/material";
import { ConnectForm } from "../../Context/conectFormContext";

type Props = {
  modelRoot: string;
};

function Address(props: Props) {
  return (
    <ConnectForm>
      {(methods: any) => {
        return (
          <>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                variant="standard"
                id={`${props.modelRoot}country`}
                name={`${props.modelRoot}country`}
                label={Vocabulary.country}
                {...methods.register(`${props.modelRoot}country`)}
                error={
                  typeof methods.formState.errors.address?.country === "object"
                }
                helperText={methods.formState.errors?.address?.country?.message}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                variant="standard"
                id={`${props.modelRoot}county`}
                name={`${props.modelRoot}county`}
                label={Vocabulary.county}
                {...methods.register(`${props.modelRoot}county`)}
                error={
                  typeof methods.formState.errors.address?.county === "object"
                }
                helperText={methods.formState.errors.address?.county?.message}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item  xs={12} sm={12} md={3}>
              <TextField
                variant="standard"
                id={`${props.modelRoot}city`}
                name={`${props.modelRoot}city`}
                label={Vocabulary.city}
                {...methods.register(`${props.modelRoot}city`)}
                error={
                  typeof methods.formState.errors.address?.city === "object"
                }
                helperText={methods.formState.errors.address?.city?.message}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                variant="standard"
                id={`${props.modelRoot}address`}
                name={`${props.modelRoot}address`}
                label={Vocabulary.address}
                {...methods.register(`${props.modelRoot}address`)}
                error={
                  typeof methods.formState.errors.address?.address === "object"
                }
                helperText={methods.formState.errors.address?.address?.message}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>
        );
      }}
    </ConnectForm>
  );
}

const styles = (theme: any) => createStyles({});
export default withStyles(styles, { withTheme: true })(Address);
