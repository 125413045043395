import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { Vocabulary } from "../../Utils/Vocabulary";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import {
  CategoryPropertiesArray,
  UrlEnum,
  generateFormData,
  get,
  getImage,
  post,
  LocalUrlEnum,
  disableAllFields,
} from "../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { DropzoneArea } from "mui-file-dropzone";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";

type Props = {
  classes: any;
};

function Category(props: Props) {
  const cachedData = CachedDataSingleton.getInstance();
  const { classes } = props;
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categoryState, setState] = useState({
    categories: [],
    update: false,
  });
  const preview = cachedData.get("preview");
  const idCache = cachedData.get("id");
  const requestValidationSchema = yup
    .object()
    .shape({
      name: yup.string().required(Vocabulary.requiredField),
      link: yup.string().required(Vocabulary.requiredField),
      order: yup.string().required(Vocabulary.requiredField),
      mainMenu: yup.boolean().default(false),
    })
    .required();
  const methods = useForm({
    resolver: yupResolver(requestValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

  /**
   *
   */
  useEffect(() => {
    get(`${UrlEnum.getCategories}`).then((response: any) => {
      if (!response.errors) {
        setState({ ...categoryState, categories: response.items });
      }
    });
    if (state?.category || idCache) {
      get(
        `${UrlEnum.getCategory}/${idCache ? idCache : state.category.id}`
      ).then((response) => {
        if (response.errors) {
          toast.error(response.errors);
        } else {
          const category = response.category;
          if (category.imagePath) {
            const url = `${UrlEnum.getImage}/${category.imagePath}`;
            getImage(url, (data) => {
              const fileName = category.imagePath.slice(
                category.imagePath.lastIndexOf("/") + 1
              );
              const file = new File([data], fileName, { type: data.type });
              category.image = file;
              methods.reset(category);
            });
          }
        }
      });
    }
  }, [state, idCache, categoryState.update]);

  /**
   *
   */
  useEffect(() => {
    if (preview) disableAllFields();
  }, []);

  /**
   *
   */
  const handleSave = () => {
    setLoading(true);
    const model = methods.getValues();
    const formData = generateFormData(model, CategoryPropertiesArray);
    post(`${UrlEnum.getCategory}`, formData).then((response: any) => {
      setLoading(false);
      if (response.errors) {
        toast.error(response.errors);
      } else {
        navigate(`/${LocalUrlEnum.categories}`);
        toast.success(Vocabulary.updateSuccess);
        methods.reset(response.category);
        setState({ ...categoryState, update: true });
      }
    });
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSave)}>
          <Paper className={classes.paper} id="previewDialogContent">
            <Typography variant="h6" align="left">
              {Vocabulary.categoryForm}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                {(state === null &&
                  !methods.getValues("image") &&
                  window.location.pathname != `/${LocalUrlEnum.categories}`) ||
                methods.getValues("image") ? (
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <DropzoneArea
                        filesLimit={1}
                        acceptedFiles={["image/*"]}
                        maxFileSize={5000000}
                        dropzoneText={Vocabulary.selectImage}
                        onChange={onChange}
                        fileObjects={value ? [value] : []}
                        initialFiles={value ? [value] : []}
                      />
                    )}
                    name="image"
                    control={methods.control}
                  />
                ) : null}
              </Grid>
              <Grid container spacing={2} item xs={12} sm={12} md={6}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="name"
                    label={Vocabulary.categoryName}
                    {...methods.register("name")}
                    fullWidth
                    error={typeof methods.formState.errors.name === "object"}
                    helperText={methods.formState.errors.name?.message?.toString()}
                    variant="standard"
                    name="name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="link"
                    label={Vocabulary.link}
                    {...methods.register("link")}
                    fullWidth
                    error={typeof methods.formState.errors.link === "object"}
                    helperText={methods.formState.errors.link?.message?.toString()}
                    variant="standard"
                    name="link"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    id="order"
                    type="number"
                    label={Vocabulary.order}
                    {...methods.register("order")}
                    fullWidth
                    error={typeof methods.formState.errors.order === "object"}
                    helperText={methods.formState.errors.order?.message?.toString()}
                    variant="standard"
                    name="order"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <InputLabel style={{ fontSize: 10 }}>
                    {Vocabulary.parentCategory}
                  </InputLabel>
                  <Controller
                    name="FK_parentId"
                    control={methods.control}
                    render={({ field: { value, onChange } }) => (
                      <FormControl fullWidth>
                        <Select
                          variant="standard"
                          className={classes.select}
                          onChange={onChange}
                          value={value ? value : ""}
                        >
                          {categoryState.categories &&
                            categoryState.categories.map(
                              (category: any, key: any) => (
                                <MenuItem key={key} value={category.id}>
                                  {category.name}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="mainMenu"
                        control={methods.control}
                        defaultValue={false}
                        render={({ field: { value, ref, ...field } }) => (
                          <Checkbox
                            {...field}
                            inputRef={ref}
                            checked={!!value}
                            color="primary"
                            size={"medium"}
                            disableRipple
                          />
                        )}
                      />
                    }
                    label={Vocabulary.mainMenuCategory}
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.saveButton}>
              <Button type="submit" variant="contained" color="secondary">
                {Vocabulary.save}
              </Button>
            </div>
          </Paper>
        </form>
      </FormProvider>
      {loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
    </>
  );
}
const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      margin: 5,
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    saveButton: {
      display: "flex",
      justifyContent: "flex-end",
    },
  });
export default withStyles(styles, { withTheme: true })(Category);
