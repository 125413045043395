import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Grid, TextField } from "@mui/material";
import Company from "./Company";
import Address from "./Address";
import { useEffect } from "react";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";
import { disableAllFields } from "../../Utils/Utils";
import { ConnectForm } from "../../Context/conectFormContext";

type Props = {
  classes: any;
};

function CompanyRequest(props: Props) {
  const cachedData = CachedDataSingleton.getInstance();
  const preview = cachedData.get("preview");
  const { classes } = props;

  /**
   *
   */
  useEffect(() => {
    if (preview) disableAllFields();
  }, []);

  return (
    <ConnectForm>
      {(methods: any) => {
        return (
          <div id="previewDialogContent">
            <fieldset className={classes.fieldset}>
              <legend>{Vocabulary.companyData}</legend>
              <Grid container spacing={2}>
                <Company addUserInterface={false} />
                <Address modelRoot="company.address." />
              </Grid>
            </fieldset>
            <fieldset className={classes.fieldset}>
              <legend>{Vocabulary.userData}</legend>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="userName"
                    name="userName"
                    label={Vocabulary.username}
                    {...methods.register("userName")}
                    error={
                      typeof methods.formState.errors.userName === "object"
                    }
                    helperText={methods.formState.errors.userName?.message}
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    label={Vocabulary.email}
                    {...methods.register("email")}
                    fullWidth
                    error={typeof methods.formState.errors.email === "object"}
                    helperText={methods.formState.errors.email?.message}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </fieldset>
          </div>
        );
      }}
    </ConnectForm>
  );
}

const styles = (theme: any) =>
  createStyles({
    select: {
      paddingTop: 0,
      width: "10%",
      marginBottom: 15,
      paddingRight: 10,
      marginRight: 10,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    fieldset: {
      borderRadius: 10,
      borderColor: "#00197540",
      marginBottom: 20,
      padding: 20,
    },
  });
export default withStyles(styles, { withTheme: true })(CompanyRequest);
