/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  LocalUrlEnum,
  UrlEnum,
  get,
  nrOfItemsPerPage,
} from "../../Utils/Utils";
import ArticlesGrid from "../../Components/ArticlesGrid";
import {
  Avatar,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

type Props = {
  classes: any;
};

function Subcategories(props: Props) {
  const [state, setState] = useState({
    articles: [],
    _subcategories: [],
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [nrPages, setNrPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = props;

  /**
   *
   * @param nrOfElements
   */
  const calculateNumberOfPages = (nrOfElements: any) => {
    const nEl = nrOfElements / nrOfItemsPerPage;
    const nrOfPages = nrOfElements % nrOfItemsPerPage;
    if (nrOfPages === 0) {
      setNrPages(nEl);
    } else {
      setNrPages(Math.trunc(nEl) + 1);
    }
  };
  /**
   *
   */
  useEffect(() => {
    setLoading(true);
    const storage = window.localStorage;
    const updatedParams = new URLSearchParams(searchParams);
    const storageUser = storage.getItem("user");
    const userParsed = JSON.parse(storageUser ? storageUser : "");
    const subscription = userParsed ? userParsed.account?.id : null;
    if (location.state?.categoryId) {
      const url = `${UrlEnum.getUserArticles}/${
        subscription ? subscription : null
      }/${page}/${location.state.categoryId}/${null}/${updatedParams.get(
        "filtru"
      )}`;
      const promises = [
        get(url),
        get(`${UrlEnum.getCategory}/${location.state.categoryId}`),
      ];
      Promise.all(promises).then((values: any) => {
        setLoading(false);
        setState({
          ...state,
          articles: values[0].articles,
          _subcategories: values[1].category.subcategories,
        });
        calculateNumberOfPages(values[0].total_count);
      });
    }
  }, [location]);

  return (
    <div className={classes.parentContainer}>
      {state._subcategories.length > 0 ? (
        <div className={classes.subcategories}>
          <Grid container spacing={2}>
            {state._subcategories.map((category: any) => {
              return (
                <Grid item xs={2} key={category.id}>
                  <IconButton
                    onClick={() =>
                      navigate(
                        `/${LocalUrlEnum.userCategories}${category.link}`,
                        {
                          replace: true,
                          state: { categoryId: category.id },
                        }
                      )
                    }
                  >
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      src={`${UrlEnum.getPublicImage}/${category.imagePath}`}
                    />
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#000",
                        fontSize: 12,
                        marginLeft: 2,
                      }}
                    >
                      {category?.name}
                    </Typography>
                  </IconButton>
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : null}
      {state.articles ? (
        <>
          <ArticlesGrid articles={state.articles} nrPages={nrPages} />
        </>
      ) : null}
      {loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    parentContainer: {
      marginTop: 50,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    subcategories: {
      width: "70%",
      display: "flex",
      backgroundColor: "rgb(241, 241, 241,0.5)",
      padding: 10,
      borderRadius: 10,
    },
  });
export default withStyles(styles, { withTheme: true })(Subcategories);
