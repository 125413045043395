import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useState, useEffect } from "react";
import { Edit, Visibility } from "@mui/icons-material";
import { LocalUrlEnum, get, UrlEnum, post,  disableAllFields } from "../../Utils/Utils";
import {
  ToggleButton,
  Button,
  Tooltip,
  ToggleButtonGroup,
  InputLabel,
  FormControl,
  MenuItem,
  Backdrop,
  CircularProgress,
  Grid,
  Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Modal from "../../Components/Modal";
import Article from "./Article";
import Filter from "../../Components/Filter";
import { Controller, useForm } from "react-hook-form";
import Select from "@mui/material/Select";
import CachedDataSingleton from "../../Utils/CachedDataSingleton";

type Props = {
  classes: any;
};

function ArticlesTable(props: Props) {
  const cachedData = CachedDataSingleton.getInstance();
  const { classes } = props;
  const navigate = useNavigate();
  const [categories, setCategories] = useState<any>([]);
  const [category, setCategory] = useState(null);
  let values: any[] = [];
  const [state, setState] = useState<any>({
    articles: [],
    page: 1,
    perPage: 20,
    order: "desc",
    preview: false,
    edit: false,
    count: 1,
    loading: false,

  });
  const methodsArticle = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });
  const watchCategory = methodsArticle.watch("FK_categoryId");

  /**
   *
   */
  const requestHeaders = [
    { label: "Denumire", name: "title", options: { sort: false } },
    { label: "Link", name: "link", options: { sort: false } },
    { label: "Etichete", name: "tag", options: { sort: false } },
    { label: "Data creare", name: "createdDate", options: { sort: true } },
    {
      label: "TRUE",
      name: "status",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      label: "Opțiuni",
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,

        empty: true,

        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.view}>
                  <ToggleButton
                    value="left"
                    aria-label="left aligned"
                    onClick={() => {
                      setState({ ...state, preview: true });
                      cachedData.setKey("preview", true);
                      cachedData.setKey("id", state.articles[rowIndex].id);
                      disableAllFields();
                    }}
                  >
                    <Visibility />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    value="center"
                    aria-label="centered"
                    onClick={() => {
                      navigate(
                        `/${LocalUrlEnum.article}/${state.articles[rowIndex].id}`,
                        { state: { id: state.articles[rowIndex].id } }
                      );
                    }}
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];
  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "multiple" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [20, 50, 100],
      confirmFilters: true,
      filter: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page - 1,
      search: true,
      serverSide: true,
      customToolbar: () => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/${LocalUrlEnum.article}`);
            }}
          >
            {Vocabulary.addNewArticle}
          </Button>
        );
      },
      onSearchChange: (searchText: string | null) => {
        if (searchText && searchText?.length > 3) getArticles(searchText);
        if (!searchText) getArticles(null);
      },
      onColumnSortChange: (changedColumn: string, direction: string) => {
        setState({ ...state, order: direction });
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page + 1 });
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        setState({ ...state, perPage: numberOfRows });
      },
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        handleDelete(rowsDeleted);
      },
    };
  };

  /**
   *
   * @param id
   */
  const handleDelete = (selectedRows: any) => {
    const ids: number[] = [];

    selectedRows.data.map((row: any, k: any) => {
      ids.push(state.articles[row.dataIndex].id);
    });

    post(UrlEnum.deleteArticles, ids).then((response: any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.deleteSucces);
        getArticles();
      }
    });
    selectedRows.data.length = 0;
  };

  /**
   *
   */
  useEffect(() => {
    if (watchCategory !== undefined && watchCategory !== "")
      setCategory(watchCategory);
  }, [watchCategory]);

  /**
   *
   */

  useEffect(() => {
    get(UrlEnum.getCategories).then((response:any) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        setCategories(getCategories(response.parentCategories, response.items));
      }
    });
  }, []);

  /**
   *
   */
  const getArticles = (filter?: string | null) => {
    if (!filter) filter = null;
    setState({ ...state, loading: true });
    get(
      `${UrlEnum.articles}/${state.page}/${state.perPage}/${state.order}/${filter}/${category}`
    ).then((response) => {
      if (response.errors) {
        toast.error(response.errors);
        setState({ ...state, loading: false });
      } else {
        setState({
          ...state,
          articles: response.articles,
          count: response.total_count,
          loading: false,
        });
      }
    });
  };

  /**
   *
   */
  useEffect(() => {
    getArticles();
  }, [state.perPage, state.page, state.order, category]);

  /**
   *
   */
  const handleDeleteFilters = () => {
    setCategory(null);
    methodsArticle.reset({});
  };

   /**
   *
   * @param param0
   * @param padding
   * @param categories
   */
   const getSubcategories = (
    { id, name }: any,
    padding: any,
    categories: any
  ) => {
    const subcategories = categories.filter(
      (category: any) => category.FK_parentId === id
    );

    values = [...values, { id: id, name: name, padding: padding }];
    if (subcategories.length > 0) {
      subcategories.map(({ id, name }: any, key: any) =>
        getSubcategories({ id, name }, padding + 2, categories)
      );
    }
  };

   /**
   *
   * @param parentCategories
   * @param categories
   * @returns
   */
   const getCategories = (parentCategories: any, categories: any) => {
    parentCategories.map(({ id, name }: any, key: any) => {
      getSubcategories({ id, name }, 1.5, categories);
    });
    return values;
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <Filter
        children={
            <Grid container className={classes.filterBox}>
              <Grid item xs={12} sm={6} md={3}>
              <InputLabel>{Vocabulary.selectCategory}</InputLabel>
                <Controller
                  name="FK_categoryId"
                  control={methodsArticle.control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        multiple
                        defaultValue={[]}
                      >
                        {categories.map((value: any, key: any) => (
                          <MenuItem
                            key={key}
                            value={value.id}
                            sx={{ paddingLeft: `${value.padding}em` }}
                          >
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
            </Grid>
            </Grid>
        }
        handleDeleteFilters={handleDeleteFilters}
      ></Filter>
       <Box className={classes.tableBox}>
      <MUIDataTable
        title={Vocabulary.articles}
        data={state.articles}
        columns={requestHeaders}
        options={getTableOptions()}
      />
        </Box>
      <Modal
        open={state.preview}
        onClose={() => {
          setState({ ...state, preview: false });
          cachedData.setKey("id", undefined);
        }}
        title={null}
        actions={null}
        children={
          <>
            <Article />
          </>
        }
      ></Modal>
      {state.loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
    </>
  );
}

const styles = (theme: any) =>
  createStyles({
    select: {
      width: "20vw",
    },
    tableBox: {
      [theme.breakpoints.down("sm")]: {
        width: "88vw",
      },
    },
    filterBox: {
      padding:5,
    },
  });
export default withStyles(styles, { withTheme: true })(ArticlesTable);
