import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { Vocabulary } from "../Utils/Vocabulary";
import { FormProvider, useForm } from "react-hook-form";
import {
  Backdrop,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { LocalUrlEnum, UrlEnum, post } from "../Utils/Utils";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";

type Props = {
  classes: any;
};

function ResetPassword(props: Props) {
  const { classes } = props;
  const [loading, setLoading] = useState(false);
  const captchaRef = useRef<any>(null);
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(Vocabulary.emailValidation)
      .required(Vocabulary.requiredField),
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

  /**
   *
   */
  const handleSubmit = () => {
    setLoading(true);
    if (captchaRef.current) {
      const body = methods.getValues();
      body["reCaptcha"] = captchaRef.current.getValue();
      post(UrlEnum.forgotPassword, body).then((response) => {
        setLoading(false);
        if (response.errors) {
          toast.error(response.errors);
        } else {
          toast.success(response);
        }
      });
    }
  };

  return (
    <div className={classes.container}>
      <ToastContainer hideProgressBar={true} />
      <Link to={`/${LocalUrlEnum.login}`} className={classes.text}>
        <div className={classes.image}>
          <img src={"/vetmed.svg"} alt="VETMED GHID" width="70%" />
        </div>
      </Link>

      <FormProvider {...methods}>
        <form
          className={classes.form}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <Typography
            align="center"
            sx={{
              fontWeight: "bold",
              fontSize: 14,
              color: "#fff",
              margin: 2,
              width: "300px",
            }}
          >
            {Vocabulary.resetPasswordEnterEmail}
          </Typography>
          <TextField
            className={classes.textField}
            variant="standard"
            {...methods.register("email")}
            id="email"
            name="email"
            error={typeof methods.formState.errors.email === "object"}
            helperText={methods.formState.errors.email?.message?.toString()}
            label={Vocabulary.email}
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{ style: { color: "white" } }}
          />
          <div className={classes.ReCAPTCHA}>
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={
                process.env.REACT_APP_SITE_KEY
                  ? process.env.REACT_APP_SITE_KEY
                  : ""
              }
            />
          </div>
          <Button
            className={classes.button}
            type="submit"
            variant="contained"
            color="primary"
          >
            {Vocabulary.send}
          </Button>

          <div className={classes.bottomText}>
            <Link to={`/${LocalUrlEnum.register}`} className={classes.text}>
              <Typography>{Vocabulary.requireRegister}</Typography>
            </Link>
            <Link to={`/${LocalUrlEnum.login}`} className={classes.text}>
              <Typography>{Vocabulary.login}</Typography>
            </Link>
          </div>
        </form>
      </FormProvider>

      {loading ? (
        <Backdrop open={true} sx={{ zIndex: "1600 !important" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    button: {
      height: "50px",
      width: "300px",
      marginTop: "30px !important",
      [theme.breakpoints.down("sm")]: { width: "100%" },
    },
    textField: {
      margin: "5px !important",
      height: "50px",
      width: "300px",
      color: "#FFF !important",
      [theme.breakpoints.down("sm")]: { width: "100%" },
    },
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#000",
      background:
        "radial-gradient(circle, rgba(74,74,74,1) 0%, rgba(0,0,0,1) 93%)",
    },
    image: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 50,
      cursor: "pointer",
      width: "350px",
      [theme.breakpoints.down("sm")]: {
        width: "200px",
      },
      [theme.breakpoints.down("sm")]: { width: "50%" },
    },

    bottomText: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "300px",
      marginTop: 10,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    text: {
      color: "#FFF",
      textDecoration: "inherit",
    },
    form: {
      width: "60%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
  });
export default withStyles(styles, { withTheme: true })(ResetPassword);
