import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";

import { Paper, Typography, Grid, TextField, Button } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Vocabulary } from "../Utils/Vocabulary";
import Address from "./user/Address";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { UrlEnum, get, post } from "../Utils/Utils";
import { ToastContainer, toast } from "react-toastify";
type Props = {
  classes: any;
  idCompany: string | null;
};

function CompanyDetails(props: Props) {
  const { classes } = props;
  const location = useLocation();

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });
  /**
   *
   */
  const handleUpdate = () => {
    const model = methods.getValues();

    post(`${UrlEnum.Company}`, model).then((response) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        toast.success(Vocabulary.updateSuccess);
        methods.reset(response.company);
      }
    });
  };

  /**
   *
   */
  useEffect(() => {
    const id = props.idCompany;

    get(`${UrlEnum.Company}/${id}`).then((response) => {
      if (response.errors) {
        toast.error(response.errors);
      } else {
        methods.reset(response.company);
      }
    });
  }, [location]);

  return (
    <>
      <FormProvider {...methods}>
        <ToastContainer hideProgressBar={true} />
        <form
          className={classes.form}
          onSubmit={methods.handleSubmit(handleUpdate)}
        >
          <Paper elevation={12} className={classes.paper}>
            <Typography variant="h6" align="left">
              {Vocabulary.companyData}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  id="companyName"
                  label={Vocabulary.companyName}
                  {...methods.register("name")}
                  fullWidth
                  disabled
                  error={
                    typeof methods.formState.errors.companyName === "object"
                  }
                  helperText={methods.formState.errors.companyName?.message?.toString()}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  {...methods.register("category.category")}
                  id="companyCategory"
                  name="companyCategory"
                  label={Vocabulary.companyCategory}
                  fullWidth
                  disabled
                  variant="standard"
                  error={
                    typeof methods.formState.errors.companyCategory === "object"
                  }
                  helperText={methods.formState.errors.companyCategory?.message?.toString()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  {...methods.register("CUI")}
                  id="CUI"
                  name="CUI"
                  label={Vocabulary.CUI}
                  fullWidth
                  disabled
                  variant="standard"
                  error={typeof methods.formState.errors.CUI === "object"}
                  helperText={methods.formState.errors.CUI?.message?.toString()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  {...methods.register("registerNo")}
                  id="registerNo"
                  name="registerNo"
                  label={Vocabulary.registerNo}
                  fullWidth
                  disabled
                  variant="standard"
                  error={
                    typeof methods.formState.errors.registerNo === "object"
                  }
                  helperText={methods.formState.errors.registerNo?.message?.toString()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  {...methods.register("representativeName")}
                  id="companyRepresentativeName"
                  name="representativeName"
                  label={Vocabulary.representativeName}
                  fullWidth
                  variant="standard"
                  error={
                    typeof methods.formState.errors.representativeName ===
                    "object"
                  }
                  helperText={methods.formState.errors.representativeName?.message?.toString()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  type="email"
                  id="companyEmail"
                  label={Vocabulary.email}
                  {...methods.register("email")}
                  error={typeof methods.formState.errors.email === "object"}
                  helperText={methods.formState.errors.email?.message?.toString()}
                  fullWidth
                  variant="standard"
                  name="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  type="number"
                  id="companyPhone"
                  label={Vocabulary.phone}
                  {...methods.register("phone")}
                  error={typeof methods.formState.errors.phone === "object"}
                  helperText={methods.formState.errors.phone?.message?.toString()}
                  fullWidth
                  variant="standard"
                  name="phone"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Address modelRoot="address." />
            </Grid>
            <div className={classes.saveButton}>
              <Button color="secondary" type="submit" variant="contained">
                {Vocabulary.save}
              </Button>
            </div>
          </Paper>
        </form>
      </FormProvider>
    </>
  );
}

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      margin: 5,
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    container: {
      width: "49%",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    typography: {
      marginBottom: 15,
    },
    saveButton: {
      display: "flex",
      padding: 10,
      justifyContent: "flex-end",
    },
  });

export default withStyles(styles, { withTheme: true })(CompanyDetails);
