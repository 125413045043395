import { Button, Typography } from "@mui/material";
import { createStyles } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { ToastContainer } from "react-toastify";
import { Vocabulary } from "../Utils/Vocabulary";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UrlEnum, post } from "../Utils/Utils";

type Props = {
  classes: any;
};

function SuccessfulPayment(props: Props) {
  const { classes } = props;
  const navigate = useNavigate();
  const [statusPayment, setPaymentStatus] = useState("");

  /**
   *
   */
  const registerPayment = () => {
    const id = JSON.parse(localStorage.user).id;
    post(`${UrlEnum.registerPayment}/${id}`).then((response: any) => {
      if (response.errors) {
        console.log(response.err);
      }
    });
  };

  /**
   *
   */
  useEffect(() => {
    const url =
      "https://ecclients.btrl.ro/payment/rest/getOrderStatusExtended.do";
    const params = new URLSearchParams({
      userName: "Vetmedghid_API",
      password: "caRqYFD8nPCY6NV_Ap!",
      orderId: `${localStorage.orderId}`,
    });
    // const url =
    //   "https://ecclients.btrl.ro:5443/payment/rest/getOrderStatusExtended.do";
    // const params = new URLSearchParams({
    //   userName: "test_iPay3_api",
    //   password: "test_iPay3_ap!e4r",
    //   orderId: `${localStorage.orderId}`,
    // });

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    })
      .then(async function (response) {
        return response.json();
      })
      .then(function (result) {
        console.log(result);
        switch (result.actionCode) {
          case 0:
            setPaymentStatus(Vocabulary.successfulPayment);
            return registerPayment();
          case 104:
            return setPaymentStatus(Vocabulary.actionCode104);
          case 124:
            return setPaymentStatus(Vocabulary.actionCode124);
          case 320:
            return setPaymentStatus(Vocabulary.actionCode320);
          case 801:
            return setPaymentStatus(Vocabulary.actionCode801);
          case 803:
            return setPaymentStatus(Vocabulary.actionCode803);
          case 804:
            return setPaymentStatus(Vocabulary.actionCode804);
          case 805:
            return setPaymentStatus(Vocabulary.actionCode805);
          case 861:
            return setPaymentStatus(Vocabulary.actionCode861);
          case 871:
            return setPaymentStatus(Vocabulary.actionCode871);
          case 905:
            return setPaymentStatus(Vocabulary.actionCode905);
          case 906:
            return setPaymentStatus(Vocabulary.actionCode906);
          case 913:
            return setPaymentStatus(Vocabulary.actionCode913);
          case 914:
            return setPaymentStatus(Vocabulary.actionCode914);
          case 915:
            return setPaymentStatus(Vocabulary.actionCode915);
          case 917:
            return setPaymentStatus(Vocabulary.actionCode917);
          case 952:
            return setPaymentStatus(Vocabulary.actionCode952);
          case 998:
            return setPaymentStatus(Vocabulary.actionCode998);
          case 341016:
            return setPaymentStatus(Vocabulary.actionCode341016);
          case 341017:
            return setPaymentStatus(Vocabulary.actionCode341017);
          case 341018:
            return setPaymentStatus(Vocabulary.actionCode341018);
          case 341019:
            return setPaymentStatus(Vocabulary.actionCode341019);
          case 341020:
            return setPaymentStatus(Vocabulary.actionCode341020);
          default:
            return setPaymentStatus("Tranzacție refuzată, vă rugăm reveniți.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={classes.root}>
      <ToastContainer hideProgressBar={true} />
      <div className={classes.image}>
        <img src={"/vetmed.svg"} alt="VETMED GHID" width="70%" />
      </div>

      <Typography variant="h5" className={classes.message}>
        {statusPayment}
      </Typography>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => {
          localStorage.removeItem("user");
          localStorage.removeItem("orderId");
          navigate("/login");
        }}
      >
        {Vocabulary.login}
      </Button>
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      //justifyContent: "center",
      paddingTop: "22vh",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#000",
      background:
        "radial-gradient(circle, rgba(74,74,74,1) 0%, rgba(0,0,0,1) 93%)",
    },
    textField: {
      margin: "5px !important",
      height: "50px",
      width: "300px",
      color: "#FFF !important",
      [theme.breakpoints.down("lg")]: {},
    },
    button: {
      height: "50px",
      width: "200px",
      marginTop: "30px !important",
    },
    form: {
      width: "60%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    image: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 50,
      width: "350px",
      [theme.breakpoints.down("sm")]: {
        width: "200px",
      },
    },
    text: {
      color: "#FFF",
      textDecoration: "inherit",
    },
    ReCAPTCHA: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      marginTop: 30,
    },
    bottomText: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "300px",
      marginTop: 10,
    },
    message: {
      color: "#FFF",
      textAlign: "center",
      maxWidth: "500px",
      [theme.breakpoints.down("sm")]: {
        padding: "2em",
        textAlign: "center",
      },
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "row",
      columnGap: "40px",

      [theme.breakpoints.down("sm")]: {
        display: "grid",
      },
    },
  });
export default withStyles(styles, { withTheme: true })(SuccessfulPayment);
